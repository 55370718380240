import React from 'react'
import { Button, InputPicker, Loader, Modal } from 'rsuite'

import { fullname } from 'components/profiles/profiles'
import { success } from 'content'
import { trans } from 'i18n'
import NAP, { Item, profilePhotoOpt } from 'nap'

interface AssignProfilePhotoProps {
  item: Item
  img: string
  onClose?: () => void
}

interface AssignProfilePhotoState {
  profiles?: AssignProfile[]
  selected: number
}

interface AssignProfile {
  id: number
  fullname: string
}

export default class AssignProfilePhoto extends React.Component<
  AssignProfilePhotoProps,
  AssignProfilePhotoState
> {
  state = {} as AssignProfilePhotoState

  componentDidMount() {
    NAP.profiles()
      .then((profiles) => {
        if (!profiles) return

        const list = profiles.map((p) => {
          return { id: p.id, fullname: fullname(p) } as AssignProfile
        })

        this.setState({ profiles: list })
      })

      .catch(alert)
  }

  handleSelectProfile = (v: any) => {
    this.setState({ selected: v })
  }

  handleApply = () => {
    const { img } = this.props

    fetch(NAP.url(img), { headers: NAP.authHeaders() })
      .then((response) => response.blob())
      .then((blob) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          if (e.target && e.target.result) this.cropImage(e.target.result)
        }
        reader.readAsDataURL(blob)
      })
      .catch(alert)
  }

  handleClose = () => {
    if (this.props.onClose) this.props.onClose()
  }

  cropImage(imgdata: string | ArrayBuffer) {
    const { item } = this.props
    const { selected } = this.state

    const canvas = document.createElement('canvas')

    const img = document.createElement('img')
    img.src = imgdata as string

    const sx = img.naturalWidth * item.bbox[0]
    const sy = img.naturalHeight * item.bbox[1]
    const sw = img.naturalWidth * item.bbox[2]
    const sh = img.naturalHeight * item.bbox[3]

    canvas.width = sw
    canvas.height = sh

    canvas.getContext('2d')?.drawImage(img, sx, sy, sw, sh, 0, 0, sw, sh)
    canvas.toBlob(
      (file: Blob | null) => {
        if (!file) return

        const opt = { nodetect: true } as profilePhotoOpt
        NAP.uploadProfilePhoto(selected, file, opt)
          .then(() => {
            success()
            this.handleClose()
          })
          .catch(alert)
      },
      'image/jpeg',
      0.95
    )
  }

  //
  // render
  //

  render() {
    const { profiles, selected } = this.state

    return (
      <Modal onClose={() => this.handleClose()} open>
        <Modal.Header>
          <Modal.Title>{trans('profiles.assign_photo')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {!profiles && (
            <div style={{ textAlign: 'center' }}>
              <Loader size='md' />
            </div>
          )}

          {profiles && (
            <InputPicker
              data={profiles}
              value={selected}
              valueKey='id'
              labelKey='fullname'
              onSelect={this.handleSelectProfile}
              cleanable={false}
              block
            />
          )}
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={this.handleClose}>{trans('cancel')}</Button>
          <Button
            appearance='primary'
            onClick={this.handleApply}
            disabled={!selected}>
            {trans('apply')}
          </Button>
        </Modal.Footer>
      </Modal>
    )
  }
}
