import path from 'path'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FaCog, FaPen } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import {
  ButtonToolbar,
  Checkbox,
  IconButton,
  Panel,
  Table,
  Tag,
  Tooltip,
  Whisper,
} from 'rsuite'

import Content, {
  ContentState,
  DelButton,
  HeaderSearch,
  TableFooter,
  alert,
  setTitle,
} from 'content'
import PageTable from 'pagetable'
import NAP, { User } from 'nap'
import AuthSettingsForm from './authsettings'
import Roles from './roles'

interface Props extends WithTranslation {}

interface State extends ContentState {
  users: User[]
  selected: Record<number, User>

  showSettings: boolean
}

class Users extends React.Component<Props, State> {
  state = {
    selected: {},
  } as State

  componentDidMount() {
    setTitle('users.title')

    this.loadUsers()
  }

  loadUsers = () => {
    NAP.users()
      .then((users: User[]) => this.setState({ users, loaded: true }))
      .catch(this.setError)
  }

  setError = (err: Error) => {
    this.setState({ error: err.message, loaded: true })
  }

  //
  // handlers
  //

  handleSearch = (search: string) => {
    this.setState({ search })
  }

  handleSelect = (u: User) => {
    const { selected } = this.state
    selected[u.id] ? delete selected[u.id] : (selected[u.id] = u)

    this.setState({ selected })
  }

  handleSelectAll = (checked: boolean, users: User[]) => {
    const selected = {} as Record<number, User>

    if (checked) {
      users.forEach((u) => {
        if (u.superadmin) return
        selected[u.id] = u
      })
    }

    this.setState({ selected })
  }

  perfomDelete = () => {
    let { users, selected } = this.state

    Object.values(selected).forEach((u: User) => {
      if (u.superadmin) return
      NAP.deleteUser(u.id).catch(alert)
    })

    users = users.filter((u: User) => {
      return selected[u.id] === undefined
    })

    this.setState({ users, selected: {} })
  }

  toggleSettings = () => {
    this.setState({ showSettings: !this.state.showSettings })
    this.loadUsers()
  }

  //
  // data
  //

  getData(): User[] {
    const search = this.state.search || ''
    let users = this.state.users || ([] as User[])

    if (search) {
      users = users.filter((u: User) => {
        return (
          u.username.includes(search) ||
          u.name.includes(search) ||
          u.email.includes(search) ||
          u.roles?.some((r) => r.title === search)
        )
      })
    }

    return users
  }

  //
  // render
  //

  render() {
    const { loaded, error, showSettings } = this.state

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        {this.renderUsers()}
        <Roles />
        {showSettings && <AuthSettingsForm onHide={this.toggleSettings} />}
      </Content>
    )
  }

  renderHeader() {
    const { t } = this.props
    const { search, selected, showSettings } = this.state

    return (
      <HeaderSearch
        onSearch={this.handleSearch}
        value={search}
        right={
          <ButtonToolbar>
            <Whisper
              placement='bottom'
              speaker={<Tooltip>{t('users.help.settings')}</Tooltip>}>
              <IconButton
                active={showSettings}
                onClick={this.toggleSettings}
                icon={<FaCog />}
                circle
              />
            </Whisper>
            <DelButton
              onConfirm={this.perfomDelete}
              disabled={Object.keys(selected || {}).length === 0}
              selected={selected}
              circle
            />
          </ButtonToolbar>
        }
      />
    )
  }

  renderUsers() {
    const { t } = this.props
    const { selected } = this.state
    const users = this.getData() || []
    const superadmins = users.filter((u) => u.superadmin) || []

    const totalUsers = users.length - superadmins.length

    const { Column, HeaderCell, Cell } = Table

    return (
      <Panel className='content-panel'>
        <PageTable
          data={users}
          total={users.length}
          limit={10}
          sort={{ col: 'username', type: 'asc' }}>
          <Column width={200} sortable>
            <HeaderCell>{t('users.username')}</HeaderCell>
            <Cell dataKey='username' />
          </Column>
          <Column flexGrow={1} sortable>
            <HeaderCell>{t('users.role')}</HeaderCell>
            <Cell dataKey='roles' align='center'>
              {(u: User | any) => this.renderRoles(u)}
            </Cell>
          </Column>
          <Column flexGrow={1} sortable>
            <HeaderCell>{t('name')}</HeaderCell>
            <Cell dataKey='name' />
          </Column>
          <Column flexGrow={1} sortable>
            <HeaderCell>{t('email')}</HeaderCell>
            <Cell dataKey='email' />
          </Column>

          <Column fixed='right' align='right' width={90}>
            <HeaderCell className='control-table-header-cell'>
              <Checkbox
                checked={
                  totalUsers > 0 && Object.keys(selected).length === totalUsers
                }
                onChange={(v, checked) => this.handleSelectAll(checked, users)}
              />
            </HeaderCell>
            <Cell className='actions-cell'>
              {(u: User | any) => (
                <ButtonToolbar>
                  <Whisper
                    placement='top'
                    trigger='hover'
                    speaker={<Tooltip>{t('edit')}</Tooltip>}>
                    <Link
                      to={path.join(window.location.pathname, u.id.toString())}>
                      <IconButton appearance='subtle' icon={<FaPen />} />
                    </Link>
                  </Whisper>
                  {!u.superadmin && (
                    <Checkbox
                      checked={selected[u.id] !== undefined || false}
                      onChange={() => this.handleSelect(u)}
                    />
                  )}
                </ButtonToolbar>
              )}
            </Cell>
          </Column>
        </PageTable>

        <TableFooter>{t('users.add_user')}</TableFooter>
      </Panel>
    )
  }

  renderRoles = (u: User) => {
    if (u.superadmin) return <Tag color='violet'>superadmin</Tag>
    return u.roles?.map((r) => <Tag key={r.title}>{r.title}</Tag>)
  }
}

export default withTranslation()(Users)
