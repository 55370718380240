import path from 'path'
import queryString from 'query-string'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FaCog, FaFileDownload, FaFileUpload, FaPen } from 'react-icons/fa'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import {
  Button,
  ButtonToolbar,
  Checkbox,
  Dropdown,
  Footer,
  IconButton,
  InputPicker,
  Panel,
  Table,
  Tag,
  Tooltip,
  Uploader,
  Whisper,
} from 'rsuite'

import { IMAGE } from 'components/players'
import Content, {
  ContentState,
  DelButton,
  HeaderSearch,
  alert,
  setTitle,
  success,
} from 'content'
import PageTable from 'pagetable'
import NAP, { Group, Profile } from 'nap'
import DeleteImages from './deleteimages'

interface RouteParams {
  id: string
}
interface Props extends WithTranslation, RouteComponentProps<RouteParams> {}
interface State extends ContentState {
  profiles: Profile[]
  groups: Group[]

  toDelete: Record<number, Profile>

  inExport: boolean
  inImport: boolean
}

class Profiles extends React.Component<Props, State> {
  state = {
    toDelete: {},
  } as State

  constructor(props: Props) {
    super(props)

    const q = queryString.parse(window.location.search)
    if (q.search) this.state.search = q.search as string
  }

  componentDidMount() {
    setTitle('profiles.title')

    NAP.groups().then(this.setGroups).catch(this.setError)
    NAP.profiles().then(this.setProfiles).catch(this.setError)
  }

  setGroups = (groups: Group[]) => {
    this.setState({ groups })
  }

  setProfiles = (profiles: Profile[]) => {
    profiles?.forEach((p) => (p.name = fullname(p)))

    this.setState({ profiles, loaded: true })
  }

  setError = (err: Error) => {
    this.setState({ error: err.message, loaded: true })
  }

  handleSearch = (search: string) => {
    this.setState({ search })
  }

  handleDelete = (p: Profile) => {
    const { toDelete } = this.state
    if (toDelete[p.id]) delete toDelete[p.id]
    else toDelete[p.id] = p
    this.setState({ toDelete })
  }

  perfomDelete = () => {
    let { profiles, toDelete } = this.state
    Object.values(toDelete).forEach((p: Profile) => {
      NAP.deleteProfile(p.id).catch(console.error)
    })

    profiles = profiles.filter((p: Profile) => {
      return toDelete[p.id] === undefined
    })

    this.setState({ profiles: profiles, toDelete: {} })
  }

  handleExport = () => {
    this.setState({ inExport: true })
    NAP.profilesExport()
      .then(() => {
        this.setState({ inExport: false })
      })
      .catch((err) => {
        this.setState({ inExport: false })
        alert(err.message)
      })
  }

  handleImport = (files: any[]) => {
    if (!files.length) return

    this.setState({ inImport: true })

    const { t } = this.props

    NAP.profilesImport(files[0])
      .then(() => {
        success(t('import_success'))
        this.setState({ inImport: false })
        this.componentDidMount()
      })
      .catch((err) => {
        this.setState({ inImport: false })
        alert(err.message || err.error)
      })
  }

  lookupGroup = (group_id: number): string => {
    const { groups } = this.state
    if (!groups) return ''

    const g = groups.find((g: Group) => g.id === group_id)
    if (!g) return ''

    return g.name
  }

  getData = (): Profile[] => {
    let { search, groups } = this.state

    let profiles = this.state.profiles || ([] as Profile[])

    if (search) {
      profiles = searchProfiles(profiles, search)
    }

    if (groups) {
      profiles.forEach((p: Profile) => {
        if (p.group.id && !p.group.name) {
          p.group.name = this.lookupGroup(p.group.id)
        }
      })
    }

    return profiles
  }

  //
  //
  //

  render() {
    const { t } = this.props
    const { loaded, error, toDelete } = this.state
    const { Column, HeaderCell, Cell } = Table

    const data = this.getData()

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        <Panel className='content-panel'>
          <PageTable
            data={data}
            total={data.length}
            sort={{ col: 'name', type: 'asc' }}>
            <Column width={80}>
              <HeaderCell>{t('profiles.photo')}</HeaderCell>
              <Cell className='table-images-cell'>
                {(p: Profile | any) =>
                  p.photo && (
                    <IMAGE
                      src={`/api/profiles/${p.id}/photos/${p.photo}`}
                      className='profile-photo-circle'
                      width={36}
                      height={36}
                      background
                    />
                  )
                }
              </Cell>
            </Column>

            <Column flexGrow={1} sortable>
              <HeaderCell>{t('profiles.fullname')}</HeaderCell>
              <Cell dataKey='name' />
            </Column>

            <Column flexGrow={1} sortable>
              <HeaderCell>{t('profiles.group')}</HeaderCell>
              <Cell dataKey='group'>
                {(p: Profile | any) =>
                  p.group &&
                  p.group.name && (
                    <Tag onClick={() => this.handleSearch(p.group.name)}>
                      {p.group.name}
                    </Tag>
                  )
                }
              </Cell>
            </Column>

            <Column flexGrow={1} sortable>
              <HeaderCell>{t('profiles.department')}</HeaderCell>
              <Cell dataKey='department' />
            </Column>

            <Column sortable>
              <HeaderCell>{t('profiles.externalid')}</HeaderCell>
              <Cell dataKey='externalid' />
            </Column>

            <Column fixed='right' align='right' width={90}>
              <HeaderCell> </HeaderCell>
              <Cell className='actions-cell'>
                {(p: Profile | any) => (
                  <ButtonToolbar>
                    <Link
                      to={path.join(window.location.pathname, p.id.toString())}>
                      <IconButton appearance='subtle' icon={<FaPen />} />
                    </Link>
                    <Checkbox
                      checked={toDelete[p.id] !== undefined}
                      onChange={() => this.handleDelete(p)}
                    />
                  </ButtonToolbar>
                )}
              </Cell>
            </Column>
          </PageTable>

          <Footer className='footer-buttons'>
            <Link to={path.join(window.location.pathname, 'new')}>
              <Button appearance='primary'>{t('profiles.new')}</Button>
            </Link>
          </Footer>
        </Panel>
      </Content>
    )
  }

  renderHeader() {
    const { t } = this.props
    const { search, groups, inExport, inImport, toDelete } = this.state

    return (
      <HeaderSearch
        onSearch={this.handleSearch}
        value={search}
        left={
          <InputPicker
            data={groups || []}
            labelKey='name'
            valueKey='name'
            placeholder={t('profiles.group')}
            onChange={(v) => this.handleSearch(v)}
          />
        }
        right={
          <ButtonToolbar align='right'>
            <Whisper
              placement='bottom'
              trigger='hover'
              speaker={<Tooltip>{t('profiles.cars.export')}</Tooltip>}>
              <IconButton
                onClick={this.handleExport}
                icon={<FaFileDownload />}
                disabled={inExport}
                circle
              />
            </Whisper>
            {inImport ? (
              <IconButton
                appearance='primary'
                icon={<FaFileUpload />}
                disabled
                circle
              />
            ) : (
              <Uploader
                action={NAP.url('/api/profiles/import')}
                headers={NAP.authHeaders()}
                onChange={this.handleImport}
                onError={(r) => alert(r.response.error)}
                fileList={[]}
                listType='text'
                accept='text/csv'
                autoUpload={false}
                fileListVisible={false}>
                <Whisper
                  placement='bottom'
                  trigger='hover'
                  speaker={<Tooltip>{t('profiles.cars.import')}</Tooltip>}>
                  <IconButton icon={<FaFileUpload />} circle />
                </Whisper>
              </Uploader>
            )}
            <Dropdown
              placement='bottomEnd'
              renderToggle={(props, ref) => (
                <IconButton {...props} ref={ref} icon={<FaCog />} circle />
              )}>
              <DeleteImages />
            </Dropdown>
            <DelButton
              onConfirm={this.perfomDelete}
              disabled={Object.keys(toDelete || {}).length === 0}
              circle
            />
          </ButtonToolbar>
        }
      />
    )
  }
}

export default withTranslation()(withRouter(Profiles))

export function fullname(p: Profile): string {
  const fullname = [] as string[]
  if (p.lastname) fullname.push(p.lastname)
  if (p.firstname) fullname.push(p.firstname)
  if (p.middlename) fullname.push(p.middlename)

  return fullname.join(' ')
}

export function searchProfiles(profiles: Profile[], search: string): Profile[] {
  if (search) {
    search = search.toLocaleLowerCase()
    profiles = profiles.filter((p: Profile) => {
      if (!search) return false
      return (
        (p.firstname && p.firstname.toLocaleLowerCase().includes(search)) ||
        (p.lastname && p.lastname.toLocaleLowerCase().includes(search)) ||
        (p.middlename && p.middlename.toLocaleLowerCase().includes(search)) ||
        (p.department && p.department.toLocaleLowerCase().includes(search)) ||
        (p.division && p.division.toLocaleLowerCase().includes(search)) ||
        (p.externalid && p.externalid.toLocaleLowerCase().includes(search)) ||
        (p.group &&
          p.group.name &&
          p.group.name.toLocaleLowerCase().includes(search))
      )
    })
  }

  return profiles
}
