import moment from 'moment-timezone'
import queryString from 'query-string'
import React from 'react'

import { Spinner } from 'content'
import NAP from 'nap'

const CONTENT_TYPE_JPEG = 'image/jpeg'
const CONTENT_TYPE_MJPEG = 'multipart/x-mixed-replace;'
const DEFAULT_MJPEG_INTERVAL = 40 // 40ms
const SIGNATURE_JPEG_START = [0xff, 0xd8]
const SIGNATURE_JPEG_END = [0xff, 0xd9]
const SIGNATURE_PNG_START = [0x89, 0x50, 0x4e, 0x47, 0x0d, 0x0a, 0x1a, 0x0a]
const SIGNATURE_PNG_END = [0x49, 0x45, 0x4e, 0x44, 0xae, 0x42, 0x60, 0x82]

interface Props {
  src: string
  alt?: string
  className?: string
  framerate?: number
  background?: boolean
  width?: number
  height?: number

  onError?: () => boolean
}

interface State {
  imageData: string
  blob: any
}

// MJPEG component required as NAP does not allow to recieve MJPEG stream
// without authentication.
// This component support load MJPEG stream and usual images.
// INFO: usually MJPEG stream has boundary, specified in the headers,
//       between jpeg frames.
// DEPRECTAED: use FRAMES or IMAGE instead
export default class MJPEG extends React.Component<Props, State> {
  unmounted = false
  initialSrc = ''

  player = {
    src: '',
    paused: false,
    progress: 0,
    createdAt: null as any,
    fps: 0,
    rate: 1,
  }

  state = {
    imageData: '',
  } as State

  canvas: HTMLCanvasElement = document.createElement('canvas')
  video: any = React.createRef<HTMLImageElement>()

  abort = new AbortController()
  mjpegInterval = 0

  constructor(props: Props) {
    super(props)
    this.initialSrc = props.src
    this.player.src = props.src
  }

  componentDidMount() {
    this.loadImage()

    this.initialSrc = this.props.src
    this.player.src = this.props.src
  }

  componentDidUpdate() {
    if (this.initialSrc !== this.props.src) {
      this.initialSrc = this.props.src
      this.player.src = this.props.src

      this.reload()
    }
  }

  componentWillUnmount() {
    this.unmounted = true
    try {
      this.abort.abort()
    } catch (error) {}

    clearInterval(this.mjpegInterval)
  }

  reload = () => {
    clearInterval(this.mjpegInterval)

    this.abort.abort()
    this.abort = new AbortController()

    this.loadImage()
  }

  buildSrc(): string {
    const u = queryString.parseUrl(this.initialSrc)
    if (this.player.createdAt) {
      u.query.timestamp = `${Math.round(this.getTimestamp())}`
    }
    u.query.rate = `${this.player.rate}`

    return queryString.stringifyUrl(u)
  }

  loadImage = () => {
    // this.abort = new AbortController()

    this.player.src = NAP.url(this.buildSrc())
    const opt = { headers: NAP.authHeaders(), signal: this.abort.signal }

    // props.opt contains fetch options, ie: headers with authorization token
    fetch(this.player.src, opt)
      .then((resp) => {
        if (!resp.ok) {
          throw Error(resp.statusText)
        }
        if (!resp.body || !resp.headers) {
          throw Error('invalid response')
        }

        return {
          body: resp.body,
          contentType: resp.headers.get('Content-Type') || '',
          createdAt: resp.headers.get('X-CreatedAt') || '',
          fps: resp.headers.get('X-FPS') || '0',
          progress: resp.headers.get('X-Progress') || '0',
          imageType: resp.headers.get('X-Image-Type') || 'image/jpeg',
        }
      })
      .then((resp) => {
        const r = resp.body.getReader()

        this.player.createdAt = moment.unix(parseInt(resp.createdAt))
        this.player.fps = parseInt(resp.fps)
        // this.player.progress = parseInt(resp.progress)

        if (resp.contentType.includes(CONTENT_TYPE_MJPEG)) {
          // check if it MJPEG stream instead a regular image
          // then pass reader to the stream reader
          this.readStream(r, resp.imageType)
        } else {
          this.readImage(r)
        }
      })
      .catch((err: Error) => {
        console.error(err)
        if (this.props.onError) {
          const next = this.props.onError()
          if (!next) return
        }

        if (err.name === 'AbortError') return
      })
  }

  // read whole image data and set to the img
  readImage(r: ReadableStreamDefaultReader) {
    let imageChunks: any = []

    const read = () => {
      if (this.unmounted) return

      r.read().then(({ done, value }) => {
        if (done) {
          this.setImageData(imageChunks)
          return
        }
        imageChunks.push(value)
        read()
      })
    }

    read()
  }

  // read stream by chunks at the specified interval.
  // high interval = less CPU load,
  // usually doesn't make sense set interval less 40ms(25fps).
  readStream(r: ReadableStreamDefaultReader, ctype: string) {
    let imageChunks: Uint8Array[] = []

    const signature = this.getSignatures(ctype)

    const setStartOfImage = (value: Uint8Array, at: number) => {
      at = this.lookupBytes(value, signature.start, at)
      if (at > -1) imageChunks = [value.slice(at)]
      else imageChunks = []
    }

    const read = () => {
      r.read()
        .then(({ done, value }) => {
          if (done || this.unmounted) {
            r.cancel()
            clearInterval(this.mjpegInterval)
            return
          }

          if (this.player.paused) return

          // lookup start of jpeg by magic bytes [0xff 0xdf]
          let at = this.lookupBytes(value, signature.start)

          // lookup end of jpeg by magic bytes [0xff 0xd9]
          // use 'at' offset if it found and more then 0
          let to = this.lookupBytes(value, signature.end, at > 0 ? at : 0)

          if (at > -1 && to > -1) {
            // if found a whole jpeg set it to the img
            this.setImageData([value.slice(at, to + signature.end.length)])
            setStartOfImage(value, to + signature.end.length)
          } else if (at > -1 && to === -1) {
            // if found only start of jpeg
            // clear imageChunks and put only this chunk
            imageChunks = [value.slice(at)]
          } else if (at === -1 && to > -1) {
            // if found onlt end of jpeg
            // put this chunk to the imageChunks
            // and try to display this chunks how image

            imageChunks.push(value.slice(0, to + signature.end.length))
            this.setImageData(imageChunks)
            setStartOfImage(value, to + signature.end.length)
          } else {
            // if start and end of jpeg not found,
            // then it raw jpeg data, apped to the imageChunks
            imageChunks.push(value)
          }
        })
        .catch((err: Error) => {
          if (err.name === 'AbortError') return
          console.error(err)
        })
    }

    const interval = this.props.framerate
      ? 1000 / this.props.framerate
      : DEFAULT_MJPEG_INTERVAL

    this.mjpegInterval = window.setInterval(read, interval)
  }

  getSignatures(ctype: string) {
    ctype = ctype.toLowerCase()
    if (ctype == 'image/png') {
      return { start: SIGNATURE_PNG_START, end: SIGNATURE_PNG_END }
    }

    return { start: SIGNATURE_JPEG_START, end: SIGNATURE_JPEG_END }
  }

  // convert image data to the blob and then set it how image src
  setImageData(value: Uint8Array[]) {
    const blob = new Blob(value, {
      type: CONTENT_TYPE_JPEG,
    })

    const { fps, rate } = this.player
    this.player.progress += (1 / fps) * rate

    if (this.state.imageData) {
      window.URL.revokeObjectURL(this.state.imageData)
    }

    this.setState({ imageData: URL.createObjectURL(blob), blob })
  }

  getFrame = (callback: (frame: any) => void) => {
    var r = new FileReader()
    r.readAsDataURL(this.state.blob)
    r.onloadend = () => {
      const res = r.result as string
      if (res) callback(res.split(',')[1])
    }
  }

  // lookup in raw data jpeg signatures
  lookupBytes(value: Uint8Array, signature: number[], at?: number): number {
    LOOP: while (true) {
      at = value.indexOf(signature[0], at)
      if (at < 0) break

      for (let i = 1; i < signature.length; i++) {
        if (value[at + i] != signature[i]) {
          at += signature.length
          continue LOOP
        }
      }

      return at
    }

    return -1
  }

  getOffset() {
    return parseInt((this.player.progress * 1000) as any)
  }

  getTimestamp() {
    if (this.player.createdAt.unix)
      return this.player.createdAt.unix() + this.player.progress

    return 0
  }

  setOffset(offset: number) {
    this.player.progress = offset
    this.reload()
  }

  playPause(): boolean {
    this.player.paused = !this.player.paused
    return this.player.paused
  }

  isPaused(): boolean {
    return this.player.paused
  }

  setPlaybackRate = (rate: number) => {
    this.player.rate = rate
    this.reload()
  }

  //
  //
  //

  render() {
    const { className, alt, background, width, height } = this.props
    const { imageData } = this.state
    const { src } = this.player

    const style: any = {}
    if (width) style.width = width + 'px'
    if (height) style.height = height + 'px'

    if (!imageData) {
      return (
        <div className={className} style={style}>
          <div className='camera-loader'>
            <Spinner className='spinner-small spinner-center' />
          </div>
        </div>
      )
    }

    if (background) {
      style.backgroundImage = `url(${imageData})`
      return (
        <div
          className={`mjpeg-background ${className}`}
          style={style}
          data-src={src}
        />
      )
    }

    return (
      <img
        ref={(ref) => (this.video = ref)}
        className={className}
        alt={alt}
        src={imageData}
        style={style}
        data-src={src}
      />
    )
  }
}
