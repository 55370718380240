import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter, Link } from 'react-router-dom'
import {
  Panel,
  Footer,
  Table,
  Tag,
  ButtonToolbar,
  Button,
  IconButton,
  Checkbox,
  Whisper,
  Tooltip,
  Uploader,
  InputPicker,
  Dropdown,
} from 'rsuite'
import { FaFileDownload, FaFileUpload, FaPen } from 'react-icons/fa'
import queryString from 'query-string'
import path from 'path'

import NAP, { Car, Group } from 'nap'
import Content, {
  ContentState,
  HeaderSearch,
  DelButton,
  setTitle,
  alert,
  success,
} from 'content'
import PageTable from 'pagetable'

interface RouteParams {
  id: string
}
interface Props extends WithTranslation, RouteComponentProps<RouteParams> {}
interface State extends ContentState {
  cars: Car[]
  groups: Group[]
  toDelete: Record<number, Car>
}

class Cars extends React.Component<Props, State> {
  state = {
    toDelete: {},
  } as State

  constructor(props: Props) {
    super(props)

    const q = queryString.parse(window.location.search)
    if (typeof q.search == 'string') this.state.search = q.search
  }

  componentDidMount() {
    setTitle('profiles.cars.title')

    NAP.groups().then(this.setGroups).catch(this.setError)
    NAP.cars().then(this.setCars).catch(this.setError)
  }

  setGroups = (groups: Group[]) => {
    this.setState({ groups })
  }

  setCars = (cars: Car[]) => {
    this.setState({ cars, loaded: true })
  }

  setError = (err: Error) => {
    this.setState({ error: err.message })
  }

  handleSearch = (search: string) => {
    this.setState({ search })
  }

  handleDelete = (p: Car) => {
    const { toDelete } = this.state
    if (toDelete[p.id]) delete toDelete[p.id]
    else toDelete[p.id] = p
    this.setState({ toDelete })
  }

  perfomDelete = () => {
    let { cars, toDelete } = this.state
    Object.values(toDelete).forEach((p: Car) => {
      NAP.deleteCar(p.id).catch(alert)
    })

    cars = cars.filter((p: Car) => {
      return toDelete[p.id] === undefined
    })

    this.setState({ cars: cars, toDelete: {} })
  }

  handleExport = (ext: string) => {
    NAP.EXPORT(`/cars/export/${ext}`).catch(alert)
  }

  handleImport = (files: any[]) => {
    if (!files.length) return

    const { t } = this.props

    NAP.carsImport(files[0])
      .then(() => {
        success(t('import_success'))
        this.componentDidMount()
      })
      .catch(alert)
  }

  lookupGroup = (group_id: number): string => {
    const { groups } = this.state
    if (!groups) return ''

    const g = groups.find((g: Group) => g.id === group_id)
    if (!g) return ''

    return g.name
  }

  getData = (): Car[] => {
    const { search, groups } = this.state

    let cars = this.state.cars || ([] as Car[])

    if (search) {
      cars = cars.filter((p: Car) => {
        return (
          (p.license_plate && p.license_plate.includes(search)) ||
          (p.make && p.make.includes(search)) ||
          (p.model && p.model.includes(search)) ||
          (p.group && p.group.name && p.group.name.includes(search))
        )
      })
    }

    if (groups) {
      cars.forEach((p: Car) => {
        if (p.group.id && !p.group.name) {
          p.group.name = this.lookupGroup(p.group.id)
        }
      })
    }

    return cars
  }

  //
  //
  //

  render() {
    const { t } = this.props
    const { loaded, error, toDelete } = this.state
    const { Column, HeaderCell, Cell } = Table

    const data = this.getData()

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        <Panel className='content-panel'>
          <PageTable
            data={data}
            total={data.length}
            sort={{ col: 'license_plate', type: 'asc' }}>
            <Column width={120} sortable>
              <HeaderCell>{t('profiles.cars.license_plate')}</HeaderCell>
              <Cell dataKey='license_plate' />
            </Column>

            <Column flexGrow={2} sortable>
              <HeaderCell>{t('name')}</HeaderCell>
              <Cell dataKey='owner'>{this.renderCellName}</Cell>
            </Column>

            <Column flexGrow={1}>
              <HeaderCell>{t('profiles.group')}</HeaderCell>
              <Cell>
                {(p: Car | any) =>
                  p.group &&
                  p.group.name && (
                    <Tag onClick={() => this.handleSearch(p.group.name)}>
                      {p.group.name}
                    </Tag>
                  )
                }
              </Cell>
            </Column>

            <Column fixed='right' align='right' width={100}>
              <HeaderCell> </HeaderCell>
              <Cell className='actions-cell'>
                {(p: Car | any) => (
                  <ButtonToolbar>
                    <Link
                      to={path.join(window.location.pathname, p.id.toString())}>
                      <IconButton appearance='subtle' icon={<FaPen />} />
                    </Link>
                    <Checkbox
                      checked={toDelete[p.id] !== undefined}
                      onChange={() => this.handleDelete(p)}
                    />
                  </ButtonToolbar>
                )}
              </Cell>
            </Column>
          </PageTable>

          <Footer className='footer-buttons'>
            <Link to={path.join(window.location.pathname, 'new')}>
              <Button appearance='primary'>{t('profiles.new')}</Button>
            </Link>
          </Footer>
        </Panel>
      </Content>
    )
  }

  renderCellName = (p: Car | any) => {
    const name = [] as string[]
    if (p.owner) name.push(p.owner)
    if (p.make) name.push(p.make)
    if (p.model) name.push(p.model)
    return name.join(' ')
  }

  renderHeader() {
    const { t } = this.props
    const { cars, search, groups, toDelete } = this.state

    return (
      <HeaderSearch
        onSearch={this.handleSearch}
        value={search}
        left={
          <InputPicker
            data={groups || []}
            labelKey='name'
            valueKey='name'
            placeholder={t('profiles.group')}
            onChange={(v) => this.handleSearch(v)}
          />
        }
        right={
          <ButtonToolbar align='right'>
            {/* export */}
            <Dropdown
              onSelect={this.handleExport}
              renderToggle={(props) => (
                <Whisper
                  {...props}
                  placement='bottom'
                  trigger='hover'
                  speaker={<Tooltip>{t('profiles.cars.export')}</Tooltip>}>
                  <IconButton
                    icon={<FaFileDownload />}
                    disabled={!cars || !cars.length}
                    circle
                  />
                </Whisper>
              )}
              disabled={!cars || !cars.length}
              placement='bottomEnd'>
              <Dropdown.Item eventKey='csv' className='dropdown-buttons'>
                CSV
              </Dropdown.Item>
              <Dropdown.Item eventKey='xlsx' className='dropdown-buttons'>
                XLSX
              </Dropdown.Item>
            </Dropdown>

            {/* import */}
            <Uploader
              action={NAP.url('/api/cars/import')}
              headers={NAP.authHeaders()}
              onChange={this.handleImport}
              onError={(r) => alert(r.response.error)}
              listType='text'
              accept='.csv,text/csv,.xlsx,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet'
              fileList={[]}
              autoUpload={false}
              fileListVisible={false}>
              <Whisper
                placement='bottom'
                trigger='hover'
                speaker={<Tooltip>{t('profiles.cars.import')}</Tooltip>}>
                <IconButton icon={<FaFileUpload />} circle />
              </Whisper>
            </Uploader>

            {/* delete */}
            <DelButton
              onConfirm={this.perfomDelete}
              disabled={!Object.keys(toDelete).length}
              circle
            />
          </ButtonToolbar>
        }
      />
    )
  }
}

export default withTranslation()(withRouter(Cars))
