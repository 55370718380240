import filesize from 'file-size'
import humanizeDuration from 'humanize-duration'
import i18next from 'i18next'
import moment from 'moment-timezone'
import path from 'path'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  FaCut,
  FaDownload,
  FaImage,
  FaList,
  FaPen,
  FaRegClock,
  FaTimes,
  FaVideo,
} from 'react-icons/fa'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Link } from 'react-router-dom'
import {
  Button,
  ButtonToolbar,
  Divider,
  Dropdown,
  IconButton,
  Message,
  Panel,
  Popover,
  Progress,
  RangeSlider,
  Stack,
  Table,
  Tag,
  Tooltip,
  Whisper,
} from 'rsuite'

import Content, { ContentState, HeaderSearch, alert, setTitle } from 'content'
import ROUTES from 'routes'
import NAP, {
  ArchiveCamera,
  ArchiveDrawAndCutProcess,
  ArchiveFile,
} from 'nap'
import Calendar from 'utils/calendar'
import { addQuery, getQuery, setQuery } from 'utils/query'
import VideoArchivePlayer, { FrameEvents } from './videoarchiveplayer'

import PageTable from 'pagetable'
import './videoarchive.less'

export const DateFormat = 'YYYY-MM-DD'
export const DateTimeFormat = 'YYYY-MM-DD-HH-mm-ss'

interface Props extends WithTranslation {}

interface State extends ContentState {
  cameras: ArchiveCamera[]
  dateAt: moment.Moment
  dateTo: moment.Moment
  hours: [number, number]

  camera?: ArchiveCamera
  file?: ArchiveFile

  processes?: ArchiveDrawAndCutProcess[]

  cut: boolean
}

class VideoArchive extends React.Component<Props, State> {
  state = {
    dateAt: moment().startOf('day'),
    dateTo: moment().add(10, 'minutes'),
    hours: [0, 24],
  } as State
  video: any = React.createRef<VideoArchive>()

  dur = humanizeDuration.humanizer({
    round: true,
    delimiter: ' ',
    spacer: '',
    largest: 2,
    units: ['h', 'm', 's'],
    language: i18next.language,
    languages: {
      en: {
        h: () => 'h',
        m: () => 'm',
        s: () => 's',
      },
      ru: {
        h: () => 'ч',
        m: () => 'м',
        s: () => 'с',
      },
      es: {
        h: () => 'h',
        m: () => 'м',
        s: () => 's',
      },
    },
  })

  processesInterval = 0

  constructor(props: Props) {
    super(props)
  }

  componentDidMount() {
    setTitle('videoarchive.title')

    NAP.time().then((time) => {
      moment.tz.setDefault(time.zone)
      this.parseQuery()
      this.loadCameras()
    })

    this.loadDrawProcesses()
    this.processesInterval = window.setInterval(this.loadDrawProcesses, 3500)
  }

  componentWillUnmount() {
    window.clearInterval(this.processesInterval)
  }

  parseQuery = (set?: boolean) => {
    const q = getQuery()

    let dateAt = moment().startOf('day')
    let dateTo = moment().add(10, 'minutes')

    if (q.time) {
      const t = moment(q.time, DateTimeFormat)
      if (t.isValid()) {
        dateAt = t.clone().startOf('day')
        dateTo = t.clone().endOf('day')
      }
    }

    if (q.dateAt) {
      const t = moment(q.dateAt)
      if (t.isValid()) dateAt = t.startOf('day')
    }

    if (q.dateTo) {
      const t = moment(q.dateTo)
      if (t.isValid()) dateTo = t.endOf('day')
    }

    this.state.dateAt = dateAt
    this.state.dateTo = dateTo

    if (q.hours) {
      this.state.hours = [parseInt(q.hours[0]), parseInt(q.hours[1])]
    }

    if (set) this.setState({ dateAt, dateTo })
  }

  loadDrawProcesses = () => {
    NAP.archiveDrawAndCutProcesses()
      .then((processes) => this.setState({ processes }))
      .catch(console.error)
  }

  loadCameras = () => {
    const { dateAt, dateTo } = this.state
    const q = getQuery()

    NAP.archive(dateAt.format(DateFormat), dateTo.format(DateFormat))
      .then((cameras: ArchiveCamera[]) => {
        let camera: any
        let file: any

        const time = q.time ? moment(q.time, DateTimeFormat) : undefined

        cameras?.forEach((cam: ArchiveCamera) => {
          cam.time_at = moment(cam.time_at)
          cam.time_to = moment(cam.time_to)

          if (q.camera) {
            if (cam.name === q.camera || cam.id.toString() === q.camera) {
              camera = cam
            }
          }

          if (cam.files) {
            this.prepareFiles(cam.files)

            cam.files.forEach((f: ArchiveFile) => {
              if (!file && camera) {
                if (q.file && q.file === f.name) {
                  file = f
                } else if (time) {
                  const at = moment(f.time)
                  const to = moment(f.time).add(f.duration, 's')

                  if (
                    at.isSame(time) ||
                    to.isSame(time) ||
                    (at.isBefore(time) && to.isAfter(time))
                  ) {
                    addQuery({
                      progress: time.diff(f.etime, 's').toString(),
                    })
                    file = f
                  }
                }
              }
            })
          }
        })

        if (file && file.time.isBefore(this.state.dateAt)) {
          this.state.dateAt = moment(file.time).startOf('day')
          this.state.dateTo = moment(file.time).endOf('day')

          console.log(
            'file',
            this.state.dateAt.format(DateTimeFormat),
            this.state.dateTo.format(DateTimeFormat)
          )
        }

        this.setState({
          cameras: cameras || [],
          loaded: true,
          camera: camera,
          file: file,
        })
      })
      .catch((err) => this.setState({ error: err.message, loaded: true }))
  }

  selectDate = (daterange: Date[]) => {
    let dateAt = moment(daterange[0]).startOf('day')
    let dateTo = moment(daterange[1]).endOf('day')

    // reset hours range if selected more then one days
    let hours = this.state.hours
    if (!dateAt.isSame(dateTo, 'day')) hours = [0, 24]

    this.setState({ dateAt, dateTo, hours }, this.loadCameras)
    setQuery({
      dateAt: dateAt.format(DateFormat),
      dateTo: dateTo.format(DateFormat),
      hours,
    })
  }

  selectHours = (hours: [number, number]) => {
    if (this.state.hours[0] !== hours[0] || this.state.hours[1] !== hours[1]) {
      addQuery({ hours }, true)
      this.setState({ hours })
    }
  }

  prepareFiles = (files: ArchiveFile[]) => {
    // const offset = moment().utcOffset() * 60

    files.forEach((f: ArchiveFile) => {
      f.etime = moment(f.time)
      f.time = moment(f.time)
    })

    files.sort((a, b): number => {
      if (moment(a.time).isBefore(moment(b.time))) return 1
      return -1
    })
  }

  disabledDate = (date: Date | moment.Moment) => {
    const d = moment(date).format(DateFormat)

    return !this.state.cameras.some((cam: ArchiveCamera) => {
      return cam.calendar[d] > 0
      // return cam.files?.some((f: ArchiveFile) => f.time.isSame(date, 'day'))
    })
  }

  handleOpen = (cam: ArchiveCamera, file: ArchiveFile) => {
    addQuery({ camera: cam.name, file: file.name, progress: 0 })
    this.setState({ camera: cam, file: file })
    this.video?.setProgress(0)
  }

  handleClose = () => {
    setQuery({ camera: undefined, file: undefined, progress: undefined })
    this.setState({ camera: undefined, file: undefined })
  }

  getTicks() {
    const { dateAt, dateTo, hours } = this.state
    let ticks = [] as any[]

    if (dateAt.isSame(dateTo, 'day')) {
      let at: number = hours[0]
      let to: number = Math.min(hours[1], dateTo.hours() + 1)

      for (let h = at; h <= to; h++) {
        ticks.push(h)
      }
    } else {
      let date = moment(dateAt)
      while (date.isSameOrBefore(dateTo)) {
        ticks.push(date.format('D MMM'))
        date.add(1, 'day')
      }
    }

    return ticks
  }

  screenshot = () => {
    this.video.getFrameData().then((data: FrameEvents) => {
      NAP.drawFrameEvents(data.frame, data.events, data.name).catch(alert)
    })
  }

  handleSort = (col: string, sort?: string) => {
    const { cameras } = this.state

    const v = sort == 'desc' ? 1 : -1

    this.setState({
      cameras: cameras.sort((a: ArchiveCamera, b: ArchiveCamera): number => {
        return a[col] < b[col] ? v : -v
      }),
    })
  }

  handleSearch = (search: string) => {
    this.setState({ search })
  }

  handleDeleteProcess = (id: string) => {
    NAP.deleteArchiveDrawAndCutProcesses(id).then((processes) => {
      this.setState({ processes })
    })
  }

  getData(): ArchiveCamera[] {
    const { search } = this.state

    let cameras = this.state.cameras || []

    if (search) {
      const s = search.toLocaleLowerCase()
      cameras = cameras.filter((cam: ArchiveCamera) => {
        return cam.name.toLocaleLowerCase().includes(s)
      })
    }

    return cameras
  }

  //
  // render
  //

  render() {
    const { t } = this.props
    const { loaded, error, cameras, camera, file, dateAt, dateTo, cut } =
      this.state

    const { Column, HeaderCell, Cell } = Table

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        {camera && file && (
          <VideoArchivePlayer
            ref={(ref) => (this.video = ref)}
            camera={camera}
            file={file}
            cut={cut}
            t={t}
          />
        )}

        {this.renderProcesses()}

        <Panel className='content-panel'>
          {(!cameras || !cameras.length) && (
            <Message>{t('videoarchive.no_data')}</Message>
          )}
          <PageTable
            data={this.getData()}
            total={cameras?.length || 0}
            sortColumn='name'
            onSortColumn={this.handleSort}>
            <Column flexGrow={1} sortable>
              <HeaderCell>{t('name')}</HeaderCell>
              <Cell dataKey='name' />
            </Column>

            <Column flexGrow={5}>
              <HeaderCell align='center'>
                {t('videoarchive.dates')}
                {this.renderTicks()}
              </HeaderCell>
              <Cell>
                {(cam: ArchiveCamera | any) => this.renderTimeline(cam)}
              </Cell>
            </Column>

            <Column fixed='right' align='right' width={140}>
              <HeaderCell> </HeaderCell>
              <Cell className='actions-cell'>
                {(cam: ArchiveCamera | any) => (
                  <ButtonToolbar justifyContent='flex-end'>
                    {cam.files && cam.files.length > 0 && (
                      <Whisper
                        placement='auto'
                        trigger='click'
                        speaker={(props, ref) => {
                          const { className, left, top, onClose } = props
                          return (
                            <Popover
                              ref={ref}
                              className={className}
                              style={{ left, top }}
                              full>
                              <PerfectScrollbar>
                                <Dropdown.Menu style={{ maxHeight: '50vh' }}>
                                  {cam.files.map((f, i) => {
                                    const prev = cam.files[i - 1]
                                    const next = cam.files[i + 1]
                                    return (
                                      <div key={f.name}>
                                        {f.time.isAfter(dateAt) &&
                                          prev &&
                                          prev.time.isBefore(dateAt) && (
                                            <Divider>
                                              {dateAt.format('DD.MM.YYYY')}
                                            </Divider>
                                          )}
                                        <Dropdown.Item
                                          onClick={() => {
                                            this.handleOpen(cam, f)
                                            onClose()
                                          }}
                                          active={
                                            cam.name === camera?.name &&
                                            f.name === file?.name
                                          }>
                                          <Stack spacing={20}>
                                            <div className='archive-filename'>
                                              {f.name}
                                            </div>
                                            <Tag>
                                              {this.dur(f.duration * 1000)}
                                            </Tag>
                                            <Tag>
                                              {filesize(f.size, {
                                                fixed: 0,
                                                spacer: ' ',
                                              }).human('si')}
                                            </Tag>
                                          </Stack>
                                        </Dropdown.Item>
                                        {f.time.isBefore(dateTo) &&
                                          next &&
                                          next.time.isAfter(dateTo) && (
                                            <Divider />
                                          )}
                                      </div>
                                    )
                                  })}
                                </Dropdown.Menu>
                              </PerfectScrollbar>
                            </Popover>
                          )
                        }}>
                        <IconButton icon={<FaList />} appearance='subtle' />
                      </Whisper>
                    )}

                    <Link
                      to={ROUTES.livestream + '?camera=' + cam.id.toString()}
                      className={!cam.enabled ? 'disabled' : ''}>
                      <IconButton
                        disabled={!cam.enabled}
                        appearance='subtle'
                        icon={<FaVideo />}
                      />
                    </Link>

                    <Link
                      to={path.join(ROUTES.settings.cameras, cam.id.toString())}
                      className={!cam.id ? 'disabled' : ''}>
                      <IconButton
                        disabled={!cam.id}
                        appearance='subtle'
                        icon={<FaPen />}
                      />
                    </Link>
                  </ButtonToolbar>
                )}
              </Cell>
            </Column>
          </PageTable>
        </Panel>
      </Content>
    )
  }

  renderNameCell = (cam: ArchiveCamera | any) => {
    let className = ''
    if (this.state.camera && cam.name === this.state.camera.name) {
      className = 'selected'
    }

    return <div className={className}>{cam.name}</div>
  }

  renderTicks() {
    const ticks = this.getTicks()

    return (
      <div className='archive-timeline-ticks'>
        {ticks.map((tick: any, i: number) => (
          <span
            key={tick}
            className='archive-tick'
            style={{ left: (i / ticks.length) * 100 + '%' }}>
            {tick}
          </span>
        ))}
      </div>
    )
  }

  renderHeader() {
    const { t } = this.props
    const { dateAt, dateTo, hours, camera, file, search, cut } = this.state

    return (
      <HeaderSearch
        value={search}
        onSearch={this.handleSearch}
        left={
          <ButtonToolbar>
            <Calendar
              value={[toDate(dateAt), toDate(dateTo)]}
              onChange={(d: any) => this.selectDate(d)}
              disabledDate={this.disabledDate}
            />
            {dateAt.isSame(dateTo, 'day') && (
              <Whisper
                placement='bottom'
                trigger='click'
                speaker={
                  <Popover className='hoursrange-container'>
                    <RangeSlider
                      min={0}
                      max={24}
                      value={hours}
                      onChange={this.selectHours}
                      graduated
                      renderMark={(n: number) => n}
                    />
                  </Popover>
                }>
                <Button className='hoursrange-btn'>
                  <FaRegClock />
                  {hours[0].toString().padStart(2, '0')}
                  {` - `}
                  {hours[1].toString().padStart(2, '0')}
                </Button>
              </Whisper>
            )}
          </ButtonToolbar>
        }
        right={
          <div style={{ width: 200 }}>
            {camera && file && (
              <ButtonToolbar justifyContent='flex-end'>
                <Whisper
                  placement='bottom'
                  trigger='hover'
                  speaker={<Tooltip>{t('videoarchive.cut_file')}</Tooltip>}>
                  <IconButton
                    appearance={cut ? 'primary' : 'default'}
                    onClick={() => this.setState({ cut: !cut })}
                    icon={<FaCut />}
                    circle
                  />
                </Whisper>

                <Whisper
                  placement='bottom'
                  trigger='hover'
                  speaker={
                    <Tooltip>{t('videoarchive.download_file')}</Tooltip>
                  }>
                  <a
                    href={NAP.url(file.path)}
                    download={NAP.url(file.path)}
                    target='_blank'>
                    <IconButton icon={<FaDownload />} circle />
                  </a>
                </Whisper>

                <Whisper
                  placement='bottom'
                  trigger='hover'
                  speaker={
                    <Tooltip>{t('videoarchive.download_frame')}</Tooltip>
                  }>
                  <IconButton
                    onClick={this.screenshot}
                    icon={<FaImage />}
                    circle
                  />
                </Whisper>

                <Whisper
                  placement='bottom'
                  trigger='hover'
                  speaker={<Tooltip>{t('close')}</Tooltip>}>
                  <IconButton
                    onClick={this.handleClose}
                    appearance='primary'
                    color='red'
                    icon={<FaTimes />}
                    circle
                  />
                </Whisper>
              </ButtonToolbar>
            )}
          </div>
        }>
        {/* <HeaderTitle>{t('videoarchive.title')}</HeaderTitle> */}
      </HeaderSearch>
    )
  }

  renderTimeline(cam: ArchiveCamera) {
    const { dateAt, dateTo, hours, camera, file } = this.state

    // default range for multiple days
    let at = 0
    let total = (dateTo.diff(dateAt, 'days') + 1) * 1440
    let step = 3600

    if (dateAt.isSame(moment(), 'day')) {
      at = hours[0]
      let to = Math.min(hours[1], dateTo.hours() + 1)
      total = (to + 1 - at) * 60
      step = 60
    } else if (dateAt.isSame(dateTo, 'day')) {
      at = hours[0]
      total = (hours[1] + 1 - at) * 60
      step = 60
    }

    return (
      <div className='archive-timeline'>
        {cam.files &&
          cam.files.map((f: ArchiveFile, i: number) => {
            const ftime = f.time
            if (ftime.isBefore(dateAt, 'day') || ftime.isAfter(dateTo, 'day')) {
              return null
            }
            if (f.duration < 0) f.duration = 0

            const diff = ftime.diff(dateAt, 'minutes')
            const left = ((diff - at * 60) / total) * 100 + '%'
            const width = (f.duration / 60 / total) * 100 + '%'

            let roundLeft = true
            let roundRight = true

            if (!cam.files) return null

            const prev = cam.files[i - 1]
            if (prev && prev.time.isSame(f.time, 'day')) {
              roundLeft = f.seconds - prev.seconds - prev.duration > step
            }

            const next = cam.files[i + 1]
            if (next && next.time.isSame(f.time, 'day')) {
              roundRight = next.seconds - f.seconds - f.duration > step
            }

            return (
              <Whisper
                key={f.name}
                placement='top'
                trigger='hover'
                speaker={
                  <Tooltip style={{ textAlign: 'center' }}>
                    <div>{ftime.format('D MMM HH:mm')}</div>
                    <div>{this.dur(f.duration * 1000)}</div>
                    {/* <div>{(f.duration / 60).toFixed(0)}m</div> */}
                  </Tooltip>
                }>
                <div
                  onClick={() => this.handleOpen(cam, f)}
                  className={`archive-timeline-file 
                ${roundLeft && 'round-left'} 
                ${roundRight && 'round-right'}`}
                  style={{ left: left, width: width }}
                  data-name={f.name}
                  data-selected={
                    camera?.name === cam.name && file?.name === f.name
                  }
                />
              </Whisper>
            )
          })}
      </div>
    )
  }

  renderDate(date: moment.Moment, i: number) {
    if (i === 0) return date.format('D MMM')
    if (date.get('date') === 1) return date.format('D MMM')

    return date.format('D')
  }

  renderProcesses() {
    const { processes } = this.state
    if (!processes?.length) return null

    return (
      <Panel className='content-panel'>
        {processes.map(this.renderProcess)}
      </Panel>
    )
  }

  renderProcess = (p: ArchiveDrawAndCutProcess) => {
    return (
      <div className='archive-drawandcut-progress' key={p.id}>
        <Progress.Line
          percent={p.progress}
          status={p.done ? 'success' : 'active'}
        />
        {p.done ? (
          <a
            href={NAP.url(p.result)}
            download={NAP.url(p.result)}
            target='_blank'>
            <IconButton appearance='primary' icon={<FaDownload />} />
          </a>
        ) : (
          <IconButton icon={<FaDownload />} disabled />
        )}

        <IconButton
          appearance='primary'
          color='red'
          icon={<FaTimes />}
          onClick={() => this.handleDeleteProcess(p.id)}
        />
      </div>
    )
  }
}

export default withTranslation()(VideoArchive)

function toDate(t: moment.Moment) {
  return new Date(t.clone().utcOffset(t.utcOffset()).format('YYYY-MM-DD HH:mm'))
}
