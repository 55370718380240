import React from 'react'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import InnerHTML from 'dangerously-set-html-content'
import Mustache from 'mustache'
import path from 'path'

import NAP, { Page } from 'nap'
import Content, {
  alert,
  ContentState,
  Header,
  HeaderTitle,
  Spinner,
} from 'content'
import ROUTES from 'routes'
import { Message } from 'rsuite'

interface RouteParams {
  name?: string
}

interface Props extends RouteComponentProps<RouteParams> {}
interface State extends ContentState {
  name: string
  page?: Page
}

class Pages extends React.Component<Props, State> {
  state = {} as State

  componentDidMount() {
    this.loadPage()
  }

  componentDidUpdate(props: Props) {
    if (this.props.match.params.name !== props.match.params.name) {
      this.loadPage()
    }
  }

  loadPage = () => {
    const { name } = this.props.match.params
    if (!name) return

    NAP.page(name).then(this.setPage).catch(alert)
  }

  setPage = (page: Page) => {
    this.setState({ page, loaded: true })
  }

  getTemplateArgs() {
    const { page } = this.state
    if (!page) return {}

    return {
      PATH: NAP.url(path.join(ROUTES.pages, page.name)),
      HOST: NAP.addr,
    }
  }

  //
  //
  //

  render() {
    const { loaded } = this.state

    return (
      <Content loaded={loaded} header={this.renderHeader()}>
        {this.renderPage()}
      </Content>
    )
  }

  renderPage() {
    const { page } = this.state
    if (!page) return <Spinner className='spinner-screen' />

    if (!page.data && !page.iframe)
      return (
        <Message type='error' showIcon>
          no data
        </Message>
      )

    return [this.renderHTML(), this.renderIframe()]
  }

  renderIframe() {
    const { page } = this.state
    if (!page || !page.iframe) return null

    return (
      <iframe
        key='iframe'
        className='pages-iframe'
        title={page.name}
        src={page.iframe}
      />
    )
  }

  renderHTML() {
    const { page } = this.state
    if (!page || !page.data) return null

    const html = Mustache.render(page.data, this.getTemplateArgs())
    return <InnerHTML key='html' html={html} />
  }

  renderHeader() {
    const { page } = this.state

    return (
      <Header>
        <HeaderTitle>{page?.title || page?.name || ''}</HeaderTitle>
      </Header>
    )
  }
}

export default withRouter(Pages)
