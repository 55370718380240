import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Link } from 'react-router-dom'
import {
  Panel,
  Table,
  ButtonToolbar,
  Button,
  IconButton,
  Checkbox,
  Whisper,
  Tooltip,
  Tag,
  Footer,
} from 'rsuite'
import { FaPen } from 'react-icons/fa'
import path from 'path'

import NAP, { Chart } from 'nap'
import Content, {
  ContentState,
  HeaderSearch,
  DelButton,
  setTitle,
  alert,
} from 'content'
import { default as ChartWidget } from './chartwidget'

interface Props extends WithTranslation {}
interface State extends ContentState {
  charts: Chart[]
  toDelete: Record<number, Chart>
  open?: Chart
}

export class Charts extends React.Component<Props, State> {
  state = {
    toDelete: {},
  } as State

  componentDidMount() {
    setTitle('charts.title')

    NAP.charts()
      .then((charts: Chart[]) => this.setState({ charts, loaded: true }))
      .catch(this.setError)

    const q = new URLSearchParams(window.location.search)
    const chartid = q.get('chart')
    if (chartid) {
      NAP.chart(parseInt(chartid))
        .then((chart: Chart) => this.setState({ open: chart }))
        .catch(alert)
    }
  }

  setError = (err: Error) => {
    this.setState({ error: err.message, loaded: true })
  }

  //
  // handlers
  //

  handleSearch = (search: string) => {
    this.setState({ search })
  }

  handleDelete = (d: Chart) => {
    const { toDelete } = this.state
    toDelete[d.id] ? delete toDelete[d.id] : (toDelete[d.id] = d)

    this.setState({ toDelete })
  }

  openChart = (chart: Chart) => {
    window.history.pushState('', chart.name, `?chart=${chart.id}`)
    this.setState({ open: chart })
  }

  perfomDelete = () => {
    let { charts, toDelete } = this.state

    Object.values(toDelete).forEach((c: Chart) => {
      NAP.deleteChart(c.id).catch(alert)
    })

    charts = charts.filter((c: Chart) => {
      return toDelete[c.id] === undefined
    })

    this.setState({ charts, toDelete: {} })
  }

  getData(): Chart[] {
    const search = this.state.search || ''
    let charts = this.state.charts || ([] as Chart[])

    if (search) {
      charts = charts.filter((c: Chart) => {
        return c.name.includes(search) || (c.types && c.types.includes(search))
      })
    }

    return charts
  }

  //
  // render
  //

  render() {
    const { loaded, error, open } = this.state

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        {open !== undefined && (
          <Panel className='content-panel' header={open.name}>
            <ChartWidget {...open} height={500} />
          </Panel>
        )}
        {this.renderCharts()}
      </Content>
    )
  }

  renderHeader() {
    const { search, toDelete } = this.state

    return (
      <HeaderSearch
        onSearch={this.handleSearch}
        value={search}
        right={
          <DelButton
            onConfirm={this.perfomDelete}
            disabled={!Object.keys(toDelete).length}
            circle
          />
        }
      />
    )
  }

  renderCharts() {
    const { t } = this.props
    const { loaded, toDelete, open } = this.state
    const charts = this.getData()

    const { Column, HeaderCell, Cell } = Table

    return (
      <Panel className='content-panel'>
        <Table data={charts} loading={!loaded} autoHeight>
          <Column flexGrow={1}>
            <HeaderCell>{t('name')}</HeaderCell>
            <Cell>
              {(c: Chart | any) => (
                <span
                  className={`link ${
                    open && open.id === c.id ? 'linkfocus' : ''
                  }`}
                  onClick={() => this.openChart(c)}>
                  {c.name}
                </span>
              )}
            </Cell>
          </Column>

          <Column width={150}>
            <HeaderCell>{t('type')}</HeaderCell>
            <Cell align='center'>
              {(c: Chart | any) => <Tag>{c.type.toUpperCase()}</Tag>}
            </Cell>
          </Column>

          <Column fixed='right' align='right' width={100}>
            <HeaderCell> </HeaderCell>
            <Cell className='actions-cell'>
              {(c: Chart | any) => (
                <ButtonToolbar>
                  <Whisper
                    placement='top'
                    trigger='hover'
                    speaker={<Tooltip>{t('edit')}</Tooltip>}>
                    <Link
                      to={path.join(window.location.pathname, c.id.toString())}>
                      <IconButton appearance='subtle' icon={<FaPen />} />
                    </Link>
                  </Whisper>
                  <Checkbox
                    checked={toDelete[c.id] !== undefined || false}
                    onChange={() => this.handleDelete(c)}
                  />
                </ButtonToolbar>
              )}
            </Cell>
          </Column>
        </Table>
        <Footer className='footer-buttons'>
          <Link to={path.join(window.location.pathname, 'new')}>
            <Button appearance='primary'>{t('charts.add_chart')}</Button>
          </Link>
        </Footer>
      </Panel>
    )
  }
}

export default withTranslation()(Charts)
