import moment from 'moment-timezone'

export interface User {
  id: number
  username: string
  password: string // password is never filled in from the NAP side
  name: string
  email: string
  photo: string

  // role_id: number
  // role: Role | null

  roles_id: number[] | null
  roles: Role[] | null

  profile_id: number

  groups_id: number[] | null
  devices_id: number[] | null
  cameras_id: number[] | null
  self_events: boolean
  new_profiles_group: number

  dashboard_id: number

  superadmin?: boolean
}

export interface Role {
  id: number
  title: string
  permissions: Permission[] | null
  sectors: string[] | null
}

export interface Permission {
  sectionpath: string
  access: number
}

export interface Dashboard {
  id: number
  uuid: string
  name: string
  desc: string

  layouts: any
  widgets: Widget[] | null

  hide: boolean
  lock: boolean
  default: boolean

  // access: number
  // permissions: DashboardPermission[]

  owner_id: number
}

export interface DashboardPermission {
  user_id: number
  access: number
}

export interface Widgets {
  layouts: any
  widgets: Widget[] | null
}

export interface Widget {
  id: string
  name: string
  // width: number
  // height: number
  // position: [number, number]
  type: string

  handlerData: Record<string, any> | null
  params: SettingsField[] | null

  data?: any
  handler?: any
  error?: string
}

export interface Camera {
  id: number
  name: string
  uuid: string
  type: string
  addr: string
  desc: string
  sector: string
  stream_path: string

  module: string
  module_path: string

  enabled: boolean
  status: string
  started_at: string | any

  params:
    | CameraMDASH
    | CameraMJPEG
    | CameraCACHE
    | CameraVIDEO
    | CameraIMAGE
    | CameraWEBCAM
    | null

  analytics_enabled: boolean
  analytics_regions?: Region[]
  analytics_lines?: Line[]

  schemes_id: number[] | null
  schemes?: Scheme[] // frontend only
  devices: number[] | null

  errors?: NAPError[] | null
}

export interface CameraForm {
  form?: SettingsField[]
  support: CameraSupport
}

export interface CameraSupport {
  addr: string
  sub_addr: string
  archive: string
  // ...
}

export interface CameraMDASH extends IpcamOpt, ArchiveOpt {
  input_args: string
  decode: DecodeOpt

  sync: boolean
  sync_time: number
}

export interface CameraMJPEG extends IpcamOpt, ArchiveOpt {
  input_args: string
  transpose: TransposeOpt
  decode: DecodeOpt

  sync: boolean

  input_formats?: string[] | null
  input_format: string
}

export interface CameraCACHE {
  input_args: string
  decode: DecodeOpt
  export: boolean
}

export interface CameraVIDEO {
  framerate: number
  cache: boolean
}

export interface CameraIMAGE {
  framerate: number
  validate: boolean
  unique: boolean
  logerrors: boolean
}

export interface CameraWEBCAM extends ArchiveOpt {
  framerate: number
}

export interface DecodeOpt {
  framerate: number
  quality: number
}

export interface TransposeOpt {
  framesize: string
  crop: string
  rotate: string
  transpose: number
  hflip: boolean
  vflip: boolean
}

export interface IpcamOpt {
  sub_addr: string
  rtsp_tcp: boolean
  stream_loop: boolean
  ptz: PTZ
}

export interface ArchiveOpt {
  archive_enabled: boolean
  archive_size: number
  archive_mp4: boolean
  archive_stream: string

  archive_byevents: boolean
  archive_byevents_before: number
  archive_byevents_after: number
}

export interface PTZ {
  enabled: boolean
  port: string
  data: PTZdata
}

export interface PTZdata {
  x: number
  y: number
  zoom: number
}

export interface CameraInputFormat {
  format: string
  size: string
  value: string
}

export interface CameraStatus {
  active: boolean
  enabled: boolean
  status: string

  // bitrate: string
  // cpu: string
  // fps: string
  // framesize: string
  // mem: string
  // video: string
  // duration: string

  stat: Stat[] | null

  archive: {
    files: number
    size: number
    time_at: string
    time_to: string
  }

  decoding?: CameraCacheState
  analytics?: CameraCacheState
  encoding?: CameraExportState

  errors: NAPError[] | null

  cmdline: string
  log: NAPError[]
}

export interface ImgSize {
  width: number
  height: number
}

export interface CameraCacheState {
  state: 0 | 1 | 2
  progress: number
}

export interface CameraExportState {
  state: 0 | 1 | 2
  progress: number
  filename: string
}

export interface CameraScan {
  type: string
  addr: string
  name: string
}

export interface NAPError {
  time: string
  message: string
}

export interface Scheme {
  id: number
  name: string
  desc: string
  enabled: boolean

  framerate: number
  latency?: number

  blocks: Record<string, SchemeBlock>
  links: Record<string, SchemeLink>

  errors?: NAPError[]
}

export interface SchemeBlock {
  id: string
  type: string
  data: Record<string, any>
  uuid: string[] | null
  position: { x: number; y: number }
  active: boolean

  info?: SchemeBlockInfo

  // internal
  [key: string]: any
}

export interface SchemeBlockInfo {
  latency: number
  out_items: number
  out_pass: boolean
  error: NAPError
}

export interface SchemeLink {
  true: string[] // | null
  false: string[] // | null

  // internal
  [key: string]: any
}

export interface CameraActivity {
  id: number
  name: string
  data: ActivityData
}

export type Point = [number, number]

export interface Region {
  id: string
  name: string
  color: number[]
  points: Point[]
  svgPoints?: string

  bbox?: number[]
  edit?: boolean
}

export interface Line {
  id: string
  name: string
  color: number[]
  a: Point
  b: Point
  direction: string

  edit?: boolean
}

export const DirectionNone = ''
export const DirectionLeft = 'left'
export const DirectionRight = 'right'

export interface Notification {
  id: number
  name: string
  type: string
  desc: string
  enabled: boolean
  system: boolean

  recipients: string[] | null
  subject: string
  text: string
  draw: boolean
  resize: number | string

  errors: NAPError[] | null

  client_values: any | null
  status: {
    variables: SettingsField[] | null
    contacts: NotifyContact[] | null
  }
}

export interface NotifyMessage {
  title: string
  text: string
  image: string
  event: Event

  timeout: number
  position: string
  confirmation: boolean
}

export interface NotifyContact {
  name: string
  value: string
}

export interface SettingsField {
  type: string
  name: string
  label: string
  desc: string
  system: boolean
  readonly: boolean
  required: boolean
  value: any
  default: any
  unit: string

  selected: boolean

  data: any
  width: number
}

export interface EventsFilter {
  id?: number
  time_at?: number
  time_to?: number
  limit?: number
  offset?: number
  alerts?: boolean
  [key: string]: any
}

export interface ReportsFilters {
  cameras: ShortModel[]
  sectors: string[] | ShortModel[]
  analytics: ShortModel[]
  schemes: ShortModel[]
  users: ShortModel[]
  groups: ShortModel[]
  profiles: ShortModel[]
  cars: ShortModel[]
  types: string[] | ShortModel[]
}

export interface ReportsConfig {
  columns: Record<string, boolean>
  reports_level: number
  feed_level: number
  hidden: boolean
}

export interface ReportEvents {
  events: Event[]
  total: number
  chartdata?: ChartData
}

export interface Event {
  id: number
  time_at: number | any
  time_to: number | any

  frame: string
  images?: string[]

  sector: string
  camera: string
  camera_id: number

  analytics: string
  analytics_id: number

  video?: string
  offset?: number

  archive_file?: string

  user?: string
  user_id?: number

  items: Item[] | null
  texts?: TextBox[]

  regions?: Region[]
  lines?: Line[]

  profile?: Profile
  profile_id?: number
  car?: Car
  car_id?: number
  group?: Group

  type: string
  name: string
  status: string
  state: string
  alert: boolean

  confirmed_by?: string
}

export interface Detected {
  camera: Model
  analytics: Model
  items: Item[] | null
  texts?: TextBox[]

  regions?: Region[]
  lines?: Line[]

  status: string
  state: string
  alert: boolean
}

export interface ReportsSending {
  id: number
  uuid: string
  name: string
  desc: string
  clock: string
  enabled: boolean

  report_duration: number
  report_offset: number

  filter: ReportsFilters
  report: string
  notify: number[]

  errors?: NAPError[]
}

//
//
//

export interface ReqFrameOpt {
  offset?: number
  video?: string

  // custom id
  object_id?: number

  status?: string
  state?: string

  items?: Item[]
  texts?: TextBox[]
}

export interface Frame extends Detected {
  img: string
  info: {
    Width: number
    Height: number
  }
  filename: string

  time: number
  timenano: number
  latency: number
  offset: number // ? maybe string
}

export interface Item {
  id: string
  name: string
  type: string
  prob: number
  bbox: number[]

  colors: Item
  text: Item

  items: Item[]
  attributes: { [key: string]: number }
  keypoints?: Record<string, number[]>
  lines?: number[][]
  emotions: Record<string, number>
  vector: number[]
  track?: ItemTrack

  profile: Profile
  car: Car

  state: string
  alert: boolean

  // frontend fields
  _key: string
  count: number

  data?: Record<string, any>
}

export interface ItemTrack {
  color: number[]
  points: number[][]
}

export interface Profile {
  id: number

  name: string // frontend only
  firstname: string
  lastname: string
  middlename: string
  externalid: string
  position: string
  department: string
  division: string
  dateOfbirth: string
  sectors: string[] | null
  // data: SettingsField[]
  data: Record<string, string> | null
  group: Group
  group_id: number
  photo: string

  [key: string]: any
}

export interface Car {
  id: number
  photo: string
  make: string
  model: string
  license_plate: string
  owner: string

  group: Group
  group_id: number

  data: any
}

export interface Group {
  id: number
  name: string
}

export interface TextBox {
  text: string
  size: number
  x: number
  y: number
  w: number
  h: number
}

export interface Chart {
  id: number
  name: string
  type: string
  desc: string
  uuid: string

  rate: number
  interval: number
  duration: number
  daily_limit: boolean
  cast: string

  legend: boolean
  color: boolean
  curve: boolean
  fill: boolean
  points: boolean
  stacked: boolean

  split_values: boolean
  sum_values: boolean

  types: string[]
  cameras_id: number[] | null
  sectors: string[] | null
  analytics_id: number[] | null
  script_id: number
  sql: string

  colors: string[]
  label_colors: LabelColor[]
}

export interface LabelColor {
  label: string
  color: string

  _edit: boolean // frontend only
}

export interface ChartData {
  labels: string[] | null
  dataset: ChartDataset[] | null

  links?: Record<string, Record<string, any>>
  options?: any
}

export interface ChartDataset {
  name: string
  values: number[]
  count: number
  color: string
}

export interface Health {
  CPU: {
    enabled: boolean
    load: number
    temp: number
    temp_max: number
  }
  RAM: {
    total: number
    used: number
  }
  disks: {
    name: string
    used: number
    total: number
    used_percent: number
  }[]
  GPU: {
    name: string
    load: number
    temp: number
    mem_total: number
    mem_used: number
    mem_percent: number
  }[]
  net: {
    name: string
    tx: number
    rx: number
  }[]
  version: string
}

export interface Detector {
  id: number
  name: string
  type: string
  desc: string
  mode: string
  enabled: boolean

  addrs: string[]
  services: DetectorService[] | null

  params: Record<string, any>
  status: DetectorStatus

  errors: NAPError[] | null

  cmd: string
}

export interface DetectorService {
  addr: string
  error: string | null
  req_busy: boolean
  req_time: string
  req_resp: DetectorServiceResponseInfo
  status: DetectorStatus
  activity: {
    fps: number
    data?: [string, number][]
  }
}

export interface DetectorServiceResponseInfo {
  code: number
  error: string
  latency: number
  time: string
  url: string
}

export interface DetectorStatus {
  addr: string
  name: string
  type: string
  path: string

  input: string[]
  output: {
    attributes: string[]
    colors: string[]
    types: string[]
  }

  variables: SettingsField[] | null

  GPU_usage: string
  version: string
}

export interface DetectorLog {
  time: string
  latency: number
  url: string
  response: string
  code: number
  error: string

  log?: DetectorLogLine[]
  stat?: Stat[]
}

export interface DetectorLogLine {
  timestamp: number
  text: string
  is_error: boolean
}

export interface DetectorParseFrameResponse {
  info: DetectorLog
  frame: Frame
}

export interface FindFaceVectorResponse {
  vector: number[]
  nodetect: boolean
}

export interface Device {
  id: number
  name: string
  type: string
  addr: string
  desc: string
  enabled: boolean

  // method: string
  // body: string
  // json: boolean // deprecated
  // content_type: string

  // auth_method: string
  // login: string
  // password: string

  handler_data: Record<string, any> | null

  // resp_path: string
  duration: number
  open_time: string

  info: DeviceInfo

  errors: NAPError[] | null
}

export interface DeviceHandlerForm {
  name: string
  form: SettingsField[]
}

export interface DeviceState {
  open: boolean
  info: DeviceInfo
  errors: NAPError[]
}

export interface DeviceInfo {
  time: string
  request: string
  response: string
  latency: number
}

export interface Founded {
  type: string
  name: string
  addr: string
}

export interface Script {
  id: number
  name: string
  desc: string
  source: string
  values: SettingsField[]

  error?: NAPError // deprecated
  errors?: NAPError[] | null
  duration: number
  call_at: string
  log?: ScriptLog[]

  status: string
}

export interface ScriptLog {
  time: string
  timenano: number
  linenumber: number
  value: string
}

export interface ArchiveCamera {
  id: number
  enabled: boolean
  name: string
  time_at: string | moment.Moment | any
  time_to: string | moment.Moment | any

  files: ArchiveFile[] | null
  calendar: Record<string, number>
}

export interface ArchiveFile {
  name: string
  path: string
  time: string | moment.Moment | any
  etime: moment.Moment
  type: string
  seconds: number
  duration: number
}

export interface DrawAndCutOpt {
  draw: boolean
  cut: [number, number]
}

export interface ArchiveDrawAndCutProcess extends ArchiveFile, DrawAndCutOpt {
  id: string

  result: string
  progress: number
  done: boolean
  stopped: boolean
  errors?: NAPError[] | null
}

export interface Success {
  success: boolean
}

export interface NAPTime {
  time: string
  timestamp: number
  zone: string
  offset: number
}

export function eventToDetected(e: Event): Detected {
  return {
    camera: {
      id: e.camera_id,
      name: e.camera,
    } as Model,
    analytics: {
      id: e.analytics_id,
      name: e.analytics,
    } as Model,
    items: e.items,
    texts: e.texts,
    regions: e.regions,
    lines: e.lines,
    status: e.status,
    state: e.state,
    alert: e.alert,
  }
}

export interface Exception {
  camera_id: number
  name: string
  type: string
  bbox: number[]
  prob: number
  time: string

  frame: string

  // front only
  camera: string
}

export interface FloorMap {
  id: number
  name: string
  desc: string

  type: string
  map_image: string

  map_props: {
    key: string
    zoom: number
    position: Point
  }

  objects: FloorMapObject[]
}

export interface FloorMapObject {
  id: number
  name: string
  type: string
  position: Point
  rotation: number
  area?: number[]

  fov_map?: Point[]
  fov_cam?: Point[]

  // frontend only
  edit?: boolean
}

export interface SearchData {
  cameras: ShortModel[]
  charts: ShortModel[]
  analytics: ShortModel[]
  detectors: ShortModel[]
  scripts: ShortModel[]
  devices: ShortModel[]
  notifications: ShortModel[]
  profiles: Profile[]
  cars: Car[]
  groups: Group[]
}

export interface Model {
  id: number
  name: string
  desc: string
  enabled: boolean
  sector: string
}

export interface ShortModel {
  id: number
  name: string
  desc: string
  enabled: boolean
}

export interface AuthSettings {
  faceauth: boolean
  faceauth_withcreds: boolean
  faceauth_analytics: number
}

export interface ExtScript {
  id: string
  name: string
  desc: string
  icon: string

  variables: SettingsField[]
  values: Record<string, any>
}

export interface License {
  serial_number: string

  cameras: number
  analytics: number
  detectors: number

  time_at: number
  time_to: number

  permissions: Record<string, number>

  usage: {
    cameras: number
    detectors: Record<string, number>
  }
}

export interface Page {
  name: string
  title: string
  icon: string
  menu: string
  iframe?: string

  data?: string
}

export interface Repository {
  name: string
  addr: string

  packages: Package[] | null
  types: string[]
  error?: NAPError

  count?: number
}

export interface Package {
  uid: string
  path: string
  name: string
  type: string
  desc?: string
  version?: string

  source?: string
  md5sum?: string
  auth: string
  auth_header: string
  lock?: boolean

  exec?: string[]
  exec_uninstall?: string[]

  need_restart?: boolean

  scripts?: Script[]
  detectors?: Detector[]
  devices?: Device[]
  notify?: Notification[]
  analytics?: Scheme[]
  charts?: Chart[]

  depends?: string[]

  progress?: PackageInstallProgress
}

export interface PackageInstallProgress {
  state: string

  percent: number
  downloaded: number
  total: number

  version: string
  time: string

  log?: string[]
  error?: NAPError
}

export interface InstalledPackage {
  rep: string
  pkg: string
  version: string
  time: string // unix timestamp
}

export const PACKAGE_ANALYTICS = 'analytics'
export const PACKAGE_SCRIPTS = 'scripts'
export const PACKAGE_DETECTORS = 'detectors'

export interface JournalMsg {
  id: string
  created_at: string

  user: string
  ip: string

  section: string
  record_id: number

  name: string
  type: string
  desc: string
  file: string
  diff: Record<string, any>[]

  method: string
  url: string
  data: string

  error?: string
}

export interface JournalSettings {
  lifetime: number
}

export interface ThemesSettings {
  styles: string
  script: string
}

export interface ONVIFprofile {
  name: string
  token: string
  stream_uri: string
  snapshot_uri: string

  video: ONVIFvideoConfig

  options: {
    H264: ONVIFprofileEncodingOption
    JPEG: ONVIFprofileEncodingOption
    MPEG4: ONVIFprofileEncodingOption
    QualityRange: ONVIFrange
  }
}

export interface ONVIFvideoConfig {
  Encoding: string
  Quality: number
  Resolution: ONVIFsize
  RateControl: {
    FrameRateLimit: number
    EncodingInterval: number
    BitrateLimit: number
  }
  H264: {
    GovLength: number
    H264Profile: string
  }
}

export interface ONVIFprofileEncodingOption {
  ResolutionsAvailable: ONVIFsize[]
  FrameRateRange: ONVIFrange
  GovLengthRange: ONVIFrange
  H264ProfilesSupported: string[]
}

export interface ONVIFsize {
  Width: number
  Height: number
}

export interface ONVIFrange {
  Min: number
  Max: number
}

export interface Plugin {
  file: string
  name: string
  desc: string
  version?: string
  error?: string

  settings?: SettingsField[]
}

export interface Shell {
  name: string
  lines: ShellLine[]
}

export interface ShellLine {
  time: string
  type: string
  text: string
}

//
// activity
//

export interface Stat {
  name: string
  text: string
  unit: string
  data: ActivityData | null
}

export type ActivityData = [string, number][]

export interface StatusModel extends ShortModel {
  status: string
  errors?: string
}
