import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  Footer,
  Grid,
  Row,
  Col,
  Form,
  ButtonToolbar,
  Button,
  InputPicker,
  Panel,
  Divider,
} from 'rsuite'

import NAP, { Car, Group, SettingsField, User } from 'nap'
import { IMAGE } from 'components/players'
import ROUTES from 'routes'
import Content, {
  ContentState,
  CenterRow,
  Header,
  HeaderLeft,
  SettingsInput,
  setTitle,
  alert,
  success,
} from 'content'

interface RouteParams {
  id: string
}
interface Props extends WithTranslation, RouteComponentProps<RouteParams> {}
interface State extends ContentState {
  car: Car
  groups: Group[]
  user: User
}

class CarForm extends React.Component<Props, State> {
  state = {
    car: {
      license_plate: '',
      make: '',
      model: '',
      owner: '',
      group: {} as Group,
    } as Car,
    groups: [] as Group[],
  } as State

  constructor(props: Props) {
    super(props)

    this.state.car.id = parseInt(props.match.params.id || 'new') | 0

    if (this.state.car.id === 0) {
      this.state.title = props.t('profiles.new')
      this.state.loaded = true
    }
  }

  componentDidMount() {
    const { id } = this.state.car

    setTitle('profiles.cars.title')

    if (id > 0) {
      NAP.car(id).then(this.setCar).catch(this.setError)
    }

    NAP.groups().then(this.setGroups).catch(this.setError)
    NAP.user()
      .then((user) => this.setState({ user }))
      .catch(console.error)
  }

  setCar = (p: Car) => {
    setTitle('profiles.cars.title', p.license_plate)

    this.setState({
      title: p.license_plate || `${p.make} ${p.model}`,
      car: p,
      loaded: true,
    })
  }

  setGroups = (groups: Group[]) => {
    this.setState({ groups })
  }

  setError = (err: Error) => {
    this.setState({
      error: err.message,
      loaded: true,
    })
  }

  //
  // handlers
  //

  handleForm = (formData: Record<string, any>) => {
    this.setState({ car: Object.assign(this.state.car, formData) })
  }

  handleGroupChanges = (id: number) => {
    const { car, groups } = this.state
    car.group = groups.find((g: Group) => g.id === id) || ({} as Group)
    car.group_id = id

    this.setState({ car })
  }

  handleData = (value: any, s: SettingsField) => {
    s.value = value
    this.setState({})
  }

  saveCar = () => {
    const { t } = this.props
    const { car } = this.state

    if (car.id) {
      NAP.changeCar(car.id, car)
        .then((car) => {
          success(t('success'))
          this.setCar(car)

          const urlpath = `${ROUTES.settings.analytics}/${car.id}`
          window.history.pushState(car, car.license_plate, urlpath)
        })
        .catch(alert)
    } else {
      NAP.createCar(car).then(this.setCar).catch(alert)
    }
  }

  removePhoto = () => {
    const { car } = this.state

    NAP.deleteCarPhoto(car.id).catch(alert)

    car.photo = ''
    this.setState({})
  }

  handleUpload = (files: any[]) => {
    if (!files.length) return

    this.uploadPhoto(files[0].blobFile)
  }

  uploadPhoto = (file: Blob): Promise<any> => {
    const { car } = this.state

    return NAP.uploadCarPhoto(car.id, file)
      .then((resp: any) => {
        car.photo = resp.filename
        this.setState({ car })
      })
      .catch(alert)
  }

  isSaveAllow = (): boolean => {
    const { user, car } = this.state

    let allow = car.license_plate !== undefined && car.license_plate.length > 0

    // if user has groups restrictions then group field is required
    if (user && user.groups_id?.length) {
      if (!car.group_id) allow = false
    }

    return allow
  }

  //
  // render
  //

  render() {
    const { t } = this.props
    const { loaded, error, car, groups, user } = this.state

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        <CenterRow>
          <Panel>
            <Grid fluid>
              <Form formValue={car} onChange={this.handleForm} fluid>
                {car.photo && (
                  <Row gutter={15}>
                    <Col md={24}>
                      <IMAGE
                        src={`/api/cars/${car.id}/photo`}
                        className='profile-photo'
                        width={200}
                        height={200}
                        background
                      />
                    </Col>
                    <Divider />
                  </Row>
                )}

                <Row gutter={15}>
                  <Col md={24}>
                    <Form.Group>
                      <Form.ControlLabel>
                        {t('profiles.cars.license_plate')}*
                      </Form.ControlLabel>
                      <Form.Control name='license_plate' required />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group>
                      <Form.ControlLabel>
                        {t('profiles.cars.make')}
                      </Form.ControlLabel>
                      <Form.Control name='make' />
                    </Form.Group>
                  </Col>

                  <Col md={12}>
                    <Form.Group>
                      <Form.ControlLabel>
                        {t('profiles.cars.model')}
                      </Form.ControlLabel>
                      <Form.Control name='model' />
                    </Form.Group>
                  </Col>

                  <Col md={24}>
                    <Form.Group>
                      <Form.ControlLabel>
                        {t('profiles.cars.owner')}
                      </Form.ControlLabel>
                      <Form.Control name='owner' />
                    </Form.Group>
                  </Col>
                </Row>

                <Row gutter={15}>
                  <Col md={24}>
                    <Form.Group>
                      <Form.ControlLabel>
                        {t('profiles.group')}
                        {user && user.groups_id?.length && '*'}
                      </Form.ControlLabel>
                      <InputPicker
                        data={groups}
                        onChange={(v) => this.handleGroupChanges(v)}
                        value={car.group_id}
                        valueKey='id'
                        labelKey='name'
                        block
                      />
                    </Form.Group>
                  </Col>
                </Row>

                {this.renderData()}
              </Form>
              {this.renderFooter()}
            </Grid>
          </Panel>
        </CenterRow>
      </Content>
    )
  }

  renderHeader() {
    return (
      <Header left={<HeaderLeft back={ROUTES.profiles.cars} />}>
        <h4 className='content-title'>{this.state.title}</h4>
      </Header>
    )
  }

  renderData() {
    const { data } = this.state.car
    if (!data) return

    if (Array.isArray(data) && data[0].value) {
      return this.renderDataFiels(data)
    }
  }

  renderDataFiels(data: SettingsField[]) {
    const { t } = this.props
    return (
      <Panel>
        <h5>{t('profiles.custom_data')}</h5>
        {data.map((s: SettingsField) => {
          return (
            <SettingsInput
              key={s.name}
              className='settingsinput'
              onChange={(v) => this.handleData(v, s)}
              i18nPrefix='profiles.cars'
              {...s}
            />
          )
        })}
      </Panel>
    )
  }

  renderFooter() {
    const { t } = this.props

    return (
      <Footer className='footer-buttons'>
        <ButtonToolbar></ButtonToolbar>
        <ButtonToolbar justifyContent='flex-end'>
          <Button
            disabled={!this.isSaveAllow()}
            onClick={() => this.saveCar()}
            appearance='primary'>
            {t('profiles.save')}
          </Button>
        </ButtonToolbar>
      </Footer>
    )
  }
}

export default withTranslation()(withRouter(CarForm))
