import ReactEcharts from 'echarts-for-react'
import moment from 'moment-timezone'
import queryString from 'query-string'
import React from 'react'
import { withTranslation, WithTranslation } from 'react-i18next'
import { FaMinus, FaPen, FaPlus, FaSave } from 'react-icons/fa'
import PerfectScrollbar from 'react-perfect-scrollbar'
import { Redirect } from 'react-router-dom'
import { IconButton, Message, Stack } from 'rsuite'

import { URLDateFormat, URLTimeFormat } from 'components/events/eventsarchive'
import { Spinner } from 'content'
import { itemsTranslate } from 'i18n'
import ROUTES from 'routes'
import NAP, { Chart, ChartData, ChartDataset } from 'nap'

interface Props extends WithTranslation, Chart {
  height: any
  ref?: React.Ref<any>
  loadDataDisable?: boolean
  data?: ChartData

  btns?: {
    edit?: boolean
    save?: boolean
  }
}

interface State {
  data?: ChartData
  time: number
  load: boolean
  error?: string
  redirect?: string

  font: {
    size: number
  }
}

const FONT_LS_KEY = 'font'

export class ChartWidget extends React.Component<Props, State> {
  state = {
    font: {
      size: 13,
    },
  } as State

  updateInterval = 0
  chart = React.createRef<ReactEcharts>()

  componentDidMount() {
    this.translateData(this.props.data)
    if (this.props.loadDataDisable) return

    this.loadData()

    let interval = 10000
    if (this.props.rate && !isNaN(this.props.rate)) {
      interval = this.props.rate * 1000
    }

    this.updateInterval = window.setInterval(this.loadData, interval)

    try {
      const s = localStorage.getItem(FONT_LS_KEY)
      if (s) {
        const font = JSON.parse(s)
        if (font) this.setState({ font })
      }
    } catch (e) {
      // do nothing
    }
  }

  componentDidUpdate(props: Props) {
    this.translateData(this.props.data)

    if (this.props.type !== props.type || this.props.id !== props.id) {
      this.loadData()
    }
  }

  componentWillUnmount() {
    window.clearInterval(this.updateInterval)
  }

  translateData = (data?: ChartData) => {
    if (!data) return

    data.labels = data.labels?.map(itemsTranslate) || []

    data.dataset?.forEach((d) => {
      d.name = itemsTranslate(d.name)
      d.values = d.values || []
    })
  }

  loadData = () => {
    if (this.props.loadDataDisable) return

    const { height, t, tReady, i18n, ...chart } = this.props
    if (!chart.id) return

    const data = Object.assign({}, chart as Record<string, any>)
    delete data['id']
    delete data['uuid']
    delete data['btns']
    delete data['label_colors'] // no need to send it to the backend

    this.setState({ load: true })
    NAP.chartData(chart.id, data).then(this.setData).catch(this.setError)
  }

  setData = (data: ChartData) => {
    this.translateData(data)
    this.setState({ data, load: false, time: new Date().valueOf() })
  }

  setError = (err: Error) => {
    this.setState({ error: err.message })
  }

  fixColor = (c: string): string => {
    if (c.length === 5) return c.substring(0, 4)
    else if (c.length === 9) return c.substring(0, 7)
    return c
  }

  //
  //
  //

  getDictValue = (key: string): any => {
    return this.state.data?.links?.[key] || { types: key }
  }

  handleChartClick = (e: any) => {
    const chart = this.props

    let time_at = moment().startOf('day')
    let time_to = moment().endOf('day')

    if (isRadialChart(chart.type)) {
      time_at = moment().add(-chart.duration, 's')
      if (chart.daily_limit) {
        time_at = time_at.add(1, 'day').startOf('day')
      }
    } else {
      time_at = moment(e.name)
      time_to = moment(e.name).add(chart.interval, 's')
      if (!time_at.isSame(time_to, 'day')) {
        time_to = moment(time_at).endOf('day')
      }
    }

    const q = {
      date_at: time_at.format('YYYY-MM-DD'),
      date_to: time_to.format('YYYY-MM-DD'),
      date_time_at: time_at.format('HH:mm'),
      date_time_to: time_to.format('HH:mm'),
    } as Record<string, any>

    if (q.date_time_at == '00:00' && q.date_time_to == '23:59') {
      delete q.date_time_at
      delete q.date_time_to
    }

    if (isRadialChart(chart.type)) {
      Object.assign(q, this.getDictValue(e.name))
    } else {
      Object.assign(q, this.getDictValue(e.seriesName))
    }

    this.fillQuery(q)

    const redirect = `${ROUTES.eventsarchive}?${queryString.stringify(q, {
      arrayFormat: 'comma',
    })}`
    this.setState({ redirect })
  }

  fillQuery = (q: Record<string, any>) => {
    const { cameras_id, analytics_id, sectors } = this.props
    if (cameras_id && cameras_id.length) q.cameras = cameras_id
    if (analytics_id && analytics_id.length) q.analytics = analytics_id
    if (sectors && sectors.length) q.sectors = sectors
    return q
  }

  handleClickDate = (date: string) => {
    const q = this.parseLabelDate(date)
    const redirect = `${ROUTES.eventsarchive}?${queryString.stringify(q)}`
    this.setState({ redirect })
  }

  parseLabelDate = (date: string) => {
    const chart = this.props

    const date_at = moment(date).add(-chart.interval, 'seconds')
    const date_to = moment(date)

    const q = {
      date_at: date_at.format(URLDateFormat),
      date_to: date_to.format(URLDateFormat),
      date_time_at: date_at.format(URLTimeFormat),
      date_time_to: date_to.format(URLTimeFormat),
    } as Record<string, string>

    // fix full day intervals
    if (q.date_time_at === '00:00' && q.date_time_to === '00:00') {
      delete q.date_time_at
      delete q.date_time_to
      q.date_to = date_to.add(-chart.interval, 'seconds').format(URLDateFormat)
    }

    return this.fillQuery(q)
  }

  handleClickType = (search: string) => {
    const q = this.getDictValue(search)
    this.fillQuery(q)

    const redirect = `${ROUTES.eventsarchive}?${queryString.stringify(q)}`
    this.setState({ redirect })
  }

  handleClickCell = (date, search: string) => {
    const q = this.parseLabelDate(date)
    Object.assign(q, this.getDictValue(search))

    const redirect = `${ROUTES.eventsarchive}?${queryString.stringify(q)}`
    this.setState({ redirect })
  }

  handleExportChart = () => {
    const { name } = this.props
    const eci = this.chart.current?.getEchartsInstance() as any
    if (!eci) return

    const img = eci.getDataURL({
      type: 'png',
      pixelRatio: 2,
      backgroundColor: '#2224',
    })

    const link = document.createElement('a')
    link.href = img
    link.setAttribute('download', name + '.png')
    link.click()
  }

  handleChangeFontSize = (size: number) => {
    const { font } = this.state
    font.size = size
    this.setState({ font })

    localStorage.setItem(FONT_LS_KEY, JSON.stringify(font))
  }

  handleEditChart = () => {
    const { id } = this.props
    this.setState({ redirect: `${ROUTES.charts}/${id}` })
  }

  //
  //
  //

  getLeftPadding = (): number => {
    const { data } = this.state
    if (!data) return 35

    let max = 0
    data.dataset?.forEach((d: ChartDataset) => {
      d.values?.forEach((n: number) => (max = Math.max(n, max)))
    })

    return Math.max(max.toString().length * 20, 35)
  }

  getSeries = () => {
    const chart = this.props
    const data = this.state.data || this.props.data

    if (!chart || !chart.type) return [{ type: 'bar' }]
    if (!data || !data.dataset || !data.labels) return [{ type: chart.type }]

    if (isRadialChart(this.props.type)) {
      return data.dataset?.map((d: ChartDataset, i: number) => {
        return {
          name: d.name,
          data: d.values?.map((v: number, i: number) => ({
            name: data.labels ? data.labels[i] : v,
            value: v,
          })),
          type: chart.type,
          stack: false,
          areaStyle: {},
          // smooth: chart.curve,
          itemStyle: d.color ? { color: this.fixColor(d.color) } : null,
          symbolSize: 5,
          showSymbol: chart.points,
          showAllSymbol: true,
        }
      })
    }

    return data.dataset?.map((d: ChartDataset, i: number) => ({
      name: d.name,
      data: d.values,
      type: chart.type,
      label: {
        show: !chart.stacked,
        position: 'top',
        lineOverflow: 'truncate',
        formatter: (p: any) => this.formatter(p, data),
      },
      stack: chart.stacked,
      areaStyle: chart.fill ? {} : null,
      smooth: chart.curve,
      itemStyle: d.color ? { color: this.fixColor(d.color) } : null,
      symbolSize: 5,
      showSymbol: chart.points,
      showAllSymbol: true,
    }))
  }

  formatter = (p: any, data: ChartData) => {
    let sum = 0
    data.dataset?.forEach((v) => {
      sum += v.values[p.dataIndex]
    })

    if (p.data > sum * 0.07) return p.data
    return ''
  }

  getLabels = () => {
    const data = this.state.data || this.props.data
    if (!data || !data.labels) return []

    return data.labels?.map(itemsTranslate)
  }

  getDataOptions = () => {
    const data = this.state.data || this.props.data
    return data?.options || {}
  }

  legendFormatter = (name: string) => {
    const data = this.props.data || this.state.data
    if (!data || !data.dataset || !data.dataset.length) return name

    if (isRadialChart(this.props.type)) {
      const index = data.labels?.findIndex((v) => v === name)
      if (index === undefined) return name
      return `${name} (${data.dataset[0].values[index]})`
    }

    const d = data.dataset.find((d) => d.name === name)
    if (!d) return name
    return `${name} (${d.count})`
  }

  getChartOptions = (): any => {
    const { type, legend, colors } = this.props
    const { font } = this.state

    const series = this.getSeries()
    const radial = isRadialChart(this.props.type)
    const palette = colors || colorPalette // : alphaPalette

    if (colors) colors.forEach((c, i) => (colors[i] = this.fixColor(c)))

    const labels = this.getLabels()

    const opt = {
      backgroundColor: '#0000',
      textStyle: { fontSize: font.size },
      xAxis: {
        type: 'category',
        data: labels,
        axisLabel: {
          fontSize: font.size * 0.9,
          align: 'center',
          formatter: this.xAxis,
        },
        show: !radial,
      },
      yAxis: {
        type: 'value',
        axisLabel: {
          fontSize: font.size * 0.9,
        },
        show: !radial,
      },
      grid: {
        left: this.getLeftPadding(),
        right: 20,
        top: 24,
        bottom: legend ? 55 : 40,
      },
      series: series,
      height: isRadialChart(type) ? '95%' : undefined,
      legend: {
        bottom: 0,
        show: legend,
        icon: 'circle',
        type: 'scroll',
        formatter: this.legendFormatter,
      },
      tooltip: {
        backgroundColor: '#212121cc',
        borderWidth: 0,
        trigger: radial ? 'item' : 'axis',
        position: 'right',
        show: true,
      },

      dataZoom: [
        {
          type: 'inside',
          start: 0,
          end: 100,
          throttle: 0,
          filterMode: 'empty',
        },
      ],
      color: palette,
    }

    return Object.assign(opt, this.getDataOptions())
  }

  //
  // render
  //

  render() {
    const { t, height, btns, ...chart } = this.props
    const { load, redirect, error, font } = this.state

    if (redirect) return <Redirect to={redirect} push />

    let h = height - 50
    if (isNaN(h) || h < 0) h = 200

    if (load)
      return (
        <div style={{ height: h }} className='chart_nodata'>
          <Spinner small />
          {error && <Message type='error'>{error}</Message>}
        </div>
      )

    const data = this.state.data || this.props.data
    if (!data) return <div className='chart_nodata'>{t('charts.nodata')}</div>

    if (chart.type === 'table') return this.renderTable(chart, data)

    return (
      <div className='chart'>
        <ReactEcharts
          style={{ height: h }}
          theme='dark'
          ref={this.chart}
          onEvents={{
            click: this.handleChartClick,
          }}
          option={this.getChartOptions()}
        />

        {btns && (
          <Stack className='chart-btns' spacing={2}>
            {btns?.edit && (
              <IconButton
                onClick={this.handleEditChart}
                icon={<FaPen />}
                size='sm'
              />
            )}
            {btns?.save && (
              <IconButton
                onClick={this.handleExportChart}
                icon={<FaSave />}
                size='sm'
              />
            )}
          </Stack>
        )}

        <div className='chart-fontsize'>
          <IconButton
            appearance='link'
            className='chart-fontsize-icon'
            onClick={() => this.handleChangeFontSize(font.size - 1)}
            icon={<FaMinus />}
            size='xs'
          />
          A
          <IconButton
            appearance='link'
            className='chart-fontsize-icon'
            onClick={() => this.handleChangeFontSize(font.size + 1)}
            icon={<FaPlus />}
            size='xs'
          />
        </div>
      </div>
    )
  }

  xAxis = (v: string) => {
    const time = moment(v)
    if (!time.isValid()) return v

    if (v.includes(':')) return time.format('HH:mm D MMM').replace(' ', '\n')
    return time.format('D MMM')
  }

  renderTable(chart: Chart, data: ChartData) {
    return (
      <PerfectScrollbar>
        <div className='chart-table-container'>
          <table className='chart-table'>
            <thead>
              <tr className='chart-table-tr'>
                <th></th>
                {data.labels?.map((s, i) => (
                  <th key={i} className='chart-table_th'>
                    <span
                      className='chart-table_link'
                      onClick={() => this.handleClickDate(s)}>
                      {s}
                    </span>
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {data.dataset?.map((d, i) => {
                return (
                  <tr className='chart-table-tr' key={`row${d.name}${i}`}>
                    <td className='chart-table-tdlabel'>
                      <span
                        className='chart-table_link'
                        onClick={() => this.handleClickType(d.name)}>
                        {d.name}
                      </span>
                    </td>
                    {d.values.map((v, i) => (
                      <td key={`val${i}`} className='chart-table-td'>
                        <span
                          className='chart-table_link'
                          onClick={() =>
                            this.handleClickCell(data.labels?.[i], d.name)
                          }>
                          {v}
                        </span>
                      </td>
                    ))}
                  </tr>
                )
              })}
            </tbody>
          </table>
        </div>
      </PerfectScrollbar>
    )
  }
}

export default withTranslation(undefined, { withRef: true })(ChartWidget)

export const colorPalette = [
  '#c23531',
  '#2f4554',
  '#61a0a8',
  '#d48265',
  '#91c7ae',
  '#749f83',
  '#ca8622',
  '#bda29a',
  '#6e7074',
  '#546570',
  '#c4ccd3',
]

// const alphaPalette = [
//   '#fffe',
//   '#fffc',
//   '#fffa',
//   '#fff8',
//   '#fff6',
//   '#fff4',
//   '#fff2',
// ]

export function isRadialChart(type: string): boolean {
  return ['pie', 'doughut', 'radar'].includes(type)
}
