import { Icon } from '@rsuite/icons'
import React from 'react'
import { FaVideo } from 'react-icons/fa'

import { ILineRot } from 'components/cameras/cameracanvas'
import { IMAGE } from 'components/players'
import { alert } from 'content'
import NAP, { Camera, Detected, FloorMapObject, Item, Point, WS } from 'nap'

interface Props {
  fmapid: number
  object: FloorMapObject
  selected?: boolean
  container?: string
  showArea?: boolean
  showItems?: boolean

  onClick?: (e: React.MouseEvent, o: FloorMapObject) => void
  onDoubleClick?: (o: FloorMapObject) => void
  onDrawFOVItems?: (cam_id: number, items: Item[], fov?: Point[]) => void

  countTarget?: string

  items?: Item[]
}

interface State {
  cam: Camera
  analytics: Record<number, Detected>
}

export default class ObjectCamera extends React.Component<Props, State> {
  state = {
    cam: {} as Camera,
    analytics: {} as Record<number, Detected>,
  }

  interval = 0
  ws?: WS = undefined

  componentDidMount() {
    this.interval = window.setInterval(this.loadCamera, 5000)
    this.loadCamera()
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
    if (this.ws) this.ws.close()
  }

  loadCamera = () => {
    const { id } = this.props.object
    NAP.camera(id).then(this.setCamera).catch(alert)
  }

  setCamera = (cam: Camera) => {
    const { items } = this.props
    this.setState({ cam })

    if (items === undefined && cam.enabled && !this.ws) {
      this.ws = NAP.ws()
      this.ws
        .camerasSubscribe(this.setAnalyticsResult)
        .then((ws) => ws.subscribe({ camera_id: cam.id }))
        .catch(alert)
    }
  }

  setAnalyticsResult = (d: Detected) => {
    const { analytics } = this.state
    const { countTarget } = this.props

    analytics[d.analytics.id] = d

    let count = 0
    let alert = false

    Object.values(analytics).forEach((d) => {
      count += d.items?.length || 0
      if (d.alert) alert = true
    })

    if (countTarget) {
      const countElem = document.getElementById(countTarget)
      if (countElem) {
        countElem.innerHTML = count.toString()
        countElem.dataset.alert = alert.toString()
        countElem.dataset.show = 'true'
      }
    }

    this.setState({ analytics })
    if (this.hasFOV()) this.passItemsToCanvas()
  }

  passItemsToCanvas = () => {
    const { analytics } = this.state
    const { fmapid, onDrawFOVItems, object } = this.props
    if (!onDrawFOVItems) return

    const items = [] as Item[]

    Object.values(analytics).forEach((d) => {
      if (d.items) items.push(...d.items)
    })

    NAP.floormapPositions(fmapid, object, items)
      .then((resp) => {
        onDrawFOVItems(object.id, resp.items, resp.points)
      })
      .catch(alert)
  }

  //
  // handlers
  //

  handleOnClick = (e: React.MouseEvent) => {
    const { object } = this.props
    const { onClick } = this.props

    if (onClick) onClick(e, object)
  }

  handleOnDoubleClick = () => {
    const { object } = this.props
    const { onDoubleClick } = this.props

    if (onDoubleClick) onDoubleClick(object)
  }

  //
  // data
  //

  getArea = () => {
    const { area } = this.props.object
    if (!area || !area[0] || !area[1]) return [0.2, 0.3]

    return area
  }

  dist = (a, b): number => {
    return Math.sqrt(
      Math.pow(Math.abs(a[0] - b[0]), 2) + Math.pow(Math.abs(a[1] - b[1]), 2)
    )
  }

  getSize = () => {
    const { container } = this.props
    if (!container) return { width: 1000, height: 600 }

    const e = document.querySelector(container) as any
    return { width: e?.width || 1000, height: e?.height || 600 }
  }

  getAreaStyles = () => {
    const area = this.getArea()
    const size = this.getSize()

    return {
      top: 19 - (size.height * area[1]) / 2,
      width: size.width * area[0],
      height: size.height * area[1],
    }
  }

  hasFOV = () => {
    const { object } = this.props
    return object.fov_map && object.fov_map?.length >= 4
  }

  //
  // render
  //

  render() {
    const { selected } = this.props
    const { cam } = this.state

    return (
      <div className={`floormaps-object ${selected && 'selected'}`}>
        <div className='floormaps-object_in'>
          {this.renderViewArea()}
          <Icon
            as={FaVideo}
            onClick={this.handleOnClick}
            onDoubleClick={this.handleOnDoubleClick}
            data-disabled={!cam.enabled}
            data-status={cam.status}
          />
        </div>
      </div>
    )
  }

  renderViewArea = () => {
    const { showArea, items } = this.props

    if (this.hasFOV()) return ''

    return (
      <div className='floormaps-area' style={this.getAreaStyles()}>
        {showArea && <div className='floormaps-cameraview' />}
        {items ? this.renderHistory() : this.renderRealtime()}
      </div>
    )
  }

  renderHistory = () => {
    const { items, showItems } = this.props
    if (!items || !showItems) return null

    return (
      <div key='history' className='floormaps-cameraview-items'>
        {items.map(this.renderItem)}
        {items.map(this.renderTrack)}
      </div>
    )
  }

  renderRealtime = () => {
    const { showItems } = this.props
    const { analytics } = this.state
    if (!analytics || !showItems) return null

    return Object.values(analytics).map((d) => {
      return (
        <div className='floormaps-cameraview-items' key={d.analytics.id}>
          {d.items && d.items.map(this.renderItem)}
          {d.items && d.items.map(this.renderTrack)}
        </div>
      )
    })
  }

  renderItem = (item: Item, i: number) => {
    if (!item.bbox) return null

    const [y, x, h, w] = item.bbox

    const k = 1.25 - x
    const top = 0.49 - (1 - k) * 0.05 + y * k - k / 2

    const style = {
      left: (1 - x) * 80 + 10 + '%',
      top: top * 80 + 10 + '%',
      width: w * 100 + '%',
      height: h * 100 + '%',
      opacity: Math.max(item.prob * 1.2, 0.5),
    } as Record<string, string | number>

    if (item.profile) {
      style.width = ((w + h) / 2) * 75 + '%'
      style.height = 'auto'

      return (
        <div
          key={item.id}
          className='floormaps-item floormaps-profile'
          data-state={item.state}
          style={style}>
          <IMAGE
            src={`/api/profiles/${item.profile.id}/photo`}
            className='floormaps-item_img'
          />
          {item.profile.name}
        </div>
      )
    }

    return (
      <div
        key={item.id}
        className='floormaps-item'
        data-state={item.state}
        style={style}>
        {item.type}
      </div>
    )
  }

  renderTrack = (item: Item, i: number): any => {
    if (!item.track || !item.track.points) return null

    const color = item.track.color
      ? `rgba(${item.track.color.join(',')}, 1)`
      : 'red'

    const points = item.track.points

    return (
      <div className='camera-canvas' key={i}>
        {points.map((p, i) => {
          const [y, x] = p

          const k = 1 - x + 0.1
          const top = 0.5 + y * k - k / 2
          const styles = {
            left: x * 100 + '%',
            top: top * 100 + '%',
            backgroundColor: color,
            opacity: 1 - i / points.length,
          } as Record<string, any>

          const b = points[i + 1]
          if (b) {
            const rot = this.getLineRot(p, b)
            styles.transform = `rotate(${rot.angle}rad)`
            styles.width = rot.length + 'px'
          }

          return <div key={i} className='item-track' style={styles} />
        })}
      </div>
    )
  }

  getLineRot = (a: number[], b: number[]): ILineRot => {
    // const { width, height } = this.props
    const width = 100
    const height = 100
    const [y1, x1] = a
    const [y2, x2] = b

    const angle = Math.atan2((y2 - y1) * height, (x2 - x1) * width)
    const length = Math.hypot((x2 - x1) * width, (y2 - y1) * height)

    return { angle, length } as ILineRot
  }
}
