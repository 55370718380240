import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Panel, Whisper, Button, Tooltip } from 'rsuite'

import NAP, { Device } from 'nap'
import { alert } from 'content'

//
// Widget DEVICE
//

interface Props extends Device, WithTranslation {}
interface State {
  openState: boolean
}

class DevicePanel extends React.Component<Props, State> {
  state = {
    device: {} as Device,
    openState: false,
  } as State

  interval: number = 0

  componentDidMount() {
    this.interval = window.setInterval(this.loadState, 1000)
    this.loadState()
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  loadState = () => {
    NAP.deviceState(this.props.id)
      .then((state: any) => this.setState({ openState: state.open }))
      .catch(alert)
  }

  openDevice = () => {
    NAP.openDevice(this.props.id)
      .then((v: any) => this.setState({ openState: true }))
      .catch(alert)
  }

  //
  // render
  //

  render() {
    const { t, ...device } = this.props
    const { openState } = this.state

    return (
      <Panel key={this.props.id} data-state={openState ? 'pass' : ''}>
        <h5 className='widget-title'>{device.name}</h5>
        <Whisper
          placement='bottom'
          trigger='hover'
          speaker={<Tooltip>{t('devices.open')}</Tooltip>}>
          <Button
            onClick={this.openDevice}
            appearance='subtle'
            disabled={openState}
            block>
            {openState ? t('devices.opened') : t('devices.closed')}
          </Button>
        </Whisper>
      </Panel>
    )
  }
}

export default withTranslation()(DevicePanel)
