import Hls from 'hls.js'
import React from 'react'

import { Spinner } from 'content'
import NAP from 'nap'

const options = {
  manifestLoadingMaxRetry: 1,
  manifestLoadingMaxRetryTimeout: 1000,
  progressive: true,
}

interface Props {
  className?: string
  src: string
  syncTime: number
}

interface State {
  inrestart: boolean
  loaded: boolean
}

export default class HLS extends React.Component<Props, State> {
  state = {
    loaded: false,
  } as State

  video = React.createRef<HTMLVideoElement>()
  player?: Hls = undefined
  segment: any = undefined

  progress = 0
  intervals: number[] = []

  componentDidMount() {
    const w = window as any
    w.HELP_IMPROVE_VIDEOJS = false

    this.loadPlayer()

    this.intervals.push(window.setInterval(this.failMonitor, 5000))
  }

  componentWillUnmount() {
    this.intervals.forEach(window.clearInterval)
    this.player?.destroy()
  }

  failMonitor = () => {
    if (!this.video.current) return

    if (!this.player) {
      this.loadPlayer()
      return
    }

    const progress = this.player.playingDate?.getTime()
    if (progress === this.progress) {
      console.log('restart player')
      this.player?.destroy()
      this.loadPlayer()
    }

    this.progress = progress || 0
  }

  loadPlayer = () => {
    if (!this.video.current) return

    const src = NAP.urlauth2(this.props.src)

    this.player = new Hls(options)
    this.player.loadSource(src)
    this.player.attachMedia(this.video.current)

    this.player.on(Hls.Events.FRAG_CHANGED, function (event, data) {
      console.log(data.frag.relurl)
    })
  }

  getTimeMS = () => {
    return (
      (this.player?.playingDate?.getTime() || 0) + this.props.syncTime * 1000
    )
  }

  getOffset = () => {
    return this.player?.playingDate?.getTime() || 0
  }

  render() {
    const { inrestart } = this.state

    if (inrestart) {
      return (
        <div className='camera-loader'>
          <Spinner className='spinner-small spinner-center' />
        </div>
      )
    }

    return (
      <video
        ref={this.video}
        className={'hls-player ' + this.props.className}
        controls={false}
        preload='none'
        playsInline
        autoPlay
      />
    )
  }
}
