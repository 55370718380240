import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  Panel,
  Grid,
  Row,
  Col,
  Button,
  IconButton,
  Uploader,
  Whisper,
  Tooltip,
  Footer,
  ButtonToolbar,
  Stack,
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaPlus, FaTimes } from 'react-icons/fa'
import moment from 'moment-timezone'

import AceEditor from 'react-ace'
import 'ace-builds/src-noconflict/mode-javascript'
import 'ace-builds/src-noconflict/theme-monokai'
import 'ace-builds/src-noconflict/ext-language_tools'
import 'ace-builds/src-noconflict/ext-beautify'
import 'ace-builds/src-noconflict/mode-css'

import NAP, { ThemesSettings } from 'nap'
import Content, {
  ContentState,
  Header,
  setTitle,
  alert,
  success,
} from 'content'
import './themes.less'

const imgPath = '/assets/img/'
const extLogo = '.png'

interface Props extends WithTranslation {}
interface State extends ContentState {
  themes: string[]
  settings: ThemesSettings
}

class Themes extends React.Component<Props, State> {
  state = {
    settings: {} as ThemesSettings,
  } as State

  componentDidMount() {
    setTitle('themes.title')

    this.loadThemes()
    this.loadSettings()
  }

  loadThemes = () => {
    NAP.themes().then(this.setThemes).catch(this.setError)
  }

  setThemes = (themes: string[]) => {
    this.setState({ themes, loaded: true })
  }

  loadSettings = () => {
    NAP.themesSettings()
      .then((s) => this.setState({ settings: s }))
      .catch(alert)
  }

  setError = (err: Error) => {
    this.setState({ error: err.message, loaded: true })
  }

  //
  // handlers
  //

  handleSetTheme = (src: string) => {
    NAP.changeTheme(src)
      .then(() => {
        localStorage.setItem('theme', src)
        setTheme(src)

        this.setState({})
      })
      .catch(alert)
  }

  handleDelTheme = (src: string) => {
    NAP.deleteTheme(src)
      .then(() => {
        const themes = this.state.themes.filter((s: string) => s !== src)
        this.setState({ themes })
      })
      .catch(alert)
  }

  handleSettings = (val: string, key: string) => {
    const { settings } = this.state
    Object.assign(settings, { [key]: val })
  }

  handleSaveSettings = () => {
    const { settings } = this.state
    NAP.changeThemesSettings(settings)
      .then(() => {
        let tag = document.getElementById(stylesTagId)
        if (tag) tag.innerText = settings.styles

        tag = document.getElementById(scriptTagId)
        if (tag) tag.innerText = settings.script

        success()
      })
      .catch(alert)
  }

  upload = (files: any[], name: string) => {
    if (!files.length) return

    NAP.uploadThemeFile(files[0].blobFile, name)
      .then(() => {
        if (name === 'logo') this.updateLogo(name, 'link[rel=icon]', 'href')
        else if (name === 'header-logo')
          this.updateLogo(name, 'img.menu-logo', 'src')
        else if (name === 'background') this.loadThemes()

        this.setState({})
      })
      .catch(alert)
  }

  updateLogo(name: string, selector: string, attr: string) {
    const el = document.querySelector(selector)
    if (el) el.setAttribute(attr, this.buildURL(name))
  }

  buildURL(name: string): string {
    return NAP.url(imgPath + name + extLogo) + '?' + moment().unix()
  }

  //
  // render
  //

  render() {
    const { t } = this.props
    const { loaded, error, themes, settings } = this.state

    return (
      <Content
        loaded={loaded}
        error={error}
        header={
          <Header>
            <h4 className='content-title'>{t('themes.title')}</h4>
          </Header>
        }>
        <Panel className='content-panel' header={t('themes.backgrounds')}>
          <div className='themes-list'>
            {themes &&
              themes.map((src: string) => (
                <div
                  key={src}
                  className='themes-img'
                  style={{ backgroundImage: `url(${NAP.url(src)})` }}>
                  <IconButton
                    onClick={() => this.handleDelTheme(src)}
                    color='red'
                    className='themes-img-closebtn'
                    
                    appearance='subtle'
                    icon={<FaTimes />}
                  />
                  <Button
                    onClick={() => this.handleSetTheme(src)}
                    className='themes-img-applybtn'
                    appearance='subtle'>
                    {t('apply')}
                  </Button>
                </div>
              ))}
            <Uploader
              className='themes-add'
              action={NAP.url('/api/themes')}
              headers={NAP.authHeaders()}
              onChange={(files) => this.upload(files, 'background')}
              onSuccess={success}
              onError={(r) => alert(r.response.error)}
              accept='image/*'
              multiple={false}
              fileListVisible={false}
              fileList={[]}
              defaultFileList={[]}
              draggable>
              <div>
                <Icon as={FaPlus} />
              </div>
            </Uploader>
          </div>
        </Panel>

        <Panel className='content-panel' header={t('themes.logo')}>
          <Grid fluid>
            <Row gutter={15}>
              <Col md={12}>
                <Stack justifyContent='center'>{this.renderLogo('logo')}</Stack>
              </Col>
              <Col md={12}>
                <Stack justifyContent='center'>
                  {this.renderLogo('header-logo')}
                </Stack>
              </Col>
            </Row>
          </Grid>
          <br />
        </Panel>

        <Panel
          className='content-panel themes-editor'
          header={t('themes.customization')}>
          <Grid fluid>
            <Row gutter={15}>
              <Col md={12}>
                <h5>{t('themes.styles')}</h5>
                <AceEditor
                  name='styles'
                  value={settings.styles}
                  onChange={(v) => this.handleSettings(v, 'styles')}
                  mode='css'
                  theme='monokai'
                  width='100%'
                  height='200px'
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: false,
                    useWorker: false,

                    showLineNumbers: true,
                    wrap: true,
                    highlightActiveLine: true,
                    showPrintMargin: false,

                    tabSize: 2,
                    fontSize: 12,

                    minLines: 6,
                    maxLines: 15,
                  }}
                  commands={[
                    {
                      name: 'save',
                      bindKey: { win: 'Ctrl-s', mac: 'Command-s' },
                      exec: () => this.handleSaveSettings(),
                    },
                  ]}
                />
              </Col>
              <Col md={12}>
                <h5>{t('themes.script')}</h5>
                <AceEditor
                  name='script'
                  value={settings.script}
                  onChange={(v) => this.handleSettings(v, 'script')}
                  mode='javascript'
                  theme='monokai'
                  width='100%'
                  height='200px'
                  setOptions={{
                    enableBasicAutocompletion: true,
                    enableLiveAutocompletion: true,
                    enableSnippets: false,
                    useWorker: false,

                    showLineNumbers: true,
                    wrap: true,
                    highlightActiveLine: true,
                    showPrintMargin: false,

                    tabSize: 2,
                    fontSize: 12,

                    minLines: 6,
                    maxLines: 15,
                  }}
                  commands={[
                    {
                      name: 'save',
                      bindKey: { win: 'Ctrl-s', mac: 'Command-s' },
                      exec: () => this.handleSaveSettings(),
                    },
                  ]}
                />
              </Col>
            </Row>
          </Grid>

          <Stack className='footer' justifyContent='center'>
            <Button appearance='primary' onClick={this.handleSaveSettings}>
              {t('save')}
            </Button>
          </Stack>
        </Panel>
      </Content>
    )
  }

  renderLogo(name: string) {
    const { t } = this.props

    return (
      <Uploader
        name={name}
        action={NAP.url('/api/themes')}
        headers={NAP.authHeaders()}
        onChange={(files) => this.upload(files, name)}
        onSuccess={success}
        onError={(r) => alert(r.response.error)}
        className='themes-logo'
        appearance='subtle'
        accept='image/png'
        multiple={false}
        fileListVisible={false}
        fileList={[]}
        defaultFileList={[]}
        draggable>
        <Whisper
          placement='top'
          trigger='hover'
          speaker={<Tooltip>{t('themes.change_' + name)}</Tooltip>}>
          <img alt='logo' src={this.buildURL(name)} />
        </Whisper>
      </Uploader>
    )
  }
}

export default withTranslation()(Themes)

export function setTheme(src?: string) {
  src = src || localStorage.getItem('theme') || undefined
  if (!src) return

  document.body.style.backgroundImage = `url(${NAP.url(src)})`
}

const stylesTagId = 'injection-styles'
const scriptTagId = 'injection-script'

export function themeInjection() {
  NAP.themesSettings().then((s) => {
    if (s.styles) {
      const tag = document.createElement('style')
      tag.id = stylesTagId
      tag.innerText = s.styles
      document.head.appendChild(tag)
    }

    if (s.script) {
      const tag = document.createElement('script')
      tag.id = scriptTagId
      tag.type = 'text/javascript'
      tag.innerText = s.script
      document.head.appendChild(tag)
    }
  })
}
