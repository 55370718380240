import React from 'react'
import moment from 'moment-timezone'

import NAP from 'nap'

interface Props {
  src: string
  alt?: string
  className?: string
  framerate?: number
  background?: boolean
  width?: number
  height?: number

  onError?: () => boolean
}

interface State {}

export default class SimpleMJPEG extends React.Component<Props, State> {
  componentWillUnmount() {
    window.clearTimeout(this.errTimeout)
  }

  handleError = (e: any) => {
    console.error(e)
    if (this.props.onError) this.props.onError()
  }

  errTimeout = 0
  handleLoad = (e: any) => {
    let timeout = 5000
    if (this.props.framerate) {
      const ms = (1000 / this.props.framerate) * 20
      timeout = Math.max(timeout, ms)
    }

    window.clearTimeout(this.errTimeout)
    this.errTimeout = window.setTimeout(() => {
      console.warn('no frames')
      if (this.props.onError) this.props.onError()
    }, timeout)
  }

  getSrc = () => {
    let { src } = this.props

    src = NAP.urlauth2(src)
    if (src.includes('?')) src += '&time=' + moment().unix()
    else src += '?time=' + moment().unix()

    return src
  }

  render() {
    const { src, className, alt, width, height } = this.props

    const style: any = {}
    if (width) style.width = width + 'px'
    if (height) style.height = height + 'px'

    return (
      <img
        className={className}
        alt={alt}
        src={this.getSrc()}
        style={style}
        data-src={src}
        onError={(e) => this.handleError(e)}
        onAbort={(e) => this.handleError(e)}
        onLoad={(e) => this.handleLoad(e)}
      />
    )
  }
}
