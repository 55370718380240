import { Icon } from '@rsuite/icons'
import moment from 'moment-timezone'
import React from 'react'
import {
  FaCheckCircle,
  FaCircle,
  FaExclamationCircle,
  FaVideo,
} from 'react-icons/fa'
import { Tag } from 'rsuite'

import { IMAGE } from 'components/players'
import { Spinner, alert } from 'content'
import { itemsTranslate } from 'i18n'
import NAP, { Event, Item, NAPTime, User, WS } from 'nap'
import * as STATUS from 'status'
import EventsModalImage from './eventsmodalimage'

export const EventsTimeFormat: string = 'HH:mm'
export const EventsTimeLongFormat: string = 'HH:mm:ss'
export const EventsDateFormat: string = 'MMMM Do YYYY'
export const EventStateDate: string = '_date'

interface Props {
  user?: User
}

interface State {
  loaded: boolean
  events: Event[]
  openEvent: Event | undefined
  openImage: string | undefined
}

export default class EventsTimeline extends React.Component<Props, State> {
  state = {} as State

  timeZone = 0
  ws?: WS = undefined

  componentDidMount() {
    this.loadEvents()
    this.ws = NAP.ws()
    this.ws
      .events(this.onEvent)
      .then((ws) => ws.subscribe())
      .catch(alert)
  }

  componentWillUnmount() {
    this.ws?.unsubscribe()
    this.ws?.close()
  }

  loadEvents = () => {
    NAP.time().then(this.setTime).catch(alert)
    NAP.events({ limit: 30, feed: true, slim: true })
      .then(this.setEvents)
      .catch(alert)
  }

  setEvents = (events: Event[]) => {
    this.fixEventsTime(events)
    this.setState({ events: events || [], loaded: true })
  }

  onEvent = (e: Event) => {
    this.fixEventTime(e)
    const events = [e].concat(this.state.events)

    this.setState({ events: events.slice(0, 30) })
  }

  fixEventsTime = (events: Event[]) => {
    events?.forEach(this.fixEventTime)
  }

  fixEventTime = (e: Event) => {
    if (e) {
      e.time_at = moment.unix(e.time_at).utcOffset(this.timeZone)
      e.time_to = moment.unix(e.time_to).utcOffset(this.timeZone)
    }
  }

  setTime = (t: NAPTime) => {
    this.timeZone = t.offset / 60
  }

  toggleEventWindow = (e?: Event, imgSrc?: string) => {
    this.setState({ openEvent: e, openImage: imgSrc })
  }

  callbackEventDelete = () => {
    const { events } = this.state
    this.setState({ events })
  }

  //
  // data
  //

  getData = (): Event[] | undefined => {
    const { events } = this.state
    if (!events) return undefined

    const newEvents: Event[] = []
    let prevTime = moment()

    events.forEach((e: Event) => {
      if (!e) return

      // const currTime = moment.unix(e.time_at)
      if (!e.time_at.isSame(prevTime, 'day')) {
        const unix = e.time_at.unix()

        // INFO: capitalize date string,
        // because css `text-transform: capitalize` bugged in chrome
        let date = e.time_at.format(EventsDateFormat)
        date = date.charAt(0).toUpperCase() + date.slice(1)

        newEvents.push({
          id: unix,
          time_at: e.time_at,
          time_to: e.time_at,
          status: date,
          state: EventStateDate,
        } as Event)
      }

      prevTime = e.time_at
      newEvents.push(e)
    })

    return newEvents
  }

  //
  //
  //

  render() {
    const { user } = this.props
    const events = this.getData()
    const { openEvent, openImage } = this.state

    return (
      <div>
        {openEvent && openImage && (
          <EventsModalImage
            events={this.state.events}
            event={openEvent}
            imgSrc={openImage}
            onHide={this.toggleEventWindow}
            onDelete={this.callbackEventDelete}
            user={user}
          />
        )}

        {events?.map((e, i) => this.renderEvent(e, i, events))}
        {events === undefined && <Spinner small center />}
      </div>
    )
  }

  renderEvent = (e: Event, i: number, events: Event[]) => {
    const next = events[i + 1] as any

    if (e.state === EventStateDate) {
      return (
        <div key={e.id} className='events-timeline-item'>
          {/* {this.renderLine()} */}
          <div></div>
          {/* {this.renderDot(e)} */}
          <div className='events-timeline-date'>{e.status}</div>
        </div>
      )
    }

    return (
      <div key={e.id} className='events-timeline-item'>
        {(!next || next.state !== EventStateDate) && this.renderLine()}

        <div>
          <div className='events-timeline-item-header'>
            {this.renderDot(e)}
            {this.renderEventTime(e)}

            <div className='events-timeline-camera'>
              <Icon as={FaVideo} />
              {e.camera}
            </div>
          </div>
          <div
            className={`events-timeline-data ${
              e.images && e.images.length > 0 && 'withimages'
            }`}>
            <div className='events-timeline_images'>
              {e.frame && this.renderThumbnail(e, e.frame)}
              {e.images &&
                e.images.map((img) => this.renderThumbnail(e, img, true))}
            </div>

            <div>
              <EventItems className='events-timeline_items' {...e} />

              {e.status && (
                <div
                  className='events-timeline_status'
                  data-state={e.state}
                  // data-alert={e.alert}
                  dangerouslySetInnerHTML={{ __html: e.status }}
                />
              )}
            </div>
          </div>
        </div>
      </div>
    )
  }

  renderEventTime(e: Event) {
    if (e.time_to - e.time_at > 60000) {
      return (
        <p className='events-time-atto'>
          {`${e.time_at.format(EventsTimeFormat)} 
          - 
          ${e.time_to.format(EventsTimeFormat)}`}
        </p>
      )
    }

    return (
      <p className='events-time'>{e.time_at.format(EventsTimeLongFormat)}</p>
    )
  }

  renderDot(e: Event) {
    return <div className='events-timeline-dot'>{this.renderDotIcon(e)}</div>
  }

  renderDotIcon(e: Event) {
    let estate = e.state ? e.state : e.alert ? STATUS.FAIL : ''
    if (estate === '' && e.items && e.items.length === 1) {
      const item = e.items[0]
      estate = item.state
    }

    if (estate === STATUS.FAIL) {
      return (
        <FaExclamationCircle
          className='events-state-icon'
          data-state={estate}
        />
      )
    }

    if (estate === STATUS.PASS) {
      return <FaCheckCircle className='events-state-icon' data-state={estate} />
    }

    return <FaCircle className='events-state-icon' data-state={estate} />
  }

  renderThumbnail(e: Event, src: string, hidebbox?: boolean) {
    return (
      <div
        key={src}
        className='events-image-btn'
        onClick={() => this.toggleEventWindow(e, src)}>
        <IMAGE
          key={src}
          src={src + '?thumb'}
          width={48}
          height={48}
          background
          className='events-image'
        />
      </div>
    )
  }

  renderLine() {
    return <div className='events-timeline-line'></div>
  }
}

//
//
//

interface EventItemsProps extends Event {
  onClick?: (item: Item) => void
  className?: string
}

export class EventItems extends React.Component<EventItemsProps> {
  toCollections(items: Item[]) {
    if (!items) return []

    const types = {} as Record<string, Item>

    items.forEach((item: Item) => {
      let key = `${item.type}${item.name}${item.state}`

      let name = item.name
      if (item.text?.name && !item.text.name.includes('---')) {
        key += item.text.name
        if (!item.name) name = item.text.name
      }

      const collection = types[key]

      if (collection) {
        collection.count++
      } else {
        types[key] = {
          _key: key,
          type: item.type,
          name: name,
          state: item.state,
          count: 1,
        } as Item
      }
    })

    return Object.values(types)
  }

  render() {
    const items = this.props.items ? this.toCollections(this.props.items) : []

    return (
      <div className={this.props.className}>
        {items.map(this.renderItem)}
        {this.renderProb()}
      </div>
    )
  }

  icons = {
    person: 'man',
    car: 'car',
    head: 'head',
    face: 'user',
    hand: 'hand',
    lhand: 'lhand',
    rhand: 'rhand',
    body: 'tshirt',
    torso: 'tshirt',
    boots: 'boots',
    bicycle: 'bicycle',
    forklift: 'forklift',
  } as Record<string, string>

  renderIcon = (type: string): any => {
    const icon = this.icons[type]
    if (!icon) return undefined
    return (
      <img
        alt={icon}
        src={NAP.url(`/assets/img/${icon}.png`)}
        className='item-icon'
      />
    )
  }

  renderItem = (item: Item) => {
    const { onClick } = this.props

    return (
      <Tag
        key={item._key}
        data-state={item.state}
        data-alert={item.alert}
        className='events-tag-item'
        data-interaction={onClick !== undefined}
        onClick={() => onClick && onClick(item)}>
        {item.count > 1 && (
          <span className='events-item-count'>{item.count}</span>
        )}
        {this.renderIcon(item.type)}
        {itemsTranslate(item.name || item.type)}
        {!item.name && item.text && `:${item.text.name}`}
      </Tag>
    )
  }

  renderProb = () => {
    const { items } = this.props
    if (items?.length !== 1) return

    const item = items[0]
    if (!item.prob) return

    return <Tag className='events-tag-item'>{Math.round(item.prob * 100)}%</Tag>
  }
}
