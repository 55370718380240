import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FaCog } from 'react-icons/fa'
import { Button, Modal, TagPicker } from 'rsuite'
import { ItemDataType } from 'rsuite/esm/@types/common'

import NAP, { Role } from 'nap'

interface Props extends WithTranslation {
  role: Role
}
interface State {
  open: boolean
  sectors: ItemDataType[]
}

class RolesSectors extends React.Component<Props, State> {
  state = {
    open: false,
  } as State

  loadSectors = () => {
    const { role } = this.props

    NAP.sectors()
      .then((sectors) => {
        const items =
          sectors?.map((s) => ({ value: s, label: s } as ItemDataType)) ||
          ([] as ItemDataType[])

        role.sectors?.forEach((s) => {
          if (items.some((item) => item.value === s)) return
          items.push({ value: s, label: s })
        })

        this.setState({ sectors: items })
      })
      .catch(alert)
  }

  toggleOpen = () => {
    const { open } = this.state
    if (!open) this.loadSectors()

    this.setState({ open: !open })
  }

  handleChangeSectors = (v: string[]) => {
    const { role } = this.props

    role.sectors = v
    this.setState({})
  }

  handleSave = () => {
    const { role } = this.props
    NAP.changeRole(role.id, { sectors: role.sectors })
      .then((resp) => {
        Object.assign(role, resp)
        this.toggleOpen()
      })
      .catch(alert)
  }

  render() {
    const { t, role } = this.props
    const { open, sectors } = this.state

    return (
      <div>
        <Button
          appearance='primary'
          className='table-mini-btn'
          color='blue'
          onClick={this.toggleOpen}>
          {role.sectors && role.sectors.length > 0 ? (
            role.sectors!.length
          ) : (
            <FaCog />
          )}
        </Button>
        {open && (
          <Modal open={open} onClose={this.toggleOpen}>
            <Modal.Header>
              <Modal.Title>{t('profiles.sectors')}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <TagPicker
                value={role.sectors || []}
                data={sectors}
                onChange={this.handleChangeSectors}
                creatable
                block
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={this.toggleOpen}>{t('cancel')}</Button>
              <Button appearance='primary' onClick={this.handleSave}>
                {t('save')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}
      </div>
    )
  }
}

export default withTranslation()(RolesSectors)
