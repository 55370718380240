import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { Panel } from 'rsuite'
import moment from 'moment-timezone'

import NAP, { FloorMapObject, Event } from 'nap'
import { alert, Spinner } from 'content'
import ReactEcharts from 'echarts-for-react'
import Calendar from 'utils/calendar'

interface Props extends WithTranslation {
  cameras: FloorMapObject[]
  onEvents?: (events?: Event[]) => void
}
interface State {
  loading?: boolean
  events: Event[]

  date_at: moment.Moment
  date_to: moment.Moment
}

class FloorMapEvents extends React.Component<Props, State> {
  state = {
    date_at: moment().startOf('day'),
    date_to: moment(),
  } as State

  interval = 0

  componentDidMount() {
    this.loadEvents()
    this.interval = window.setInterval(this.loadEvents, 5000)
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  loadEvents = () => {
    const { date_at, date_to } = this.state
    const opt = {
      time_at: date_at.unix(),
      time_to: date_to.unix(),
    }

    NAP.events(opt)
      .then((events) => this.setState({ events, loading: false }))
      .catch(alert)
  }

  //
  // handlers
  //

  handleCalendar = (dates: any) => {
    const date_at = moment(dates[0]).startOf('day')
    const date_to = moment(dates[1]).endOf('day')

    this.setState({ date_at, date_to, loading: true })
    this.loadEvents()
  }

  handleEventsSelect = (e: any) => {
    const { onEvents } = this.props
    if (!onEvents) return

    if (!e.areas.length) return onEvents()

    const range = e.areas[0].coordRange
    const time_at = moment(range[0][0]).unix()
    const time_to = moment(range[0][1]).unix()

    let cam_at = range[1][0]
    let cam_to = range[1][1] + 1 // "+1" fix for slice

    const cams = this.props.cameras.slice(cam_at, cam_to).map((cam) => cam.id)
    const events = this.state.events.filter((e) => {
      return (
        e.time_at >= time_at &&
        e.time_at <= time_to &&
        cams.includes(e.camera_id)
      )
    })

    onEvents(events)
  }

  handleEventsClear = (e: any) => {
    const { onEvents } = this.props
    if (!onEvents) return

    if (e.command === 'clear') onEvents()
  }

  //
  // data
  //

  getEvents = (id: number) => {
    const { events } = this.state
    return events?.filter((e) => e.camera_id === id) || []
  }

  getData = () => {
    const { events } = this.state
    if (!events) return

    const data = events.map((e) => {
      return {
        value: [
          moment.unix(e.time_at).toDate(),
          e.camera,
          e.items?.length || 0,
          e.status,
        ],
        symbolSize: 6 + 2 * (e.items?.length || 0),
        itemStyle: { color: colors[e.state] },
      }
    })

    return data
  }

  //
  // render
  //

  render() {
    const { cameras } = this.props
    const { date_at, date_to, loading } = this.state

    return (
      <Panel header={this.renderHeader()}>
        <ReactEcharts
          theme='dark'
          style={{
            height: 120 + cameras.length * 26,
          }}
          onEvents={{
            brush: this.handleEventsClear,
            brushEnd: this.handleEventsSelect,
          }}
          option={{
            backgroundColor: '#0000',
            textStyle: {
              fontSize: 12,
            },
            tooltip: {
              trigger: 'axis',
              axisPointer: { axis: 'x' },
              valueFormatter: (v: any) => {
                return `${v[1]} ${v[3] || v[2]}`
              },
            },
            yAxis: {
              type: 'category',
              data: cameras.map((cam) => cam.name),
            },
            xAxis: {
              type: 'time',
              min: date_at.toDate(),
              max: date_to.toDate(),
            },
            dataZoom: [{ type: 'inside' }, { type: 'slider' }],
            grid: {
              left: 100,
              right: 45,
              top: 20,
              bottom: 70,
            },
            series: {
              type: 'scatter',
              data: this.getData(),
            },
            toolbox: {
              left: 'center',
              feature: {
                brush: {
                  type: ['rect', 'clear'],
                  title: {
                    rect: '',
                    lineX: '',
                    clear: '',
                  },
                },
              },
            },
            brush: {
              xAxisIndex: 'all',
              yAxisIndex: 'all',
              brushLink: 'all',
              outOfBrush: {
                colorAlpha: 0.2,
              },
            },
            color: ['#fff9'],
          }}
        />
        {loading && <Spinner center small />}
      </Panel>
    )
  }

  renderHeader() {
    const { t } = this.props
    const { date_at, date_to } = this.state

    return (
      <div className='panel-title-withtoolbar'>
        <div>
          <h5>{t('floormaps.events_timeline')}</h5>

          <Calendar
            value={[date_at.toDate(), date_to.toDate()]}
            onChange={this.handleCalendar}
            placement='top'
          />
        </div>

        {/* <ButtonToolbar align='right'>
          <IconButton
            onClick={onTogglePlay}
            icon={<Icon icon={pause ? 'play' : 'pause'} />}
          />
        </ButtonToolbar> */}
      </div>
    )
  }
}

export default withTranslation()(FloorMapEvents)

const colors = {
  fail: '#f04f43',
  pass: '#58b15b',
} as Record<string, string>
