import { Point } from 'nap'

export const CAMERASVIEW = 'camerasview'
export const ITEMSVIEW = 'itemsview'

export const ITEMSVIEW_HIDE = 'hide'
export const ITEMSVIEW_BBOX = 'bbox'
export const ITEMSVIEW_DOT = 'dot'

export const OBJ_CAMERA = 'camera'
export const OBJ_DEVICE = 'device'

export const TYPE_MAP = 'MAP'
export const TYPE_IMAGE = 'IMAGE'

export const settings = {
  getCamerasView: (): boolean => {
    return settings.get(CAMERASVIEW) !== 'hide'
  },

  toggleCamerasView: (): boolean => {
    settings.toggle(CAMERASVIEW)
    return settings.getCamerasView()
  },

  getItemsView: (): string => {
    return settings.get(ITEMSVIEW) || ITEMSVIEW_DOT
  },

  setItemsView: (s: string) => {
    settings.set(ITEMSVIEW, s)
  },

  toggleItemsView: () => {
    const itemsview = settings.get(ITEMSVIEW)
    if (itemsview === ITEMSVIEW_BBOX) settings.setItemsView(ITEMSVIEW_DOT)
    else if (itemsview === ITEMSVIEW_DOT) settings.setItemsView(ITEMSVIEW_HIDE)
    else settings.setItemsView(ITEMSVIEW_BBOX)
  },

  get: (key: string) => {
    return localStorage.getItem(key)
  },

  set: (key: string, val: string) => {
    localStorage.setItem(key, val)
  },

  toggle: (key: string) => {
    if (localStorage.getItem(key)) localStorage.removeItem(key)
    else localStorage.setItem(key, 'hide')
  },
}

export function dist(a: Point, b: Point): number {
  return Math.sqrt(
    Math.pow(Math.abs(a[0] - b[0]), 2) + Math.pow(Math.abs(a[1] - b[1]), 2)
  )
}

export function bboxPoints(bbox: number[]): Point[] {
  return [
    [bbox[0], bbox[1]],
    [bbox[0] + bbox[2], bbox[1]],
    [bbox[0] + bbox[2], bbox[1] + bbox[3]],
    [bbox[0], bbox[1] + bbox[3]],
  ]
}

export function bboxCenter(bbox: number[]): Point {
  return [bbox[0] + bbox[2] / 2, bbox[1] + bbox[3] / 2]
}

export function pointCenter(a: Point, b: Point): Point {
  return [(a[0] + b[0]) / 2, (a[1] + b[1]) / 2]
}

export function pointsCenter(points: Point[]): Point {
  var x = points.map((xy) => xy[0])
  var y = points.map((xy) => xy[1])
  var cx = (Math.min(...x) + Math.max(...x)) / 2
  var cy = (Math.min(...y) + Math.max(...y)) / 2
  return [cx, cy]

  // const a = pointCenter(points[0], points[2])
  // const b = pointCenter(points[1], points[3])
  // return pointCenter(a, b)

  // const pts = points.map((p) => p)
  // const first = pts[0]
  // const last = pts[pts.length - 1]
  // if (first[0] != last[0] || first[1] != last[1]) pts.push(first)
  // let twicearea = 0
  // let x = 0
  // let y = 0
  // let nPts = pts.length
  // let p1 = [0, 0]
  // let p2 = [0, 0]
  // let f = 0
  // for (let i = 0, j = nPts - 1; i < nPts; j = i++) {
  //   p1 = pts[i]
  //   p2 = pts[j]
  //   f = p1[0] * p2[1] - p2[0] * p1[1]
  //   twicearea += f
  //   x += (p1[0] + p2[0]) * f
  //   y += (p1[1] + p2[1]) * f
  // }
  // f = twicearea * 3
  // return [x / f, y / f]

  // let x = 0
  // let y = 0
  // let i = 0
  // let j = 0
  // let f = 0
  // let point1 = [0, 0]
  // let point2 = [0, 0]

  // for (i = 0, j = points.length - 1; i < points.length; j = i, i++) {
  //   point1 = points[i]
  //   point2 = points[j]
  //   f = point1[0] * point2[1] - point2[0] * point1[1]
  //   x += (point1[0] + point2[0]) * f
  //   y += (point1[1] + point2[1]) * f
  // }

  // f = area(points) * 6

  // return [x / f, y / f]

  // return vectorIntersect(points[0], points[2], points[1], points[3])!
}

export function area(points: Point[]) {
  let area = 0
  let i = 0
  let j = 0
  let point1 = [0, 0]
  let point2 = [0, 0]

  for (i = 0, j = points.length - 1; i < points.length; j = i, i++) {
    point1 = points[i]
    point2 = points[j]
    area += point1[0] * point2[1]
    area -= point1[1] * point2[0]
  }
  area /= 2

  return area
}

export function vectorIntersect(
  a: Point,
  b: Point,
  c: Point,
  d: Point
): Point | undefined {
  let n = 0
  if (b[1] - a[1] != 0) {
    const q = (b[0] - a[0]) / (a[1] - b[1])
    const sn = c[0] - d[0] + (c[1] - d[1]) * q
    if (!sn) return undefined

    const fn = c[0] - a[0] + (c[1] - a[1]) * q
    n = fn / sn
  } else {
    if (!(c[1] - d[1])) return undefined

    n = (c[1] - a[1]) / (c[1] - d[1])
  }

  return [c[0] + (d[0] - c[0]) * n, c[1] + (d[1] - c[1]) * n]
}

export function vectorIntersectPoints(
  points: Point[],
  center: Point,
  remote: Point
) {
  let prev = {} as any
  let prevd = 999
  for (let i = 0; i < points.length; i++) {
    let j = i + 1
    if (j >= points.length) j = 0

    const a = points[i]
    const b = points[j]

    const c = vectorIntersect(a, b, center, remote)
    if (c) {
      const d = dist(center, c)
      if (d < prevd) {
        prev = c
        prevd = d
      }
    }
  }

  return prev
}

export function lineIntersect(
  a: Point,
  b: Point,
  c: Point,
  d: Point
): Point | undefined {
  const den = (d[1] - c[1]) * (b[0] - a[0]) - (d[0] - c[0]) * (b[1] - a[1])

  // Lines are parallel
  if (!den) return undefined

  let ua = ((d[0] - c[0]) * (a[1] - c[1]) - (d[1] - c[1]) * (a[0] - c[0])) / den
  let ub = ((b[0] - a[0]) * (a[1] - c[1]) - (b[1] - a[1]) * (a[0] - c[0])) / den

  // is the intersection along the segments
  if (ua < 0 || ua > 1 || ub < 0 || ub > 1) return undefined

  // Return a object with the x and y coordinates of the intersection
  return [a[0] + ua * (b[0] - a[0]), a[1] + ua * (b[1] - a[1])]
}

export function lineIntersectPoints(
  points: Point[],
  center: Point,
  remote: Point
) {
  for (let i = 0; i < points.length; i++) {
    let j = i + 1
    if (j >= points.length) j = 0

    const a = points[i]
    const b = points[j]

    const c = lineIntersect(a, b, center, remote)
    if (c) {
      return { c, i, j, d: dist(center, c) }
    }
  }
}

export function lerp(a: number, b: number, t: number): number {
  return a + t * (b - a)
}

export function lerpPoints(a: Point, b: Point, t: number): Point {
  return [lerp(a[0], b[0], t), lerp(a[1], b[1], t)] as Point
}

export function pointPosition(t: Point, area: Point[]): Point {
  const p = lerpPoints(area[0], area[1], t[0])
  const q = lerpPoints(area[3], area[2], t[0])
  return lerpPoints(p, q, t[1])
}

export function angle(a: Point, b: Point): number {
  return Math.atan2(b[1] - a[1], b[0] - a[0]) //+ (90 * Math.PI) / 180
}

// export function perspectiveTransformPoint(
//   matrix: number[][],
//   x: number,
//   y: number
// ) {
//   //  Perform the perspective transformation on the input point
//   const transformed_point = math.multiply(matrix, [x, y, 1])

//   return [
//     transformed_point[0] / transformed_point[2],
//     transformed_point[1] / transformed_point[2],
//   ]
// }

// export function computePerspectiveTransformMatrix(
//   mapArea: Point[],
//   camArea: Point[]
// ) {
//   // Compute the perspective transformation matrix using cross-ratios
//   const xa = mapArea[0][0]
//   const ya = mapArea[0][1]
//   const xb = mapArea[1][0]
//   const yb = mapArea[1][1]
//   const xc = mapArea[2][0]
//   const yc = mapArea[2][1]
//   const xd = mapArea[3][0]
//   const yd = mapArea[3][1]

//   const xp = camArea[0][0]
//   const yp = camArea[0][1]
//   const xq = camArea[1][0]
//   const yq = camArea[1][1]
//   const xr = camArea[2][0]
//   const yr = camArea[2][1]
//   const xs = camArea[3][0]
//   const ys = camArea[3][1]

//   const A = [
//     [xa, ya, 1, 0, 0, 0, -xp * xa, -xp * ya],
//     [xb, yb, 1, 0, 0, 0, -xq * xb, -xq * yb],
//     [xc, yc, 1, 0, 0, 0, -xr * xc, -xr * yc],
//     [xd, yd, 1, 0, 0, 0, -xs * xd, -xs * yd],
//     [0, 0, 0, xa, ya, 1, -yp * xa, -yp * ya],
//     [0, 0, 0, xb, yb, 1, -yq * xb, -yq * yb],
//     [0, 0, 0, xc, yc, 1, -yr * xc, -yr * yc],
//     [0, 0, 0, xd, yd, 1, -ys * xd, -ys * yd],
//   ]

//   const solution = math.lusolve(A, [xp, xq, xr, xs, yp, yq, yr, ys])

//   const m = solution.map((item) => item[0])
//   m.push(1)

//   return [m.slice(0, 3), m.slice(3, 6), m.slice(6, 9)]
// }
