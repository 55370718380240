import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FaCheck, FaPlus } from 'react-icons/fa'
import { Input, InputGroup, InputPicker, Popover, Whisper } from 'rsuite'

import NAP from 'nap'

interface Props extends WithTranslation {
  value: string
  onChange: (v?: string) => void
  disabled?: boolean
}

interface State {
  sectors: Record<string, string>[]
  sector?: string
}

class SelectSector extends React.Component<Props, State> {
  state = {
    sectors: [] as Record<string, string>[],
  } as State

  refPicker = React.createRef<any>()
  refPopup = React.createRef<any>()

  componentDidMount(): void {
    this.loadSectors()
  }

  loadSectors = () => {
    const { value } = this.props

    NAP.sectors()
      .then((resp) => {
        const sectors = resp?.map((s: string) => ({ name: s })) || []

        if (value && !resp.includes(value)) {
          sectors.push({ name: value })
        }

        this.setState({ sectors })
      })
      .catch(alert)
  }

  handleInput = (v?: string) => {
    this.setState({ sector: v })
  }

  handleSubmit = (v?: string) => {
    let { sectors } = this.state
    if (v && !sectors.some((s) => s.name === v)) {
      sectors = sectors.concat([{ name: v }])
    }

    this.setState({ sectors, sector: '' })

    this.props.onChange(v)
    this.refPopup.current?.close()
  }

  render() {
    const { t, value, disabled } = this.props
    const { sectors, sector } = this.state

    return (
      <InputGroup>
        <InputPicker
          ref={this.refPicker}
          name='sector'
          value={value || ''}
          data={sectors || []}
          onChange={(v) => this.handleSubmit(v)}
          onOpen={this.loadSectors}
          valueKey='name'
          labelKey='name'
          disabled={disabled}
          creatable
          block
        />
        <Whisper
          ref={this.refPopup}
          trigger='click'
          placement='bottom'
          onOpen={this.loadSectors}
          speaker={
            <Popover title={t('cameras.add_sector')}>
              <InputGroup>
                <Input
                  value={sector || ''}
                  placeholder={t('cameras.sector')}
                  onChange={(v) => this.handleInput(v)}
                />
                <InputGroup.Button
                  disabled={!sector}
                  appearance='primary'
                  onClick={() => this.handleSubmit(sector)}>
                  <FaCheck />
                </InputGroup.Button>
              </InputGroup>
            </Popover>
          }>
          <InputGroup.Button disabled={disabled}>
            <FaPlus />
          </InputGroup.Button>
        </Whisper>
      </InputGroup>
    )
  }
}

export default withTranslation()(SelectSector)
