import { Icon } from '@rsuite/icons'
import path from 'path'
import React, { DragEvent } from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  FaCamera,
  FaCog,
  FaImage,
  FaImages,
  FaListAlt,
  FaTimes,
  FaVideo,
} from 'react-icons/fa'
import ReactResizeDetector from 'react-resize-detector'
import { Link, RouteComponentProps, withRouter } from 'react-router-dom'
import {
  Button,
  ButtonToolbar,
  CheckPicker,
  Col,
  Divider,
  Dropdown,
  FlexboxGrid,
  Footer,
  Form,
  Grid,
  IconButton,
  Input,
  InputPicker,
  Message,
  Modal,
  Panel,
  Placeholder,
  Popover,
  Row,
  Tooltip,
  Uploader,
  Whisper,
} from 'rsuite'

import { CameraCanvas, CameraHeader } from 'components/cameras'
import { IMAGE, WEBCAM, WSFRAMES } from 'components/players'
import Content, {
  ContentState,
  Header,
  HeaderLeft,
  SelectWithIcon,
  SettingsInput,
  alert,
  setTitle,
  success,
} from 'content'
import ROUTES from 'routes'
import NAP, {
  Camera,
  Detected,
  Group,
  Item,
  Profile,
  SettingsField,
  User,
  profilePhotoOpt,
} from 'nap'
import ProfileImageEditor from './profileimageeditor'
import { fullname } from './profiles'

const required = {
  firstname: true,
  group_id: true,
} as Record<string, boolean>

interface RouteParams {
  id: string
}
interface Props extends WithTranslation, RouteComponentProps<RouteParams> {}
interface State extends ContentState {
  profile: Profile
  photos: string[]
  groups: Group[]
  fields: SettingsField[]
  sectors: string[] | null
  user: User

  webcam: boolean

  camera?: Camera
  cameras?: Camera[]

  opt: profilePhotoOpt
  img?: string
}

class ProfileForm extends React.Component<Props, State> {
  state = {
    profile: {
      group: {} as Group,
    } as Profile,
    photos: [] as string[],
    groups: [] as Group[],
    opt: {
      nodetect: false,
      emotions: false,
      get_quality: true,
    } as profilePhotoOpt,
  } as State

  refCamera = React.createRef<any>()

  constructor(props: Props) {
    super(props)

    this.state.profile.id = parseInt(props.match.params.id || 'new') | 0

    if (this.state.profile.id === 0) {
      this.state.title = props.t('profiles.new')
      this.state.loaded = true
    }
  }

  componentDidMount() {
    const { id } = this.state.profile

    setTitle('profiles.title')

    NAP.groups().then(this.setGroups).catch(this.setError)
    NAP.user().then((user) => this.setState({ user }))
    NAP.profilesFields().then((fields) => this.setState({ fields }))
    NAP.sectors().then((sectors) => this.setState({ sectors }))

    if (id > 0) {
      NAP.profile(id).then(this.setProfile).catch(this.setError)
      this.loadPhotos(id)
    }

    NAP.cameras().then((cameras) => {
      cameras = cameras.filter(
        (cam) => cam.enabled && !['VIDEO', 'CACHE', 'WEBCAM'].includes(cam.type)
      )
      this.setState({ cameras })
    })
  }

  componentDidUpdate() {
    const id = parseInt(this.props.match.params.id || 'new') || 0
    if (id && id != this.state.profile.id) {
      NAP.profile(id).then(this.setProfile).catch(this.setError)
      this.loadPhotos(id)
    }
  }

  loadPhotos = (id: number) => {
    NAP.profilePhotos(id).then(this.setPhotos).catch(this.setError)
  }

  setProfile = (p: Profile) => {
    setTitle('profiles.title', fullname(p))

    this.setState({
      title: fullname(p),
      profile: p,
      loaded: true,
    })

    window.history.replaceState(
      p,
      p.name,
      path.join(ROUTES.profiles.people, p.id.toString())
    )
  }

  setPhotos = (photos: string[]) => {
    this.setState({ photos: photos || [] })
  }

  setGroups = (groups: Group[]) => {
    this.setState({ groups })
  }

  setError = (err: Error) => {
    this.setState({
      error: err.message,
      loaded: true,
    })
  }

  //
  // handlers
  //

  handleInput = (val: string, e: any) => {
    const { profile } = this.state

    profile[e.target.name] = val
    this.setState({ profile })
  }

  handleSectorsChanges = (sectors: string[]) => {
    const { profile } = this.state
    profile.sectors = sectors

    this.setState({ profile })
  }

  handleGroupChanges = (id: number) => {
    const { profile, groups } = this.state
    profile.group = groups.find((g: Group) => g.id === id) || ({} as Group)
    profile.group_id = id || 0

    this.setState({ profile })
  }

  handleFieldsChange = (value: string, name: string) => {
    const { profile } = this.state

    // if profile data is not initialize do this
    if (!profile.data) profile.data = {} as Record<string, string>
    profile.data[name] = value

    this.setState({ profile })
  }

  saveProfile = () => {
    const { t } = this.props
    const { profile } = this.state

    if (profile.id) {
      NAP.changeProfile(profile.id, profile)
        .then((p) => {
          success(t('success'))
          this.setProfile(p)
        })
        .catch(alert)
    } else {
      NAP.createProfile(profile).then(this.setProfile).catch(alert)
    }
  }

  removePhoto = (src: string) => {
    const { id } = this.state.profile

    NAP.deleteProfilePhoto(id, src)
      .then(() => this.loadPhotos(id))
      .catch(alert)
  }

  handleUpload = (files: any) => {
    if (!files || !files.length) return
    this.readImage(files[0].blobFile)
  }

  handleUploadDrop = (e: DragEvent<HTMLDivElement>) => {
    if (!e.dataTransfer.files || !e.dataTransfer.files.length) return
    this.readImage(e.dataTransfer.files[0])
  }

  readImage = (file: Blob) => {
    const r = new FileReader()
    r.onload = (e) => {
      if (e.target?.result) this.setState({ img: e.target.result as string })
    }
    r.readAsDataURL(file)
  }

  uploadPhoto = (file: Blob): Promise<any> => {
    const { profile, photos, opt } = this.state

    return NAP.uploadProfilePhoto(profile.id, file, opt)
      .then((resp: any) => {
        if (resp.filename) photos.push(resp.filename)
        else alert(`upload failed ${resp.message || resp.error}`)
        this.setState({ photos, img: undefined })

        return resp
      })
      .catch(alert)
  }

  toggleWebcam = () => {
    this.setState({ webcam: !this.state.webcam })
  }

  toggleCamera = (id: number, camera?: Camera) => {
    this.setState({ camera })
  }

  handleCameraPhoto = () => {
    const img = this.refCamera.current?.state.imgData
    if (!img) return

    fetch(img)
      .then((res) => res.blob())
      .then(this.uploadPhoto)
      .then((resp) => {
        if (resp.filename) {
          this.toggleCamera(0)
          success()
        }
      })
  }

  handlePhotoOpt = (o: Record<string, boolean>) => {
    const { opt } = this.state
    Object.assign(opt as any, o)

    this.setState({ opt })
  }

  handleDeleteImages = () => {
    const { profile, photos } = this.state
    NAP.deleteProfilePhotos(profile.id)
      .then(() => {
        success()

        // reset photos array to reload images
        this.setState({ photos: photos.map(() => '') })
      })
      .catch(alert)
  }

  isAllowSave = (): boolean => {
    const { profile, user } = this.state

    let allow: boolean = profile.firstname?.length > 0

    if (user && user.groups_id?.length) {
      if (!profile.group_id) allow = false
    }

    return allow
  }

  //
  //
  //

  render() {
    const { t } = this.props
    const { loaded, error, profile, photos, opt, img } = this.state
    const { cameras, camera, webcam } = this.state

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        {img && (
          <ProfileImageEditor
            img={img}
            onResult={this.uploadPhoto}
            onHide={() => this.setState({ img: undefined })}
          />
        )}

        {camera && (
          <Modal onClose={() => this.toggleCamera(0)} open>
            <Modal.Header>
              <Modal.Title>
                <h5>{t('profiles.assign_photo')}</h5>
              </Modal.Title>
            </Modal.Header>
            <Modal.Body className='camera-player'>
              <CameraHeader camera={camera} />
              <WSFRAMES
                ref={this.refCamera}
                className='camera-player'
                camera_id={camera.id}
                rate={(camera.params as any).decode?.framerate || 1}
              />
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={() => this.toggleCamera(0)}>
                {t('cancel')}
              </Button>
              <Button
                appearance='primary'
                onClick={() => this.handleCameraPhoto()}>
                {t('apply')}
              </Button>
            </Modal.Footer>
          </Modal>
        )}

        <div className='content-panel'>
          {webcam && (
            <Webcam
              show={webcam}
              onHide={this.toggleWebcam}
              uploadPhoto={this.uploadPhoto}
            />
          )}

          <Grid fluid className='content-grid'>
            <Row gutter={15}>
              <Col lg={profile.id === 0 ? 24 : 12}>{this.renderForm()}</Col>

              {profile.id > 0 && (
                <Col lg={12}>
                  <Panel className='profile-actions-panel' bodyFill>
                    <ButtonToolbar justifyContent='center'>
                      {cameras && (
                        <SelectWithIcon
                          value={camera}
                          data={cameras}
                          onSelect={this.toggleCamera}
                        />
                      )}

                      <Whisper
                        placement='top'
                        trigger='hover'
                        speaker={
                          <Tooltip>{t('profiles.capture_photo')}</Tooltip>
                        }>
                        <IconButton
                          onClick={() => this.toggleWebcam()}
                          icon={<FaCamera />}
                        />
                      </Whisper>

                      <Uploader
                        action={NAP.url(`/api/profiles/${profile.id}/photos`)}
                        headers={NAP.authHeaders()}
                        onChange={this.handleUpload}
                        onError={(r) => alert(r.response.error)}
                        listType='text'
                        fileList={[]}
                        accept='image/jpeg'
                        autoUpload={false}
                        fileListVisible={false}>
                        <Whisper
                          placement='top'
                          trigger='hover'
                          speaker={
                            <Tooltip>{t('profiles.upload_photo')}</Tooltip>
                          }>
                          <IconButton icon={<FaImage />} />
                        </Whisper>
                      </Uploader>

                      <Whisper
                        trigger='click'
                        placement='bottom'
                        speaker={
                          <Popover full>
                            <Dropdown.Menu>
                              <Dropdown.Item
                                active={opt.nodetect}
                                onSelect={() =>
                                  this.handlePhotoOpt({
                                    nodetect: !opt.nodetect,
                                  })
                                }>
                                {t('profiles.nodetect')}
                              </Dropdown.Item>
                              <Dropdown.Item
                                active={opt.get_quality}
                                onSelect={() =>
                                  this.handlePhotoOpt({
                                    get_quality: !opt.get_quality,
                                  })
                                }>
                                {t('profiles.get_quality')}
                              </Dropdown.Item>
                            </Dropdown.Menu>
                          </Popover>
                        }>
                        <IconButton icon={<FaCog />} />
                      </Whisper>
                    </ButtonToolbar>
                  </Panel>

                  <Panel
                    className='profile-dnduploader'
                    onDrop={(e: any) => {
                      e.preventDefault()
                      this.handleUploadDrop(e)
                      e.target.classList.remove('dragon')
                    }}
                    onDragOver={(e: any) => e.preventDefault()}
                    onDragEnter={(e: any) => {
                      e.target.classList.add('dragon')
                    }}
                    onDragLeave={(e: any) => {
                      e.target.classList.remove('dragon')
                    }}
                    bodyFill>
                    {!photos?.length && (
                      <Icon className='dnd-icon' as={FaImages} />
                    )}
                    <FlexboxGrid justify='center'>
                      {photos?.map((src: string, i: number) => (
                        <div key={i} className='profile-photo-panel'>
                          <IconButton
                            appearance='primary'
                            color='red'
                            className='close-btn'
                            onClick={() => this.removePhoto(src)}
                            icon={<FaTimes />}
                            circle
                          />
                          <Placeholder
                            graph='image'
                            className='profile-photo-placeholder'
                          />
                          {src ? (
                            <IMAGE
                              src={`/api/profiles/${profile.id}/photos/${src}`}
                              className='profile-photo'
                              width={200}
                              height={200}
                              background
                            />
                          ) : (
                            <div
                              className='profile-photo'
                              style={{ width: 200, height: 200 }}></div>
                          )}
                        </div>
                      ))}
                    </FlexboxGrid>
                  </Panel>

                  <Panel bodyFill>
                    <Message className='multiline_msg' type='info' showIcon>
                      {t('profiles.help.photo')}
                    </Message>
                  </Panel>
                </Col>
              )}
            </Row>
          </Grid>
        </div>
      </Content>
    )
  }

  renderHeader() {
    const { t } = this.props
    const { profile } = this.state

    return (
      <Header
        left={
          <ButtonToolbar>
            <HeaderLeft back={ROUTES.profiles.people} />
            {profile.id > 0 && (
              <Whisper
                placement='bottom'
                trigger='hover'
                speaker={<Tooltip>{t('profiles.search_events')}</Tooltip>}>
                <Link to={`${ROUTES.eventsarchive}?profiles=${profile.id}`}>
                  <IconButton icon={<FaListAlt />} circle />
                </Link>
              </Whisper>
            )}
          </ButtonToolbar>
        }
        right={
          <ButtonToolbar>
            {profile.id > 0 && (
              <Dropdown
                placement='bottomEnd'
                renderToggle={(props, ref) => (
                  <IconButton {...props} ref={ref} icon={<FaCog />} circle />
                )}>
                <Dropdown.Item onClick={this.handleDeleteImages}>
                  <p>{t('profiles.delete_images')}</p>
                  <span className='dropdown-help'>
                    {t('profiles.help.delete_images')}
                  </span>
                </Dropdown.Item>
              </Dropdown>
            )}
          </ButtonToolbar>
        }>
        <h4 className='content-title'>{this.state.title}</h4>
      </Header>
    )
  }

  renderForm() {
    return (
      <Panel className='profile-form'>
        <Form fluid>
          <FlexboxGrid>
            {this.renderInput('firstname', 'profiles.people.firstname', 12)}
            {this.renderInput('lastname', 'profiles.people.lastname', 12)}
            {this.renderInput('middlename', 'profiles.people.middlename', 12)}
            {this.renderInput('dateofbirth', 'profiles.people.dateofbirth', 12)}

            <FlexboxGrid.Item as={Col} colspan={24} md={24}>
              <Divider />
            </FlexboxGrid.Item>

            {this.renderInput('externalid', 'profiles.externalid', 12)}
            {this.renderSectorsInput()}
            {this.renderInput('department', 'profiles.people.department', 12)}
            {this.renderInput('position', 'profiles.people.position', 12)}

            <FlexboxGrid.Item as={Col} colspan={24} md={24}>
              <Divider />
            </FlexboxGrid.Item>

            {this.renderFields()}

            <FlexboxGrid.Item as={Col} colspan={24} md={24}>
              <Divider />
            </FlexboxGrid.Item>

            {this.renderGroupInput()}
          </FlexboxGrid>
        </Form>
        {this.renderFooter()}
      </Panel>
    )
  }

  renderInput(name: string, labelkey: string, md: number) {
    const { t } = this.props
    const { profile } = this.state

    return (
      <FlexboxGrid.Item as={Col} colspan={24} md={md}>
        <Form.Group>
          <Form.ControlLabel>
            {t(labelkey)}
            {required[name] && '*'}
          </Form.ControlLabel>
          <Input
            name={name}
            value={profile[name] || ''}
            onChange={this.handleInput}
          />
        </Form.Group>
      </FlexboxGrid.Item>
    )
  }

  renderSectorsInput() {
    const { t } = this.props
    const { profile, sectors } = this.state

    const inputData = sectors?.map((s) => ({ name: s })) || []

    return (
      <FlexboxGrid.Item as={Col} colspan={24} md={12}>
        <Form.Group>
          <Form.ControlLabel>{t('profiles.sectors')}</Form.ControlLabel>
          <CheckPicker
            data={inputData}
            onChange={this.handleSectorsChanges}
            value={profile.sectors || undefined}
            valueKey='name'
            labelKey='name'
            block
            searchable={sectors ? sectors.length > 10 : false}
          />
        </Form.Group>
      </FlexboxGrid.Item>
    )
  }

  renderGroupInput() {
    const { t } = this.props
    const { profile, groups, user } = this.state

    return (
      <FlexboxGrid.Item as={Col} colspan={24} md={24}>
        <Form.Group>
          <Form.ControlLabel>
            {t('profiles.group')}
            {user?.groups_id?.length && '*'}
          </Form.ControlLabel>
          <InputPicker
            data={groups}
            onChange={(v: number) => this.handleGroupChanges(v)}
            value={profile.group_id}
            valueKey='id'
            labelKey='name'
            block
          />
        </Form.Group>
      </FlexboxGrid.Item>
    )
  }

  renderFields() {
    const { fields } = this.state
    if (!fields || !fields.length) return null

    return fields.map(this.renderField)
  }

  renderField = (f: SettingsField) => {
    const { profile } = this.state

    if (profile.data) {
      const value = profile.data[f.name]
      if (value !== undefined) f.value = value
    }

    return (
      <FlexboxGrid.Item
        as={Col}
        colspan={24}
        md={f.data?.md || 12}
        key={f.name}>
        <Form.Group>
          <SettingsInput {...f} onChange={this.handleFieldsChange} />
        </Form.Group>
      </FlexboxGrid.Item>
    )
  }

  renderFooter() {
    const { t } = this.props

    return (
      <Footer className='footer-buttons'>
        <ButtonToolbar></ButtonToolbar>
        <ButtonToolbar justifyContent='flex-end'>
          <Button
            disabled={!this.isAllowSave()}
            onClick={() => this.saveProfile()}
            appearance='primary'>
            {t('profiles.save')}
          </Button>
        </ButtonToolbar>
      </Footer>
    )
  }
}

//
//
//

interface WebcamProps {
  show: boolean
  onHide: () => void
  uploadPhoto: (file: Blob) => Promise<any>
}

interface WebcamState {
  canvas: { w: number; h: number }
  error?: string
  item?: Item
  frame: Blob | null
}

class Webcam extends React.Component<WebcamProps, WebcamState> {
  state = {
    canvas: { w: 0, h: 0 },
  } as WebcamState

  webcam = React.createRef<any>()
  interval = 0

  onWebcamResize = (w?: number, h?: number) => {
    this.setState({ canvas: { w: w || 0, h: h || 0 } })
  }

  componentDidMount() {
    this.interval = window.setInterval(this.sendFrame, 1000)
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  sendFrame = () => {
    const player = this.webcam.current
    if (!player) return

    player.getBlob(this.onFrame)
  }

  onFrame = (frame: Blob | null) => {
    if (!frame) return

    NAP.findFace(frame)
      .then((item: Item) =>
        this.setState({ item: item, frame: frame, error: undefined })
      )
      .catch(this.setError)
  }

  setError = (err: Error) => {
    this.setState({ item: undefined, error: err.message })
  }

  handleCapture = () => {
    const { uploadPhoto, onHide } = this.props
    const { frame, item } = this.state

    if (frame && item) {
      uploadPhoto(frame)
        .then(() => onHide())
        .catch(this.setError)
    }
  }

  //
  // render
  //

  render() {
    const { show, onHide } = this.props
    const { error, canvas, item } = this.state

    const detected = {
      analytics: { id: 0 },
      camera: { id: 0 },
      items: [] as Item[],
    } as Detected

    if (item) detected.items?.push(item)

    return (
      <Modal onClose={() => onHide()} open={show}>
        <Modal.Body>
          <ReactResizeDetector
            handleWidth
            handleHeight
            onResize={this.onWebcamResize}>
            <WEBCAM ref={this.webcam} className='camera-player' />
          </ReactResizeDetector>
          <CameraCanvas
            analytics={{ 0: detected }}
            width={canvas.w}
            height={canvas.h}
            // interaction
          />
        </Modal.Body>

        <Modal.Footer>
          {error && <Message type='error'>{error}</Message>}

          <ButtonToolbar justifyContent='center'>
            <IconButton
              disabled={!item || !item.type}
              onClick={() => this.handleCapture()}
              appearance='primary'
              icon={<FaVideo />}
            />
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default withTranslation()(withRouter(ProfileForm))
