import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Divider,
  Form,
  InputPicker,
  Modal,
} from 'rsuite'

import { SettingsInput, alert, success } from 'content'
import NAP, { AuthSettings, Scheme, SettingsField } from 'nap'

interface Props extends WithTranslation {
  onHide: () => void
}
interface State {
  s: AuthSettings
  schemes: Scheme[]
  ext: SettingsField[]
  extData: Record<string, any>
}

class AuthSettingsForm extends React.Component<Props, State> {
  state = {} as State

  componentDidMount() {
    NAP.authSettings()
      .then((s) => this.setState({ s }))
      .catch(alert)

    NAP.schemes()
      .then((schemes) => this.setState({ schemes }))
      .catch(alert)

    NAP.authExtSettings()
      .then((ext) => {
        console.log(ext)
        this.setState({ ext })
      })
      .catch(alert)
  }

  success = () => {
    const { t } = this.props
    success(t('success'))
    this.props.onHide()
  }

  //
  // handlers
  //

  handleInput = (val: any, key: string) => {
    const { s } = this.state
    Object.assign(s, { [key]: val })

    this.setState({ s })
  }

  handleSubmit = () => {
    let { s, ext, extData } = this.state

    if (extData || ext) {
      if (!extData && ext) {
        extData = {} as Record<string, any>
        ext.forEach((sf) => {
          if (sf.value) extData[sf.name] = sf.value
        })
      }
      NAP.changeAuthExtSettings(extData).catch(alert)
    }

    NAP.changeAuthSettings(s).then(this.success).catch(alert)
  }

  handleExternal = (sf: SettingsField, val: any, key: string) => {
    let { extData } = this.state
    if (!extData) extData = {} as Record<string, any>

    const found = sf.name.match(/(.+?)\[(\d+)\]/)
    if (found && found.length === 3) {
      const rowKey = found[1]
      const index = parseInt(found[2])
      if (!extData[rowKey]) extData[rowKey] = [] as any[]
      if (!extData[rowKey][index]) extData[rowKey][index] = {}
      extData[rowKey][index][key] = val

      sf = (sf.data as SettingsField[]).find((sf) => sf.name === key)!
      sf.value = val
    } else {
      extData[key] = val
      sf.value = val
    }

    this.setState({ extData })
  }

  //
  // render
  //

  render() {
    const { t, onHide } = this.props
    const { s, schemes } = this.state

    return (
      <Modal onClose={() => onHide()} size='md' open>
        <Modal.Header>
          <h5>{t('users.settings.title')}</h5>
        </Modal.Header>

        <Form onSubmit={this.handleSubmit} fluid>
          {s && schemes && (
            <div>
              <Form.Group>
                <ButtonGroup block justified>
                  <Button
                    appearance={s.faceauth ? 'primary' : 'default'}
                    onClick={() => this.handleInput(!s.faceauth, 'faceauth')}>
                    {t('users.settings.faceauth')}
                  </Button>
                  <Button
                    appearance={s.faceauth_withcreds ? 'primary' : 'default'}
                    onClick={() =>
                      this.handleInput(
                        !s.faceauth_withcreds,
                        'faceauth_withcreds'
                      )
                    }>
                    {t('users.settings.faceauth_withcreds')}
                  </Button>
                </ButtonGroup>
              </Form.Group>

              <Form.Group>
                <Form.ControlLabel>
                  {t('users.settings.auth_analytics')}
                </Form.ControlLabel>
                <InputPicker
                  value={s.faceauth_analytics}
                  data={schemes}
                  labelKey='name'
                  valueKey='id'
                  onChange={(v) => this.handleInput(v, 'faceauth_analytics')}
                  cleanable
                  block
                />
              </Form.Group>

              <Divider />
            </div>
          )}

          {this.renderExternal()}
        </Form>

        <Modal.Footer>
          <ButtonToolbar justifyContent='flex-end' className='footer'>
            <Button onClick={onHide}>{t('cancel')}</Button>
            <Button appearance='primary' onClick={this.handleSubmit}>
              {t('save')}
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }

  renderExternal() {
    const { ext } = this.state
    if (!ext || !ext.length) return null

    return (
      <div>
        {ext.map((sf) => {
          if (sf.default && !sf.value) sf.value = sf.default

          return (
            <SettingsInput
              {...sf}
              className='settingsinput'
              onChange={(val, key) => this.handleExternal(sf, val, key)}
            />
          )
        })}
      </div>
    )
  }
}

export default withTranslation()(AuthSettingsForm)
