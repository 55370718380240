import React from 'react'
import { FaChevronLeft, FaChevronRight, FaVideo } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import { Modal, Tag } from 'rsuite'

import { alert } from 'content'
import moment from 'moment-timezone'
import ROUTES from 'routes'
import NAP, { Event, User } from 'nap'
import EventsImage from './eventsimage'

interface ModalProps {
  events?: Event[]
  event: Event
  imgSrc: string
  onHide?: () => void
  onDelete?: () => void
  user?: User
}

interface ModalState {
  iter: number
  event?: Event
}

export default class EventsModalImage extends React.Component<
  ModalProps,
  ModalState
> {
  state = {} as ModalState

  componentDidMount() {
    const { event } = this.props

    if (typeof event.time_at === 'string') {
      event.time_at = moment(event.time_at)
    }
    if (typeof event.time_to === 'string') {
      event.time_at = moment(event.time_to)
    }

    document.addEventListener('keydown', this.handleKeys)
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.handleKeys)
  }

  handleKeys = (e: any) => {
    switch (e.key) {
      case 'ArrowRight':
        return this.handleMove(-1)
      case 'ArrowLeft':
        return this.handleMove(1)
      case 'a':
        return this.handleMove(1)
      case 'd':
        return this.handleMove(-1)
    }
  }

  handleMove = (step: number) => {
    const { events } = this.props
    let iter = this.state.iter
    let event = this.getEvent()

    if (!events) return

    if (iter === undefined) {
      iter = events.findIndex((e) => e.id === event.id)
      console.log(iter)
      if (iter === undefined) return
    }

    iter += step
    event = events[iter]
    if (!event) return

    this.setState({ event, iter })
  }

  handleDelete = () => {
    const { events, onDelete } = this.props
    if (!events) return

    const event = this.getEvent()
    NAP.deleteEvents([event.id])
      .then(() => {
        const iter = events.findIndex((e) => e.id === event.id)

        // eslint-disable-next-line
        this.state.iter = iter
        this.handleMove(1)

        if (iter > -1) events.splice(iter, 1)
        if (onDelete) onDelete()
      })
      .catch(alert)
  }

  getEvent = (): Event => {
    return this.state.event || this.props.event
  }

  getImageProps = () => {
    const def = {
      user: this.props.user,
      onDelete: this.handleDelete,
    }

    if (this.state.event) {
      return { event: this.state.event, ...def } as ModalProps
    }

    const { event, imgSrc } = this.props
    return { event, imgSrc, ...def } as ModalProps
  }

  render() {
    const { onHide } = this.props
    const event = this.getEvent()
    if (!event) return

    if (typeof event.time_at === 'number') {
      event.time_at = moment(event.time_at)
    }
    if (typeof event.time_to === 'number') {
      event.time_at = moment(event.time_to)
    }

    return (
      <Modal
        key='event_modal'
        className='event-image-expanded'
        overflow={false}
        onClose={() => onHide && onHide()}
        size='lg'
        open>
        <Modal.Header>
          <Modal.Title>
            <div className='event-image-title'>
              <span className='events-date-subtitle'>
                {event.time_at.format('HH:mm:ss DD MMMM YYYY')}
              </span>
              <Link
                onClick={onHide}
                to={`${ROUTES.livestream}?camera=${event.camera_id}`}>
                <FaVideo /> {event.camera}
              </Link>
              {event.sector && <Tag>{event.sector}</Tag>}
            </div>
          </Modal.Title>
        </Modal.Header>
        <EventsImage {...this.getImageProps()} />
        {this.renderPrevBtn()}
        {this.renderNextBtn()}
      </Modal>
    )
  }

  renderPrevBtn() {
    const { events } = this.props
    const event = this.getEvent()
    if (!events || !events.length) return null
    if (events[events.length - 1].id === event.id) return null

    return (
      <div
        className='events-movebtn events-prevbtn'
        onClick={() => this.handleMove(1)}>
        <FaChevronLeft />
      </div>
    )
  }

  renderNextBtn() {
    const { events } = this.props
    const event = this.getEvent()
    if (!events || !events.length) return null
    if (events[0].id === event.id) return null

    return (
      <div
        className='events-movebtn events-nextbtn'
        onClick={() => this.handleMove(-1)}>
        <FaChevronRight />
      </div>
    )
  }
}
