import { Icon } from '@rsuite/icons'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FaCopy, FaExclamationCircle, FaQuestionCircle } from 'react-icons/fa'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  ButtonToolbar,
  Checkbox,
  Col,
  Divider,
  Footer,
  Form,
  Grid,
  IconButton,
  Input,
  InputGroup,
  InputNumber,
  InputPicker,
  Message,
  Panel,
  Radio,
  RadioGroup,
  Row,
  Stack,
  Tag,
  TagPicker,
  Tooltip,
  Whisper,
} from 'rsuite'

import Content, {
  CenterRow,
  CheckButtonGroup,
  ContentState,
  Errors,
  Header,
  HeaderLeft,
  HeaderTitle,
  SettingsInput,
  alert,
  setTitle,
  success,
} from 'content'
import { Translate } from 'i18n'
import path from 'path'
import ROUTES from 'routes'
import NAP, { Notification, NotifyContact, SettingsField } from 'nap'

const TYPE_SMTP = 'smtp'
const TYPE_IMAP = 'imap'
const TYPE_Telegram = 'telegram'
const TYPE_HTTP = 'http'
const TYPE_ICQ = 'icq'
const TYPE_POPUP = 'popup'

interface RouteParams {
  id?: string
}
interface Props extends WithTranslation, RouteComponentProps<RouteParams> {}
interface State extends ContentState {
  title: string
  ntf: Notification
  type: string

  inSave: boolean
  inCancel: boolean
  inToggle: boolean
  inCheck: boolean
}

class NotificationForm extends React.Component<Props, State> {
  state = {
    ntf: {
      name: '',
      desc: '',
      recipients: [] as string[],
      client_values: {} as any,
      status: {},
    } as Notification,
    type: '',
  } as State

  translate = new Translate('notifications')

  constructor(props: Props) {
    super(props)
    this.state.ntf.id = parseInt(props.match.params.id || '0') || 0
  }

  componentDidMount() {
    const { t } = this.props
    const { ntf } = this.state

    setTitle('notifications.title')

    if (ntf.id > 0) {
      this.loadNotification()
    } else {
      this.setState({
        loaded: true,
        title: t('notifications.add_notification'),
      })
    }
  }

  componentDidUpdate() {
    const sid = window.location.pathname.split('/').pop()
    if (!sid || sid === 'new') return

    const id = parseInt(sid)
    if (this.state.ntf.id !== id) {
      this.state.ntf.id = id
      this.loadNotification()
    }
  }

  loadNotification = () => {
    const { ntf, loaded } = this.state

    if (loaded) {
      this.setState({ inCancel: true })
    }

    NAP.notification(ntf.id).then(this.setNotification).catch(this.setError)
  }

  setNotification = (ntf: Notification) => {
    setTitle('notifications.title', ntf.name)

    if (!ntf.recipients) ntf.recipients = []
    if (!ntf.client_values) ntf.client_values = {}

    this.setState({
      ntf: ntf,
      type: ntf.type,
      title: ntf.name,
      loaded: true,
      inSave: false,
      inCancel: false,
      inToggle: false,
      inCheck: false,
    })
  }

  setError = (err: Error) => {
    const { t } = this.props
    const { title } = this.state

    this.setState({
      error: err.message,
      title: title || t('title'),
      loaded: true,
      inSave: false,
      inCancel: false,
      inToggle: false,
      inCheck: false,
    })
  }

  alert = (err: Error) => {
    alert(err.message)
    console.error(err.message)

    this.setState({
      inSave: false,
      inCancel: false,
      inToggle: false,
      inCheck: false,
    })
  }

  loadRecipientsList = () => {
    const { ntf } = this.state
    if (!ntf || !ntf.id) return

    NAP.notification(ntf.id)
      .then((n) => {
        ntf.status = n.status
        this.setState({})
      })
      .catch(this.setError)
  }

  getSettings = (key: string, ntf?: Notification): SettingsField => {
    ntf = ntf || this.state.ntf

    let s = { name: key } as SettingsField

    if (ntf.status.variables) {
      s =
        ntf.status.variables.find(
          (s: SettingsField) => s.name === key || s.label?.toLowerCase() === key
        ) || s
    }

    if (ntf.client_values) {
      s.value = ntf.client_values[key] || s.value
    }

    return s
  }

  getContacts = (): NotifyContact[] => {
    const { ntf } = this.state

    const contacts = ntf.status.contacts || ([] as NotifyContact[])

    ntf.recipients?.forEach((v) => {
      if (contacts.some((c) => c.value === v)) return
      contacts.push({ value: v, name: v } as NotifyContact)
    })

    contacts.sort((a: NotifyContact, b: NotifyContact) => {
      if (a.name > b.name) return 1
      if (a.name === b.name && a.value > b.value) return 1
      return -1
    })

    return contacts
  }

  //
  // form handlers
  //

  handleToggle = () => {
    this.setState({ inToggle: true })

    NAP.toggleNotification(this.state.ntf.id)
      .then(this.setNotification)
      .catch(this.alert)
  }

  handleSave = () => {
    const { ntf } = this.state

    this.setState({ inSave: true })

    const { id, errors, status, ...data } = ntf

    data.resize = parseInt(data.resize as any)
    if (isNaN(data.resize)) data.resize = 0
    ntf.resize = data.resize

    if (data.client_values?.headers) {
      data.client_values.headers = data.client_values.headers.filter(
        (s) => s.trim().length
      )
    }

    let resp: Promise<Notification>
    if (ntf.id > 0) {
      resp = NAP.changeNotification(ntf.id, data as Notification)
    } else {
      ntf.enabled = true
      resp = NAP.createNotification(ntf as Notification)
    }

    resp
      .then((ntf) => {
        this.setNotification(ntf)
        const url = `${ROUTES.settings.notifications}/${ntf.id}`
        window.history.replaceState(ntf, '', url)
      })
      .catch(this.alert)
  }

  handleCheck = () => {
    const { t } = this.props
    const { ntf } = this.state

    this.setState({ inCheck: true })

    NAP.checkNotification(ntf.id)
      .then(() => {
        success(t('notifications.check_success'))
        this.setState({ inCheck: false })
      })
      .catch(this.alert)
  }

  handleInput = (value: any, key: string) => {
    const { ntf } = this.state
    Object.assign(ntf, { [key]: value })
    this.setState({ ntf })
  }

  handleForm = (p: Record<string, any>, e: any) => {
    const ntf = Object.assign(this.state.ntf, p)

    this.setState({ ntf })
  }

  handleParams = (val: any, key: string) => {
    const { ntf } = this.state

    if (ntf.status.variables) {
      const s = ntf.status.variables.find((s: SettingsField) => s.name === key)
      if (s) s.value = val
    }

    ntf.client_values[key] = val
    this.setState({})
  }

  handleCopy = () => {
    const { t } = this.props
    const { ntf } = this.state

    ntf.id = 0
    ntf.name = ''
    ntf.errors = null
    ntf.client_values = null
    ntf.status.variables = null
    ntf.status.contacts = null

    this.setState({
      ntf,
      title: t('notifications.add_notification'),
    })

    const urlpath = path.join(ROUTES.settings.notifications, 'new')
    window.history.pushState(ntf, 'notifications', urlpath)
  }

  handleChangeHeaders = (val: any, index: number) => {
    const { ntf } = this.state

    if (!ntf.client_values.headers) ntf.client_values.headers = []
    ntf.client_values.headers[index] = val

    this.setState({})
  }

  //
  // render
  //

  render() {
    const { t } = this.props
    const { ntf } = this.state
    const contacts = this.getContacts()

    return (
      <Content {...this.state} header={this.renderHeader()}>
        <CenterRow>
          {ntf.errors && <Errors value={ntf.errors} />}

          <Panel>
            <Form formValue={ntf} onChange={this.handleForm} fluid>
              <Grid fluid>
                <Row>
                  <Col md={24}>
                    <Form.Group>
                      <Form.ControlLabel>{t('name')}*</Form.ControlLabel>
                      <InputGroup>
                        <Form.Control name='name' />
                        <Whisper
                          placement='top'
                          trigger='hover'
                          speaker={
                            <Tooltip>{t('notifications.system_help')}</Tooltip>
                          }>
                          <InputGroup.Button
                            data-checked={ntf.system}
                            onClick={() =>
                              this.handleInput(!ntf.system, 'system')
                            }>
                            <FaExclamationCircle />
                          </InputGroup.Button>
                        </Whisper>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={24}>
                    <Form.Group>
                      <Form.ControlLabel>{t('desc')}</Form.ControlLabel>
                      <Form.Control name='desc' />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={24}>
                    <Form.Group>
                      <Form.ControlLabel>{t('type')}*</Form.ControlLabel>
                      <RadioGroup
                        as={Stack}
                        className='form-type-selector'
                        appearance='picker'
                        justifyContent='center'
                        inline
                        onChange={(v) => this.handleInput(v, 'type')}
                        value={ntf.type}>
                        <Radio value={TYPE_SMTP}>{TYPE_SMTP}</Radio>
                        <Radio value={TYPE_IMAP}>{TYPE_IMAP}</Radio>
                        <Radio value={TYPE_Telegram}>{TYPE_Telegram}</Radio>
                        <Radio value={TYPE_ICQ}>{TYPE_ICQ}</Radio>
                        <Radio value={TYPE_HTTP}>{TYPE_HTTP}</Radio>
                        <Radio value={TYPE_POPUP}>{TYPE_POPUP}</Radio>
                      </RadioGroup>
                    </Form.Group>
                  </Col>
                </Row>

                {this.renderClient()}

                <Divider />

                {ntf.type !== TYPE_POPUP && (
                  <Row>
                    <Col md={24}>
                      <Form.Group>
                        <Form.ControlLabel>
                          {t('notifications.recipients')}
                        </Form.ControlLabel>
                        <TagPicker
                          data={contacts}
                          value={ntf.recipients || []}
                          labelKey='name'
                          valueKey='value'
                          creatable={true}
                          cleanable={false}
                          onOpen={this.loadRecipientsList}
                          onChange={(v: string[]) =>
                            this.handleInput(v, 'recipients')
                          }
                          renderMenuItem={(label: any, item: any) => (
                            <div>
                              {item.name || item.value}
                              {!item.name ||
                                (item.name !== item.value && (
                                  <Tag>{item.value}</Tag>
                                ))}
                            </div>
                          )}
                          renderValue={(value: any[], items: any) => {
                            return items.map((item: any) => (
                              <Tag key={item.value}>
                                {item.name || item.value}
                              </Tag>
                            ))
                          }}
                          block
                        />
                      </Form.Group>
                    </Col>
                  </Row>
                )}

                <Row>
                  <Col md={24}>
                    <Form.Group>
                      <Form.ControlLabel>
                        {t('notifications.subject')}
                      </Form.ControlLabel>
                      <Form.Control name='subject' />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={24}>
                    <Form.Group>
                      <Form.ControlLabel>
                        {t('notifications.text')}
                        <a
                          href='https://golang.org/pkg/text/template/#hdr-Actions'
                          target='_blank'
                          rel='noopener noreferrer'>
                          <FaQuestionCircle />
                        </a>
                      </Form.ControlLabel>
                      <Input
                        as='textarea'
                        rows={3}
                        style={{ resize: 'vertical' }}
                        value={ntf.text}
                        onChange={(v) => this.handleInput(v, 'text')}
                      />
                    </Form.Group>
                  </Col>
                </Row>

                <Row>
                  <Col md={12}>
                    <Form.Group>
                      <Form.ControlLabel>
                        {t('notifications.resize')}
                      </Form.ControlLabel>
                      <InputGroup>
                        <InputNumber
                          value={ntf.resize}
                          onChange={(v) => this.handleInput(v, 'resize')}
                          min={0}
                          max={4096}
                          step={256}
                        />
                        <InputGroup.Addon>px</InputGroup.Addon>
                      </InputGroup>
                    </Form.Group>
                  </Col>
                  <Col md={12}>
                    <Form.ControlLabel>&nbsp;</Form.ControlLabel>
                    <Form.Group>
                      <Checkbox
                        checked={ntf.draw || false}
                        onChange={() => this.handleInput(!ntf.draw, 'draw')}>
                        {t('notifications.draw')}
                      </Checkbox>
                    </Form.Group>
                  </Col>
                </Row>
                <Row>{this.renderFooter()}</Row>
              </Grid>
            </Form>
          </Panel>
        </CenterRow>
      </Content>
    )
  }

  renderClient() {
    const { t } = this.props
    const { ntf } = this.state

    switch (ntf.type) {
      case '':
      case undefined:
        return ''
      case TYPE_SMTP:
        return this.renderSMTP()
      case TYPE_IMAP:
        return this.renderIMAP()
      case TYPE_Telegram:
        return this.renderTelegram()
      case TYPE_ICQ:
        return this.renderICQ()
      case TYPE_HTTP:
        return this.renderHTTP()
      case TYPE_POPUP:
        return this.renderPOPUP()
      default:
        return <Message>{t('client.unsupported')}</Message>
    }
  }

  renderSMTP() {
    const { t } = this.translate
    const { ntf } = this.state

    return (
      <Row key='smtp'>
        <Col md={24}>
          <Form.Group>
            <Form.ControlLabel>{t('client.addr')}</Form.ControlLabel>
            {this.renderInput('addr', { placeholder: 'smtp.gmail.com:465' })}
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group>
            <Form.ControlLabel>{t('client.user')}</Form.ControlLabel>
            {this.renderInput('user')}
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group>
            <Form.ControlLabel>{t('client.pass')}</Form.ControlLabel>
            {this.renderInput('pass', { type: 'password' })}
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group>
            <Form.ControlLabel>{t('client.security')}</Form.ControlLabel>
            <CheckButtonGroup
              data={ntf.client_values}
              name='security'
              buttons={[
                { value: '', text: 'NO' },
                { value: 'ssl', text: 'SSL' },
                { value: 'tls', text: 'TLS' },
              ]}
              onClick={this.handleParams}
            />
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group>
            <Form.ControlLabel>{t('client.auth')}</Form.ControlLabel>
            <CheckButtonGroup
              data={ntf.client_values}
              name='auth'
              buttons={[
                { value: 'plain', text: 'PLAIN' },
                { value: 'login', text: 'LOGIN' },
              ]}
              onClick={this.handleParams}
            />
          </Form.Group>
        </Col>
      </Row>
    )
  }

  renderIMAP() {
    const { t } = this.translate
    const { ntf } = this.state

    return (
      <Row key='imap'>
        <Col md={24}>
          <Form.Group>
            <Form.ControlLabel>{t('client.addr')}</Form.ControlLabel>
            {this.renderInput('addr', { placeholder: 'smtp.gmail.com:465' })}
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group>
            <Form.ControlLabel>{t('client.user')}</Form.ControlLabel>
            {this.renderInput('user')}
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group>
            <Form.ControlLabel>{t('client.pass')}</Form.ControlLabel>
            {this.renderInput('pass', { type: 'password' })}
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group>
            <Form.ControlLabel>{t('client.security')}</Form.ControlLabel>
            <CheckButtonGroup
              data={ntf.client_values}
              name='security'
              buttons={[
                { value: 'tls', text: 'TLS' },
                { value: 'starttls', text: 'StartTLS' },
              ]}
              onClick={this.handleParams}
            />
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group>
            <Form.ControlLabel>{t('client.folder')}</Form.ControlLabel>
            {this.renderInput('folder')}
          </Form.Group>
        </Col>
      </Row>
    )
  }

  renderTelegram() {
    const { t } = this.translate

    const delMsg = this.getSettings('delete_messages_timeout')

    return (
      <Row key='telegram'>
        <Col md={24}>
          <Form.Group>
            <Form.ControlLabel>{t('client.token')}</Form.ControlLabel>
            <InputGroup>
              {this.renderInput('token')}
              {this.renderBotName()}
            </InputGroup>
          </Form.Group>
        </Col>

        <Col md={24}>
          <Form.Group>
            <Form.ControlLabel>
              {t('client.delete_messages_timeout')}
            </Form.ControlLabel>
            <InputGroup>
              <InputNumber
                value={delMsg ? delMsg.value : 0}
                min={0}
                step={1}
                onChange={(v: any) =>
                  this.handleParams(v, 'delete_messages_timeout')
                }
              />
              <InputGroup.Addon>{this.props.t('sec')}</InputGroup.Addon>
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
    )
  }

  renderICQ() {
    const { t } = this.translate

    return (
      <Row key='icq'>
        <Col md={24}>
          <Form.Group>
            <Form.ControlLabel>{t('client.token')}</Form.ControlLabel>
            <InputGroup>
              {this.renderInput('token')}
              {this.renderBotName()}
            </InputGroup>
          </Form.Group>
        </Col>
      </Row>
    )
  }

  renderHTTP() {
    const { t } = this.translate
    const { ntf } = this.state

    const fields = ntf.status.variables
    const headers = ntf.client_values.headers || []
    if (!headers.length || headers[headers.length - 1]) headers.push('')

    return (
      <Row key='http'>
        <Col md={24}>
          <Form.Group>
            <Form.ControlLabel>{t('client.addr')}</Form.ControlLabel>
            <InputGroup>
              {this.renderInput('addr', { placeholder: '127.0.0.1:8123' })}
              {this.renderBotName()}
            </InputGroup>
          </Form.Group>
        </Col>
        <Col md={24}>
          <Form.Group>
            <Form.ControlLabel>{t('client.headers')}</Form.ControlLabel>
            <div>
              {headers.map((h, i) => (
                <InputGroup key={i}>
                  <Input
                    value={h}
                    onChange={(v) => this.handleChangeHeaders(v, i)}
                    placeholder='Authorization: ...'
                  />
                </InputGroup>
              ))}
            </div>
          </Form.Group>
        </Col>
        {fields?.map(this.renderSettingsField)}
      </Row>
    )
  }

  renderPOPUP() {
    const { t } = this.translate

    const timeout = this.getSettings('timeout')
    const confirmation = this.getSettings('confirmation')
    const position = this.getSettings('position')

    const positions = [
      'topStart',
      'topCenter',
      'topEnd',
      'bottomStart',
      'bottomCenter',
      'bottomEnd',
    ].map((v) => ({ label: v, value: v }))

    return (
      <Row key='popup'>
        <Col md={12}>
          <Form.Group>
            <Form.ControlLabel>{t('client.position')}</Form.ControlLabel>
            <InputPicker
              data={positions}
              value={position.value || 'topCenter'}
              onChange={(v) => this.handleParams(v, 'position')}
              cleanable={false}
              block
            />
          </Form.Group>
        </Col>
        <Col md={12}>
          <Form.Group>
            <Form.ControlLabel>{t('client.timeout')}</Form.ControlLabel>
            <InputGroup>
              <InputNumber
                value={timeout.value}
                onChange={(v) => this.handleParams(v, 'timeout')}
              />
              <InputGroup.Addon>ms</InputGroup.Addon>
            </InputGroup>
          </Form.Group>
        </Col>
        {/* <Col md={8}>
          <Form.Group>
            <Form.ControlLabel>&nbsp;</Form.ControlLabel>
            <Checkbox
              checked={parseBool(confirmation.value)}
              onChange={() =>
                this.handleParams(!confirmation.value, 'confirmation')
              }>
              {t('client.confirm')}
            </Checkbox>
          </Form.Group>
        </Col> */}
      </Row>
    )
  }

  renderSettingsField = (s: SettingsField, i: number) => {
    if (s.label?.toLowerCase() === 'bot name') return
    if (s.label?.toLowerCase() === 'contacts') return

    const { t } = this.translate

    let label = s.name
    if (this.translate.exists(`client.${s.name}`)) {
      label = t(`client.${s.name}`)
    }
    if (this.translate.exists(`client.${s.label}`)) {
      label = t(`client.${s.label}`)
    }

    return (
      <Col key={i} md={s.width || 24}>
        <Form.Group>
          <SettingsInput {...s} onChange={this.handleParams} />
        </Form.Group>
      </Col>
    )
  }

  renderInput(key: string, opt?: any) {
    const s = this.getSettings(key)
    return (
      <Input
        value={s.value}
        onChange={(v: any) => this.handleParams(v, key)}
        {...opt}
      />
    )
  }

  renderBotName = () => {
    const s = this.getSettings('bot name')
    if (!s || !s.value) return ''

    if (s.type === 'html')
      return (
        <InputGroup.Addon>
          <Tag color='blue'>
            <div dangerouslySetInnerHTML={{ __html: s.value || '' }}></div>
          </Tag>
        </InputGroup.Addon>
      )

    return (
      <InputGroup.Addon>
        <Tag color='blue'>@{s.value}</Tag>
      </InputGroup.Addon>
    )
  }

  renderHeader() {
    const { ntf, title } = this.state

    return (
      <Header left={<HeaderLeft back={ROUTES.settings.notifications} />}>
        <HeaderTitle {...ntf}>{title}</HeaderTitle>
      </Header>
    )
  }

  renderFooter() {
    const { t } = this.props
    const { ntf, inSave, inCancel, inToggle, inCheck } = this.state

    return (
      <Footer className='footer-buttons'>
        <ButtonToolbar align='left'>
          {ntf.id > 0 && (
            <Button
              appearance='primary'
              color={ntf.enabled ? 'orange' : 'green'}
              onClick={this.handleToggle}
              loading={inToggle}>
              {t(ntf.enabled ? 'disable' : 'enable')}
            </Button>
          )}
        </ButtonToolbar>

        <ButtonToolbar justifyContent='flex-end'>
          {ntf.id > 0 && (
            <Button
              onClick={this.handleCheck}
              loading={inCheck}
              disabled={
                ntf.type !== TYPE_POPUP &&
                (!ntf.recipients || !ntf.recipients.length)
              }>
              {t('notifications.check')}
            </Button>
          )}
          {ntf.id > 0 && (
            <Button onClick={this.loadNotification} loading={inCancel}>
              {t('cancel')}
            </Button>
          )}
          <ButtonGroup>
            <Button
              type='submit'
              appearance='primary'
              disabled={ntf.name === '' || !ntf.type}
              onClick={this.handleSave}
              loading={inSave}>
              {t('save')}
            </Button>
            <IconButton onClick={this.handleCopy} icon={<Icon as={FaCopy} />} />
          </ButtonGroup>
        </ButtonToolbar>
      </Footer>
    )
  }
}

export default withTranslation()(withRouter(NotificationForm))
