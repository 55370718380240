import queryString from 'query-string'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { RouteComponentProps, withRouter } from 'react-router-dom'
import {
  Button,
  ButtonGroup,
  CheckPicker,
  CheckTreePicker,
  Col,
  FlexboxGrid,
  Footer,
  IconButton,
  Message,
} from 'rsuite'

import Content, { ContentState, HeaderSearch, setTitle } from 'content'
import { FaAngleLeft, FaAngleRight } from 'react-icons/fa'
import NAP, { Camera, Detected, Scheme } from 'nap'
import CameraPlayer from './cameraplayer'

const opt = { parseNumbers: true }

interface RouteParams {
  sector?: string
  heatmap?: any
}
interface Props extends WithTranslation, RouteComponentProps<RouteParams> {}
interface State extends ContentState {
  sector?: string
  // expand?: Camera
  selected: number[]

  cameras: Camera[]
  sectors: string[]
  schemes: Scheme[]
  filterAnalytics: number[]

  cols: number
  page: number
  pageCapacity: number
}

class LiveStream extends React.Component<Props, State> {
  state = {
    selected: [] as number[],
    cols: 2,
    page: 0,
    pageCapacity: 6,
  } as State

  componentDidMount() {
    setTitle('livestream.title')

    const q = queryString.parse(window.location.search, opt)

    let id = q.camera as any
    if (typeof id == 'number' && id > 0) {
      // eslint-disable-next-line
      this.state.selected = [id] // no need to redraw
    } else if (typeof id == 'object') {
      // eslint-disable-next-line
      this.state.selected = id
    }

    if (q.page) {
      // eslint-disable-next-line
      this.state.page = Number(q.page as any) - 1
      // eslint-disable-next-line
      if (this.state.page < 0) this.state.page = 0
    }

    // this.parseQueryHeatmap()
    this.loadCameras()

    NAP.schemes().then((schemes) => this.setState({ schemes }))
  }

  componentDidUpdate() {
    let id = queryString.parse(window.location.search, opt).camera as any
    if (typeof id == 'number' && id > 0) {
      if (this.state.selected.length === 1 && this.state.selected[0] === id)
        return

      this.setState({ selected: [id] })
    } else {
      id = id || []
      if (JSON.stringify(this.state.selected) !== JSON.stringify(id)) {
        this.setState({ selected: id })
      }
    }

    // if current sector not equal sector from url path then load cameras
    const sector = this.props.match.params.sector
    if (this.state.sector !== sector) {
      this.setState({ sector: sector })
    }
  }

  loadCameras() {
    NAP.cameras()
      .then(this.setCameras)
      .catch((err) => this.setState({ error: err.message, loaded: true }))
  }

  setCameras = (cameras: Camera[]) => {
    const sector = this.props.match.params.sector || undefined

    if (!cameras) cameras = []
    cameras = cameras.filter((cam) => cam.enabled)

    const sectors = cameras
      .map((cam: Camera) => cam.sector)
      .filter((s: string) => s !== '')

    let { page, pageCapacity } = this.state
    if (Math.ceil(cameras.length / pageCapacity) <= page) {
      const q = queryString.parse(window.location.search, opt)
      q.page = '1'
      window.history.pushState('', `page:1`, '?' + queryString.stringify(q))

      page = 0
    }

    this.setState({
      cameras: cameras || [],
      sectors: Array.from(new Set(sectors)),
      loaded: true,
      sector,
      page,
    })
  }

  setPage = (page: number) => {
    const q = queryString.parse(window.location.search, opt)
    q.page = (page + 1).toString()
    window.history.pushState(
      '',
      `page:${page + 1}`,
      '?' + queryString.stringify(q)
    )

    this.setState({ page: page })
  }

  handleSearch = (search: string) => {
    this.setState({ search })
  }

  expandCamera = (cam: Camera) => {
    if (this.state.selected.length === 1) {
      window.history.pushState('', cam.name, `?`)
      this.setState({ selected: [] })
    } else {
      window.history.pushState('', cam.name, `?camera=${cam.id}`)
      this.setState({ selected: [cam.id] })
    }
  }

  selectCameras = (id: number[] | any) => {
    const { sectors, cameras } = this.state
    const selected: number[] = []

    id.forEach((i: number) => {
      if (i < 0) {
        const sector = sectors[Math.abs(i + 1)]
        cameras.forEach((cam: Camera) => {
          if (cam.sector && cam.sector === sector) {
            selected.push(cam.id)
          }
        })
      }

      selected.push(i)
    })

    const urlq = selected.map((id: number) => `camera=${id}`).join('&')
    window.history.pushState('', selected.join(','), `?` + urlq)

    this.setState({ selected: selected, search: '' })
  }

  selectAnalytics = (id: number[], e: any) => {
    this.setState({ filterAnalytics: id })
  }

  filterAnalytics = (d: Detected): boolean => {
    const { filterAnalytics } = this.state
    if (!filterAnalytics || !filterAnalytics.length) return true

    return filterAnalytics.some((aID: number) => d.analytics.id === aID)
  }

  getCamerasList = (): any => {
    const { sectors, cameras } = this.state
    if (!sectors || !sectors.length) return cameras

    const list = sectors.map((sector: string, i: number) => {
      return {
        id: -i - 1, // negative id for sectors
        name: sector,
        children: cameras.filter((cam: Camera) => cam.sector === sector),
      }
    })

    // append cameras without sector
    cameras.forEach((cam: Camera) => {
      if (!cam.sector) list.push(cam as any)
    })

    return list
  }

  getData = (): Camera[] => {
    const { page, pageCapacity, search, selected, sector } = this.state

    let cameras = this.state.cameras || ([] as Camera[])

    if (selected.length) {
      return cameras.filter((cam: Camera) => selected.includes(cam.id))
    }

    if (sector) {
      cameras = cameras.filter((cam: Camera) => cam.sector === sector)
    }

    if (search) {
      cameras = cameras.filter((cam: Camera) => {
        return (
          cam.name.includes(search) ||
          cam.desc.includes(search) ||
          cam.sector.includes(search) ||
          cam.addr.includes(search)
        )
      })
    }

    if (cameras.length < pageCapacity) return cameras

    let at = pageCapacity * page
    let to = Math.min(at + pageCapacity, cameras.length)

    return cameras.slice(at, to)
  }

  //
  // render
  //

  render() {
    const { t } = this.props
    const { loaded, error, selected } = this.state
    const cameras = this.getData()

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        <div
          className='livestream'
          data-columns={cameras?.length > 1 ? '2' : '1'}>
          {cameras.map((cam: Camera) =>
            this.renderCameraCard(cam, cameras.length)
          )}
          {!cameras.length && (
            <FlexboxGrid.Item key='msg' as={Col} className='livestream-camera'>
              <Message type='info'>{t('livestream.noone')}</Message>
            </FlexboxGrid.Item>
          )}
        </div>

        {selected.length === 0 && this.renderPagination()}
      </Content>
    )
  }

  renderHeader() {
    const { t } = this.props
    const { search, schemes, selected } = this.state

    return (
      <HeaderSearch
        onSearch={this.handleSearch}
        value={search}
        left={
          <CheckTreePicker
            placeholder={t('cameras.title')}
            data={this.getCamerasList() || []}
            value={selected}
            onChange={this.selectCameras}
            labelKey='name'
            valueKey='id'
            style={{ width: 200 }}
          />
        }
        right={
          schemes &&
          schemes.length > 0 && (
            <CheckPicker
              placeholder={t('analytics.title')}
              data={schemes || []}
              onChange={this.selectAnalytics}
              labelKey='name'
              valueKey='id'
              placement='bottomEnd'
              searchable={false}
              style={{ width: 200 }}
            />
          )
        }
      />
    )
  }

  renderCameraCard(cam: Camera, len: number) {
    if (!cam.enabled) return null
    const { selected } = this.state
    const expanded = selected?.length == 1 && selected[0] === cam.id

    return (
      <div key={cam.id} className='livestream-camera'>
        <div className='camera'>
          <CameraPlayer
            camera={cam}
            onDetected={this.filterAnalytics}
            onExpand={() => this.expandCamera(cam)}
            expanded={expanded}
            header={{
              edit: true,
              open: false,
              events: true,
            }}
            withHeatmap
          />
        </div>
      </div>
    )
  }

  renderPagination() {
    const { cameras, page, pageCapacity } = this.state

    if (!cameras || cameras.length < pageCapacity) return ''

    const pagesCount = Math.ceil(cameras.length / pageCapacity)
    const pages = Array.from({ length: pagesCount }, (v, k) => k)

    return (
      <Footer className='content-footer'>
        <ButtonGroup>
          <IconButton
            disabled={page <= 0}
            onClick={() => this.setPage(page - 1)}
            icon={<FaAngleLeft />}
          />
          {pages.map((p) => (
            <Button key={p} active={p === page} onClick={() => this.setPage(p)}>
              {p + 1}
            </Button>
          ))}
          <IconButton
            disabled={page >= pagesCount - 1}
            onClick={() => this.setPage(page + 1)}
            icon={<FaAngleRight />}
          />
        </ButtonGroup>
      </Footer>
    )
  }
}

export default withTranslation()(withRouter(LiveStream))
