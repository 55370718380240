import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  Button,
  IconButton,
  Calendar,
  Modal,
  Popover,
  Stack,
  Whisper,
} from 'rsuite'
import { Icon } from '@rsuite/icons'
import { FaRegClock, FaTimes } from 'react-icons/fa'
import ReactEcharts from 'echarts-for-react'
import moment from 'moment-timezone'

import NAP from 'nap'
import { getLocale } from 'utils/calendar'

interface Props extends WithTranslation {
  section: string
  id: number
  name: string
  current?: any
  title: any
}

interface State {
  data?: [string, number][]
  open?: boolean
  date: Date | null
}

export class Activity extends React.Component<Props, State> {
  state = {} as State

  interval = 0
  calendar = React.createRef<any>()

  componentDidMount() {
    this.loadData()
    this.interval = window.setInterval(this.loadData, 30000)
  }
  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  loadData = () => {
    const { section, id } = this.props
    const { date } = this.state

    let sdate = ''
    if (date) sdate = moment(date).format('yyyy-MM-DD')

    NAP.loadActivity(section, id, sdate).then((data) => {
      this.setState({ data: data || [] })
    })
  }

  toggleModal = () => {
    if (this.state.open) {
      this.state.date = null
      this.loadData()
    }

    this.setState({ open: !this.state.open })
  }

  handleDate = (date: Date | null) => {
    this.state.date = date

    this.loadData()
    this.setState({ date })

    this.calendar.current?.close()
  }

  //
  // render
  //

  render() {
    const { t, title } = this.props
    const { data, open, date } = this.state

    return (
      <div>
        <Modal open={open} onClose={this.toggleModal}>
          <Modal.Header>
            <Stack
              style={{ textAlign: 'center' }}
              alignItems='flex-start'
              justifyContent='center'
              spacing={15}>
              <h5>{title}</h5>

              <div className='activity-subtitle'>
                <Whisper
                  ref={this.calendar}
                  placement='bottom'
                  trigger='click'
                  speaker={
                    <Popover style={{ width: 300 }}>
                      <Calendar
                        className='activity-calendar'
                        onSelect={this.handleDate}
                        onChange={this.handleDate}
                        value={date || undefined}
                        locale={getLocale()}
                        compact
                      />
                    </Popover>
                  }>
                  <Button appearance='link' size='xs'>
                    <Icon as={FaRegClock} />
                    {date
                      ? moment(date).format('DD.MM.yyyy')
                      : t('activity.last_hour')}
                  </Button>
                </Whisper>
                {date && (
                  <IconButton
                    appearance='link'
                    size='xs'
                    icon={<FaTimes />}
                    onClick={() => this.handleDate(null)}
                  />
                )}
              </div>
            </Stack>
          </Modal.Header>
          <Modal.Body>
            {open && data && data?.length > 0 && (
              <ReactEcharts
                style={{ height: 200 }}
                theme='dark'
                option={{
                  backgroundColor: '#0000',
                  xAxis: {
                    type: 'time',
                    show: true,
                    axisTick: { show: false },
                    splitLine: { show: true },
                    minInterval: 1000000,
                  },
                  yAxis: {
                    type: 'value',
                    show: true,
                    boundaryGap: ['0%', '20%'],
                  },
                  grid: {
                    left: 40,
                    right: 20,
                    top: 20,
                    bottom: 20,
                  },
                  dataZoom: [{ type: 'inside' }],
                  visualMap: [
                    {
                      show: false,
                      type: 'continuous',
                      min: 0,
                      color: ['#ffff', '#f04f43'],
                    },
                  ],
                  series: [
                    {
                      type: 'line',
                      data: data || [],
                      areaStyle: {
                        color: {
                          type: 'linear',
                          x: 0,
                          y: 0,
                          x2: 0,
                          y2: 1,
                          colorStops: [
                            {
                              offset: 0,
                              color: '#fff4',
                            },
                            {
                              offset: 1,
                              color: '#fff0',
                            },
                          ],
                        },
                      },
                      lineStyle: {
                        width: 1,
                      },
                      showSymbol: false,
                      smooth: true,
                    },
                  ],
                  tooltip: {
                    trigger: 'axis',
                    show: true,
                    appendToBody: true,
                    backgroundColor: 'rgba(30, 31, 32, 0.95)',
                    borderWidth: 0,
                  },
                }}
              />
            )}
          </Modal.Body>
        </Modal>
        <div onClick={this.toggleModal} className='stat-panel-activity'>
          <ReactEcharts
            style={{ height: 64 }}
            theme='dark'
            option={{
              backgroundColor: '#0000',
              xAxis: {
                type: 'time',
                show: false,
              },
              yAxis: {
                type: 'value',
                show: false,
              },
              grid: {
                left: 2,
                right: 2,
                top: 2,
                bottom: 2,
              },
              visualMap: [
                {
                  show: false,
                  type: 'continuous',
                  min: 0,
                  color: ['#f04f4300', '#f04f43'],
                },
              ],
              series: [
                {
                  type: 'line',
                  data: data || [],
                  areaStyle: {
                    color: {
                      type: 'linear',
                      x: 0,
                      y: 0,
                      x2: 0,
                      y2: 1,
                      colorStops: [
                        {
                          offset: 0,
                          color: '#fff4',
                        },
                        {
                          offset: 1,
                          color: '#fff0',
                        },
                      ],
                    },
                  },
                  lineStyle: {
                    width: 1,
                  },
                  showSymbol: false,
                  smooth: true,
                },
              ],
              tooltip: {
                trigger: 'axis',
                show: true,
                appendToBody: true,
                backgroundColor: 'rgba(30, 31, 32, 0.95)',
                borderWidth: 0,
              },
            }}
          />
        </div>
      </div>
    )
  }
}

export default withTranslation()(Activity)
