import React from 'react'
import { FaLightbulb } from 'react-icons/fa'

import NAP, { FloorMapObject } from 'nap'

interface Props {
  object: FloorMapObject
  selected?: boolean

  onClick?: (e: React.MouseEvent, o: FloorMapObject) => void
  onDoubleClick?: (o: FloorMapObject) => void
}

interface State {
  open: boolean
}

export default class ObjectDevice extends React.Component<Props, State> {
  state = {
    open: false,
  } as State

  interval = 0

  componentDidMount() {
    this.interval = window.setInterval(this.loadDeviceState, 1000)
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  loadDeviceState = () => {
    const { object } = this.props

    NAP.deviceState(object.id)
      .then((state) => this.setState({ open: state.open }))
      .catch(console.error)
  }

  //
  // handlers
  //

  handleOnClick = (e: React.MouseEvent) => {
    const { object } = this.props
    const { onClick } = this.props

    if (onClick) onClick(e, object)
  }

  handleOnDoubleClick = () => {
    const { object } = this.props
    const { onDoubleClick } = this.props

    if (onDoubleClick) onDoubleClick(object)
  }

  //
  // render
  //

  render() {
    const { selected } = this.props
    const { open } = this.state

    return (
      <div className={`floormaps-object ${selected && 'selected'}`}>
        <div className='floormaps-object_in'>
          {this.renderObjectTail()}
          <FaLightbulb
            className={open ? 'floormaps-object_active' : undefined}
            onClick={this.handleOnClick}
            onDoubleClick={this.handleOnDoubleClick}
          />
        </div>
      </div>
    )
  }

  renderObjectTail() {
    const { open } = this.state

    return (
      <div
        style={{ opacity: open ? 0.1 : 1 }}
        className='floormaps-devicezone'
      />
    )
  }
}
