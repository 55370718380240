import React from 'react'
import { Progress } from 'rsuite'
import { FaThermometerHalf } from 'react-icons/fa'

import NAP, { Health } from 'nap'
import i18next from 'i18next'

interface HeathBarsProps {
  expand?: boolean
}
interface HeathBarsState {
  health: Health
}

class HealthBars extends React.Component<HeathBarsProps, HeathBarsState> {
  state = {} as HeathBarsState

  errors = 0
  interval = 0
  componentDidMount() {
    this.loadStatus()
    this.interval = window.setInterval(this.loadStatus, 5000)
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  loadStatus = () => {
    NAP.health()
      .then((health: Health) => {
        if (this.errors >= 3) this.reload()
        this.errors = 0
        this.setState({ health })
      })
      .catch((err) => {
        console.error(err)
        this.errors++
      })
  }

  reload = () => {
    console.log('reload')
    window.location.reload()
  }

  //
  //
  //

  render() {
    const { expand } = this.props
    const h = this.state.health

    if (!h) return ''

    return [
      <div key='health' className='menu-health'>
        {h.CPU && h.CPU.enabled && (
          <HealthBar percent={h.CPU.load}>
            <b className='health-prefix'>CPU</b>
            <span className='health-value'>{Math.round(h.CPU.load)}%</span>
          </HealthBar>
        )}
        {h.CPU && h.CPU.temp > 0 && (
          <div className='health-subtitle'>
            {Math.round(h.CPU.temp)} ℃
            <FaThermometerHalf />
          </div>
        )}

        {h.RAM && h.RAM.total > 0 && (
          <div className='health-group'>
            <HealthBar percent={(h.RAM.used / h.RAM.total) * 100}>
              <b className='health-prefix'>RAM</b>
              <span className='health-value'>
                {Math.round(h.RAM.used / 1073741824)}/
                {Math.round(h.RAM.total / 1073741824)} GB
              </span>
            </HealthBar>
          </div>
        )}

        {h.disks && (
          <div className='health-group'>
            <div className='health-title'>Disks</div>
            {h.disks.map((d: any) => (
              <HealthBar key={d.name} percent={d.used_percent}>
                <b className='health-prefix'>{d.name}</b>
                <span className='health-value'>
                  {((d.total - d.used) / 1073741824).toFixed(0)} GB free
                </span>
              </HealthBar>
            ))}
          </div>
        )}

        {h.GPU && (
          <div className='health-group'>
            <div className='health-title'>GPU</div>
            {h.GPU.map((gpu: any, i: number) => (
              <div key={gpu.name + '_' + i} className='health-group'>
                <HealthBar percent={gpu.load}>
                  <b className='health-prefix'>{gpu.name}</b>
                  <span className='health-value'>{Math.round(gpu.load)}%</span>
                </HealthBar>
                <HealthBar percent={gpu.mem_percent} />
                <div className='health-subtitle'>
                  {expand
                    ? `${Math.round(gpu.mem_used)}/${Math.round(
                        gpu.mem_total
                      )} MB`
                    : `${Math.round(gpu.mem_used)} MB`}
                </div>
              </div>
            ))}
          </div>
        )}

        {h.net && (
          <div className='health-group'>
            <div className='health-title'>Network</div>
            {h.net.map((net: any, i: number) => (
              <div key={net.name + '_' + i} className='menu-health-label'>
                <b className='health-prefix'>{net.name}</b>
                {expand ? (
                  <span className='health-value'>
                    tx {Math.round(net.tx)} / rx {Math.round(net.rx)} MBit
                  </span>
                ) : (
                  <span className='health-value'>
                    {Math.round(net.tx)}/{Math.round(net.rx)}
                  </span>
                )}
              </div>
            ))}
          </div>
        )}
      </div>,
      <div key='version' id='version'>
        {i18next.language == 'ru' && <span>"NeuroView"</span>}
        <span>{h.version}</span>
      </div>,
    ]
  }
}

interface HealthProps {
  percent: number
  children?: any
}
class HealthBar extends React.Component<HealthProps> {
  getColor(n: number): string {
    if (n < 60) return '#3FEA9D' // green
    if (n < 80) return '#F9C22E' // yellow
    return '#EA3F4C' //red
  }

  render() {
    return (
      <div className='menu-health-bar'>
        <div className='menu-health-label'>{this.props.children}</div>
        <Progress.Line
          percent={this.props.percent}
          showInfo={false}
          strokeColor={this.getColor(this.props.percent)}
          strokeWidth={4}
        />
      </div>
    )
  }
}

export default HealthBars
