import moment from 'moment-timezone'
import path from 'path'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FaHeartbeat, FaPen } from 'react-icons/fa'
import { Link } from 'react-router-dom'
import {
  ButtonToolbar,
  Checkbox,
  IconButton,
  Panel,
  Table,
  Tag,
  Toggle,
  Tooltip,
  Whisper,
} from 'rsuite'

import Content, {
  ContentState,
  DelButton,
  HeaderSearch,
  TableFooter,
  alert,
  hasErrors,
  setTitle,
} from 'content'
import PageTable from 'pagetable'
import ROUTES from 'routes'
import NAP, { Device } from 'nap'

interface Props extends WithTranslation {}

interface State extends ContentState {
  devices: Device[]
  selected: Record<number, Device>
}

class Devices extends React.Component<Props, State> {
  state = {
    selected: {},
  } as State

  componentDidMount() {
    setTitle('devices.title')

    NAP.devices()
      .then((devices: Device[]) => this.setState({ devices, loaded: true }))
      .catch(this.setError)
  }

  setError = (err: Error) => {
    this.setState({ error: err.message, loaded: true })
  }

  //
  // handlers
  //

  toggleDevice = (d: Device, v: any) => {
    NAP.toggleDevice(d.id)
      .then((dev: Device) => {
        Object.assign(d, dev)
        this.setState({})
      })
      .catch(alert)
  }

  handleSearch = (search: string) => {
    this.setState({ search })
  }

  handleSelect = (d: Device) => {
    const { selected } = this.state
    selected[d.id] ? delete selected[d.id] : (selected[d.id] = d)

    this.setState({ selected })
  }

  handleSelectAll = (checked: boolean, devices: Device[]) => {
    const selected = {} as Record<number, Device>
    if (checked) devices.forEach((dev) => (selected[dev.id] = dev))

    this.setState({ selected })
  }

  perfomDelete = () => {
    let { devices, selected } = this.state

    Object.values(selected).forEach((d: Device) => {
      NAP.deleteDevice(d.id).catch(alert)
    })

    devices = devices.filter((d: Device) => {
      return selected[d.id] === undefined
    })

    this.setState({ devices, selected: {} })
  }

  getData(): Device[] {
    const search = this.state.search || ''
    let devices = this.state.devices || ([] as Device[])

    if (search) {
      devices = devices.filter((d: Device) => {
        return (
          d.name.includes(search) ||
          d.addr.includes(search) ||
          d.type === search
        )
      })
    }

    return devices
  }

  //
  // render
  //

  render() {
    const { loaded, error } = this.state

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        {this.renderDevices()}
      </Content>
    )
  }

  renderHeader() {
    const { search, selected } = this.state

    return (
      <HeaderSearch
        onSearch={this.handleSearch}
        value={search}
        right={
          <ButtonToolbar>
            <Link to={`${ROUTES.status}/devices`}>
              <IconButton icon={<FaHeartbeat />} circle />
            </Link>
            <DelButton
              onConfirm={this.perfomDelete}
              disabled={Object.keys(selected || {}).length === 0}
              selected={selected}
              circle
            />
          </ButtonToolbar>
        }
      />
    )
  }

  renderDevices() {
    const { t } = this.props
    const { selected } = this.state
    const devices = this.getData() || ([] as Device[])

    const { Column, HeaderCell, Cell } = Table

    return (
      <Panel className='content-panel'>
        <PageTable
          data={devices}
          total={devices.length}
          sort={{ col: 'name', type: 'asc' }}>
          <Column width={65} sortable>
            <HeaderCell> </HeaderCell>
            <Cell dataKey='enabled'>
              {(dev: Device | any) => (
                <Toggle
                  key={`toggle_${dev.id}`}
                  checked={dev.enabled}
                  data-errors={hasErrors(dev.errors)}
                  onChange={(v) => this.toggleDevice(dev, v)}
                />
              )}
            </Cell>
          </Column>

          <Column width={200} sortable>
            <HeaderCell>{t('name')}</HeaderCell>
            <Cell dataKey='name' />
          </Column>
          <Column flexGrow={1} sortable>
            <HeaderCell>{t('desc')}</HeaderCell>
            <Cell dataKey='desc' />
          </Column>
          <Column width={150} sortable>
            <HeaderCell>{t('type')}</HeaderCell>
            <Cell dataKey='type' align='center'>
              {(dev: Device | any) => <Tag>{dev.type}</Tag>}
            </Cell>
          </Column>
          <Column width={200} sortable>
            <HeaderCell>{t('addr')}</HeaderCell>
            <Cell dataKey='addr' />
          </Column>

          <Column width={100}>
            <HeaderCell>{t('devices.state')}</HeaderCell>
            <Cell>
              {(dev: Device | any) => {
                if (dev.duration <= 0) dev.duration = 5
                if (
                  moment(dev.open_time)
                    .add(dev.duration, 's')
                    .isBefore(moment())
                ) {
                  return null
                }
                return <Tag>{t('devices.opened')}</Tag>
              }}
            </Cell>
          </Column>

          <Column fixed='right' align='right' width={90}>
            <HeaderCell className='control-table-header-cell'>
              <Checkbox
                checked={
                  devices?.length > 0 &&
                  Object.keys(selected).length === devices?.length
                }
                onChange={(v, checked) =>
                  this.handleSelectAll(checked, devices)
                }
              />
            </HeaderCell>
            <Cell className='actions-cell'>
              {(d: Device | any) => (
                <ButtonToolbar>
                  <Whisper
                    placement='top'
                    trigger='hover'
                    speaker={<Tooltip>{t('edit')}</Tooltip>}>
                    <Link
                      to={path.join(window.location.pathname, d.id.toString())}>
                      <IconButton appearance='subtle' icon={<FaPen />} />
                    </Link>
                  </Whisper>
                  <Checkbox
                    checked={selected[d.id] !== undefined || false}
                    onChange={() => this.handleSelect(d)}
                  />
                </ButtonToolbar>
              )}
            </Cell>
          </Column>
        </PageTable>

        <TableFooter>{t('devices.add_device')}</TableFooter>
      </Panel>
    )
  }
}

export default withTranslation()(Devices)
