import React from 'react'

import NAP from 'nap'
import { Spinner } from 'content'

// const CONTENT_TYPE_JPEG = 'image/jpeg'

interface Props {
  src: string
  alt?: string
  className?: string
  background?: boolean
  width?: number
  height?: number

  onLoad?: () => void
  onError?: () => void
}

interface State {
  imgData: string
  imgTime: number
}

export default class IMAGE extends React.Component<Props, State> {
  state = {
    imgData: '',
    imgTime: 0,
  } as State

  src = ''
  fetchAbort = new AbortController()
  unmounted = false
  img: any = React.createRef<HTMLImageElement>()

  constructor(props: Props) {
    super(props)
    this.src = props.src
  }

  componentDidMount() {
    if (this.props.src) {
      this.loadImage(this.props.src)
    }
  }

  componentDidUpdate() {
    if (this.src !== this.props.src) {
      this.loadImage(this.props.src)
      this.unmounted = false
    }
  }

  componentWillUnmount() {
    this.fetchAbort.abort()
    this.unmounted = true
  }

  isLocal = (): boolean => {
    return NAP.addr.startsWith('/')
  }

  loadImage(src: string) {
    this.src = this.props.src
    const url = NAP.url(src)
    // if (this.isLocal()) {
    //   this.setState({ imageData: url })
    //   return
    // }

    const opt = {
      headers: NAP.authHeaders(),
      signal: this.fetchAbort.signal,
    }

    fetch(url, opt)
      .then((resp) => {
        // eslint-disable-next-line
        this.state.imgTime = Number(resp.headers.get('Timems')) || 0
        return resp.blob()
      })
      .then((blob) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          if (e.target) this.setImageData(e.target.result)
        }
        reader.readAsDataURL(blob)
      })
      .catch(this.handleError)
  }

  getFrame = (callback: (frame: any) => void) => {
    callback(this.state.imgData.split(',')[1])
  }

  handleError = (err: Error) => {
    if (err.name === 'AbortError') return

    const { onError } = this.props
    if (onError) onError()
  }

  setImageData = (img: any) => {
    if (this.props.onLoad) this.props.onLoad()

    this.setState({ imgData: img })
  }

  handleImgLoad = () => {
    if (this.isLocal()) {
      if (this.props.onLoad) this.props.onLoad()
    }
  }

  render() {
    const { className, alt, background, width, height, src } = this.props
    const { imgData } = this.state

    const style: any = {}
    if (width) style.width = width + 'px'
    if (height) style.height = height + 'px'

    if (!imgData) {
      return (
        <div className={className} style={style}>
          <div className='camera-loader'>
            <Spinner className='spinner-small spinner-center' />
          </div>
        </div>
      )
    }

    if (background) {
      style.backgroundImage = `url(${imgData})`
      return (
        <div
          className={`mjpeg-background ${className}`}
          style={style}
          data-src={src}
        />
      )
    }

    return (
      <img
        ref={(ref) => (this.img = ref)}
        className={className}
        onLoad={this.handleImgLoad}
        onError={() => this.handleError(new Error('invalid image'))}
        alt={alt}
        src={imgData}
        style={style}
        data-src={src}
      />
    )
  }
}
