import moment from 'moment-timezone'
import queryString, { ParseOptions } from 'query-string'
import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import {
  FaChartBar,
  FaExclamationCircle,
  FaFileAlt,
  FaMapMarkedAlt,
  FaPlay,
  FaSort,
  FaSortDown,
  FaSortUp,
  FaTimesCircle,
  FaUserCircle,
} from 'react-icons/fa'
import { Redirect, RouteComponentProps } from 'react-router-dom'
import {
  ButtonToolbar,
  CheckPicker,
  Checkbox,
  Dropdown,
  IconButton,
  Pagination,
  Panel,
  Progress,
  Slider,
  Stack,
  Table,
  Tag,
  Tooltip,
  Uploader,
  Whisper,
} from 'rsuite'
import { ItemDataType } from 'rsuite/esm/@types/common'

import { DateTimeFormat } from 'components/cameras/videoarchive'
import { ChartWidget } from 'components/charts'
import FloorMapCanvas from 'components/floormaps/floormapcanvas'
import { IMAGE } from 'components/players'
import Content, {
  ContentState,
  DelButton,
  HeaderSearch,
  alert,
  setTitle,
  success,
} from 'content'
import { Sort } from 'pagetable'
import ResizeDetector from 'react-resize-detector'
import ROUTES from 'routes'
import NAP, {
  Chart,
  ChartData,
  Event,
  FindFaceVectorResponse,
  FloorMap,
  Group,
  Item,
  Profile,
  NAPTime,
  ReportEvents,
  ReportsConfig,
  ReportsFilters,
  ShortModel,
  User,
  checkAccess,
} from 'nap'
import Calendar, { TimeRange } from 'utils/calendar'
import { EventItems, EventsDateFormat, EventsTimeLongFormat } from './events'
import './events.less'
import EventsModalImage from './eventsmodalimage'
import EventsSettings from './eventssettings'
import {
  DEFAULT_VECTOR_THRESHOLD,
  StorageFace,
  localStorageLoadFace,
  localStorageRemoveFace,
  localStorageSaveFace,
  vectorsEqual,
} from './eventsutils'
import ReportsSendings from './reportssendings'

export const URLDateFormat = 'YYYY-MM-DD'
export const URLTimeFormat = 'HH:mm'
const DEFAULT_SORT = { col: 'time_at', type: 'desc' } as Sort

const ANY_PROFILE = 'any_profile'
const ANY_CAR = 'any_car'

interface Props extends WithTranslation, RouteComponentProps {}
interface State extends ContentState {
  events: Event[]

  cameras: ShortModel[]
  sectors: ShortModel[]
  schemes: ShortModel[]
  analytics: ShortModel[]
  users: ShortModel[]
  groups: Group[]
  profiles: ItemDataType[]
  types: ShortModel[]

  filter: Record<string, any>
  sort: Sort
  face?: StorageFace

  date_at: Date
  date_to: Date

  page: number
  pageCapacity: number
  total: number

  config: ReportsConfig

  exportNames: string[]
  inExport?: {
    name: string
    percent: number
    interval: number
  }

  open: {
    event?: Event
    imgsrc?: string
  }

  charts: Chart[]
  chart?: Chart
  chartdata?: ChartData

  floormaps: FloorMap[]
  floormap?: FloorMap
  floormapSize: { w: number; h: number }

  progress: number
  inProgress: boolean

  selected: Record<number, Event>

  user: User

  redirect?: string
}

const queryOpt = {
  // parseNumbers: true,
  parseBooleans: true,
  arrayFormat: 'comma',
} as ParseOptions

class EventsArchive extends React.Component<Props, State> {
  state = {
    events: [] as Event[],

    cameras: [] as ShortModel[],
    sectors: [] as ShortModel[],
    schemes: [] as ShortModel[],
    users: [] as ShortModel[],
    groups: [] as Group[],
    profiles: [] as ItemDataType[],
    types: [] as ShortModel[],
    config: { columns: {} as Record<string, boolean> } as ReportsConfig,
    filter: {} as Record<string, any>,
    sort: DEFAULT_SORT,

    page: 1,
    pageCapacity: 30,
    total: 0,

    progress: 0,
    inProgress: true,

    selected: {} as Record<number, Event>,
  } as State

  timeZone = 0
  // progressInterval = 0
  refChart = React.createRef<any>()

  constructor(props: Props) {
    super(props)
    this.parseQuery()
  }

  parseQuery = () => {
    const q = queryString.parse(window.location.search, queryOpt) as any

    const date_at = q.date_at
      ? moment(q.date_at, URLDateFormat)
      : moment().startOf('day')
    const date_to = q.date_to
      ? moment(q.date_to, URLDateFormat)
      : moment().endOf('day')

    if (q.analytics) q.analytics = this.fixQueryItem(q.analytics) || []
    if (q.cameras) q.cameras = this.fixQueryItem(q.cameras) || []
    if (q.groups) q.groups = this.fixQueryItem(q.groups) || []
    if (q.users) q.users = this.fixQueryItem(q.users) || []
    if (q.types && typeof q.types === 'string') q.types = [q.types]
    if (q.sectors && typeof q.sectors === 'string') q.sectors = [q.sectors]
    if (q.time_at) q.time_at = Number(q.time_at)
    if (q.time_to) q.time_to = Number(q.time_to)
    if (q.search !== undefined) q.search = q.search.toString()

    if (q.offset !== undefined && q.limit !== undefined) {
      this.state.page = Math.round(q.offset / q.limit) + 1
      this.state.pageCapacity = Math.round(q.limit)
    }

    q['date_at'] = date_at.format(URLDateFormat)
    q['date_to'] = date_to.format(URLDateFormat)

    this.state.date_at = date_at.toDate()
    this.state.date_to = date_to.toDate()
    this.state.filter = q
    this.state.search = q.search
    this.state.sort = this.parseSort(q['sort'])
  }

  fixQueryItem = (o: number | number[] | string | string[]): number[] => {
    if (typeof o === 'number') return [o]
    if (typeof o === 'string') return [parseInt(o)]
    return o.map((s) => parseInt(s))
  }

  componentDidMount() {
    setTitle('eventsarchive.title')

    NAP.time().then(this.setTime).catch(alert)
    this.loadEvents()
    this.loadColumns()

    NAP.reportsFilters()
      .then((resp: ReportsFilters) => {
        const { t } = this.props
        let profiles = [] as ItemDataType[]

        if (resp.profiles) {
          profiles.push({
            id: 'any_profile',
            name: t('eventsarchive.any_profile'),
            groupBy: 'profiles',
          })
          resp.profiles.forEach((p) => {
            profiles.push({
              id: 'profile:' + p.id,
              name: p.name,
              groupBy: 'profiles',
            })
          })
        }

        if (resp.cars) {
          profiles.push({
            id: 'any_car',
            name: t('eventsarchive.any_car'),
            groupBy: 'cars',
          })
          resp.cars.forEach((c) => {
            profiles.push({
              id: 'car:' + c.id,
              name: c.name,
              groupBy: 'cars',
            })
          })
        }

        this.setState({
          cameras: resp.cameras || [],
          schemes: resp.schemes || [],
          sectors:
            (resp.sectors?.map((s) => ({ name: s })) as ShortModel[]) || [],
          users: resp.users || [],
          types: (resp.types?.map((t) => ({ name: t })) as ShortModel[]) || [],
          groups: resp.groups || [],
          profiles,
        })
      })
      .catch(alert)

    NAP.user()
      .then((user) => this.setState({ user }))
      .catch(alert)

    NAP.charts().then((charts) => this.setState({ charts }))
    NAP.floormaps().then((floormaps) => this.setState({ floormaps }))

    this.setState({ loaded: true })
  }

  url = ''
  componentDidUpdate() {
    const face = localStorageLoadFace()

    if (
      this.url === window.location.search &&
      vectorsEqual(face?.vector, this.state.face?.vector)
    ) {
      return
    }

    this.url = window.location.search

    this.setState(
      { face, open: { event: undefined, imgsrc: undefined } },
      () => {
        this.parseQuery()
        this.loadEvents()
      }
    )
  }

  componentWillUnmount() {
    if (this.loadEventsAbort) this.loadEventsAbort.abort()
  }

  loadColumns = () => {
    NAP.reportsConfig()
      .then((config) => this.setState({ config }))
      .catch(alert)
  }

  loadEventsTimeout = 0
  loadEventsAbort: any // AbortController
  loadEvents = () => {
    if (this.loadEventsTimeout) window.clearTimeout(this.loadEventsTimeout)
    this.loadEventsTimeout = window.setTimeout(this._loadEvents, 500)

    if (this.loadEventsAbort) this.loadEventsAbort.abort()
  }

  _loadEvents = () => {
    this.setState({ inProgress: true, progress: 0 })

    const { search, page, pageCapacity, chart, config, face } = this.state

    const filter = Object.assign({}, this.state.filter)

    if (search) filter.search = search.toString()
    else delete filter.search

    filter.offset = (page - 1) * pageCapacity
    filter.limit = pageCapacity
    filter.witharchive = config.columns['archive'] !== false

    if (filter.sort) {
      filter.sort = this.parseSort(filter['sort'])
    }

    if (filter.types && typeof filter.types === 'string') {
      filter.types = [filter.types]
    }

    if (filter.vector_threshold) {
      filter.vector_threshold = parseFloat(filter.vector_threshold)
    }

    if (filter.groups) {
      if (Array.isArray(filter.groups))
        filter.groups = filter.groups.map((n) => parseInt(n))
      else filter.groups = [parseInt(filter.groups)]
    }

    if (filter.users) {
      if (Array.isArray(filter.users))
        filter.users = filter.users.map((n) => parseInt(n))
      else filter.users = [parseInt(filter.users)]
    }

    if (filter.any_car) {
      filter.any_car = String(filter.any_car) === 'true'
    }

    if (filter.any_profile) {
      filter.any_profile = String(filter.any_profile) === 'true'
    }

    if (filter.profiles) {
      if (Array.isArray(filter.profiles))
        filter.profiles = filter.profiles.map((n) => parseInt(n))
      else filter.profiles = [parseInt(filter.profiles)]
    }

    if (filter.cars) {
      if (Array.isArray(filter.cars))
        filter.cars = filter.cars.map((n) => parseInt(n))
      else filter.cars = [parseInt(filter.cars)]
    }

    if (face && face.vector?.length) {
      filter.vector = face.vector
      filter.vector_threshold = face.threshold || DEFAULT_VECTOR_THRESHOLD
    }

    if (this.loadEventsAbort) this.loadEventsAbort.abort()
    this.loadEventsAbort = new AbortController()

    NAP.reportsEvents(filter, chart?.id, this.loadEventsAbort.signal)
      .then(this.setStream)
      .catch(this.setError)
  }

  setStream = (reader: any) => {
    const events = [] as Event[]
    this.setState({ events: [] })

    let data: string = ''
    const decoder = new TextDecoder('utf-8')

    const parseStreamData = () => {
      const lines = data.split('\n')
      data = ''

      const complete = data[data.length - 1] == '\n'

      lines.forEach((line, i) => {
        if (!complete && i == lines.length - 1) {
          data = line + '\n'
          return
        }

        try {
          const data = JSON.parse(line)

          if (data.total) {
            this.setState({
              progress: (data.progress / data.total) * 100,
              inProgress: true,
              total: data.total,
            })
          } else if (data.event) {
            const e = data.event
            e.time_at = moment.unix(e.time_at).utcOffset(this.timeZone)
            e.time_to = moment.unix(e.time_to).utcOffset(this.timeZone)
            events.push(e)

            this.setState({ events })
          } else if (data.chart) {
            this.setState({ chartdata: data.chart })
          }
        } catch (e) {
          data += line + '\n'
        }
      })
    }

    const pump = ({ done, value }) => {
      data += decoder.decode(value) || ''
      const char = data[data.length - 1]
      if (char == '\n' || char == '}') parseStreamData()

      if (done) {
        this.setState({ loaded: true, progress: 100, inProgress: false })
        return
      }

      return reader.read().then(pump)
    }

    reader.read().then(pump)
  }

  setEvents = (resp: ReportEvents) => {
    resp.events?.forEach((e: Event) => {
      e.time_at = moment.unix(e.time_at).utcOffset(this.timeZone)
      e.time_to = moment.unix(e.time_to).utcOffset(this.timeZone)
    })

    // if no events in response but events exists, reset page and load it again
    if (!resp.events?.length && resp.total && this.state.page > 1) {
      // eslint-disable-next-line
      this.state.page = 1

      this._loadEvents()
      return
    }

    this.setState({
      events: resp.events || [],
      total: resp.total,
      chartdata: resp.chartdata,
      loaded: true,
      progress: 100,
    })

    // const chart = this.refChart.current
    // if (chart && resp.chartdata) chart.setData(resp.chartdata)

    // window.clearInterval(this.progressInterval)
    // window.setTimeout(() => {
    //   this.setState({ progress: 0, inProgress: false })
    // }, 50)
  }

  setTime = (t: NAPTime) => {
    this.timeZone = t.offset / 60
  }

  setError = (err: Error) => {
    if (err.message.includes('abort')) return

    this.setState({
      error: err.message,
      loaded: true,
      inProgress: false,
      progress: 0,
    })
  }

  //
  // handlers
  //

  handleSettings = () => {
    this.loadColumns()
    this.loadEvents()
  }

  handleSort = (col: string, type: string) => {
    const { sort } = this.state
    Object.assign(sort, { col, type })
    this.setState({ sort })

    this.loadEvents()
    this.setURL()
  }

  parseSort = (s: string | Sort): Sort => {
    if (!s) return DEFAULT_SORT as Sort
    if (typeof s === 'string') {
      const ss = (s as string).split(' ')
      return {
        col: ss[0],
        type: ss[1],
      }
    }

    return s as Sort
  }

  handleSearch = (search: string) => {
    // eslint-disable-next-line
    this.state.search = search
    this.setURL()

    this.setState({ search }, () => {
      this.loadEvents()
    })
  }

  handleCalendar = (dates: any) => {
    const { filter } = this.state
    const date_at = moment(dates[0]).startOf('day')
    const date_to = moment(dates[1]).endOf('day')

    filter['date_at'] = date_at.format(URLDateFormat)
    filter['date_to'] = date_to.format(URLDateFormat)

    this.setURL()
    this.setState({ date_at: date_at.toDate(), date_to: date_to.toDate() })
    this.loadEvents()
  }

  handleTimeRange = (at?: string, to?: string, everyday?: boolean) => {
    const { filter } = this.state

    if (at) filter['date_time_at'] = at
    else delete filter['date_time_at']

    if (to) filter['date_time_to'] = to
    else delete filter['date_time_to']

    if (everyday) filter['date_time_everyday'] = everyday
    else delete filter['date_time_everyday']

    this.setURL()
    this.setState({ filter }, () => {
      this.loadEvents()
    })
  }

  handleChangePage = (page: number) => {
    this.setState({ page })
    this.loadEvents()
    this.setURL()
  }
  handleChangeLength = (pageCapacity: number) => {
    this.setState({ pageCapacity })
    this.loadEvents()
    this.setURL()
  }

  handleFilterByItem = (item: Item) => {
    this.handleSearch(item.name || item.type)
  }

  loadTimeout = 0
  handleFilter = (val: any, key: string) => {
    const filter = this.state.filter

    if (val) filter[key] = val
    else delete filter[key]

    this.setURL()
    this.setState({ inProgress: true }, this.loadEvents)
  }

  handleFilterProfiles = (selected: any, val: any) => {
    const filter = this.state.filter

    if (val.id === ANY_PROFILE && filter.any_profile) {
      delete filter[ANY_PROFILE]
      this.handleFilter(false, ANY_PROFILE)
      return
    }
    if (val.id === ANY_CAR && filter.any_car) {
      delete filter[ANY_CAR]
      this.handleFilter(false, ANY_CAR)
      return
    }

    const curr = selected[selected.length - 1]

    if (curr === ANY_PROFILE || curr === ANY_CAR) {
      delete filter['profiles']
      delete filter['cars']
      selected.splice(0, selected.length - 1)
    } else {
      let index = selected.indexOf(ANY_PROFILE)
      if (index != -1) selected.splice(index, 1)
      index = selected.indexOf(ANY_CAR)
      if (index != -1) selected.splice(index, 1)

      delete filter[ANY_PROFILE]
      delete filter[ANY_CAR]
    }

    if (val.id === ANY_PROFILE) {
      delete filter[ANY_CAR]
      this.handleFilter(filter.any_profile ? false : true, ANY_PROFILE)
      return
    }

    if (val.id === ANY_CAR) {
      delete filter[ANY_PROFILE]
      this.handleFilter(filter.any_car ? false : true, ANY_CAR)
      return
    }

    //
    //
    //

    if (curr?.startsWith('profile')) {
      delete filter.cars
      for (let i = selected.length - 1; i >= 0; i--) {
        if (!selected[i].startsWith('profile')) {
          selected.splice(i, 1)
        }
      }
    } else if (curr?.startsWith('car')) {
      delete filter.profiles
      for (let i = selected.length - 1; i >= 0; i--) {
        if (!selected[i].startsWith('car')) {
          selected.splice(i, 1)
        }
      }
    }

    //
    //
    //

    filter[val.groupBy] = selected.map((s) => s.split(':')[1])

    this.setURL()
    this.setState({ inProgress: true, filter }, this.loadEvents)
  }

  getFilterProfilesValues = () => {
    const filter = this.state.filter

    if (filter[ANY_PROFILE]) return [ANY_PROFILE]
    if (filter[ANY_CAR]) return [ANY_CAR]

    if (filter.profiles) {
      if (['number', 'string'].includes(typeof filter.profiles)) {
        return [`profile:${filter.profiles}`]
      }
      return filter.profiles.map((id) => `profile:${id}`)
    }
    if (filter.cars) {
      if (['number', 'string'].includes(typeof filter.cars)) {
        return [`car:${filter.cars}`]
      }
      return filter.cars.map((id) => `car:${id}`)
    }

    return []
  }

  setIDPrefix(id: any, prefix: string) {
    return `${prefix}:${id}`
  }

  setURL = () => {}

  loadExportNames = () => {
    NAP.reportsNames()
      .then((exportNames: string[]) => this.setState({ exportNames }))
      .catch(alert)
  }

  export = (n: string) => {
    const len = this.state.events.length || 1

    const inExport = {
      name: n,
      percent: 1,
      interval: 0,
    }
    this.setState({ inExport })

    inExport.interval = window.setInterval(() => {
      inExport.percent += (250 / len) * 100
      this.setState({ inExport })
    }, 250)

    const { filter } = this.state
    NAP.reportsExport(n, filter)
      .then(() => {
        window.clearInterval(inExport.interval)
        this.setState({ inExport: undefined })
      })
      .catch((err: Error) => {
        window.clearInterval(inExport.interval)
        this.setState({ inExport: undefined })
        alert(err)
      })
  }

  getData = () => {
    return this.state.events
  }

  getChartData = () => {
    // const { events } = this.state
    return undefined
  }

  filterByItems = (e: Event, search: string): boolean => {
    if (!e.items) return false

    return e.items.some((item: Item) => {
      return item.type.includes(search) || item.name.includes(search)
    })
  }

  filterByTimeRange = (e: Event, search: string): boolean => {
    return e.time_at.format('YYYY/MM/DD HH:mm').includes(search)
  }

  getFilter = () => {
    const data = {
      cameras: this.state.cameras,
      sectors: this.state.sectors,
      schemes: this.state.schemes,
      users: this.state.users,
      groups: this.state.groups,
      profiles: this.state.profiles,
      types: this.state.types,
    } as ReportsFilters

    return data
  }

  toggleEventWindow = (e?: Event, imgsrc?: string) => {
    this.setState({ open: { event: e, imgsrc: imgsrc } })
  }

  handleSelectChart = (chart?: Chart) => {
    this.setState({ chart: chart })
    this.loadEvents()
  }

  handleSelectFloormap = (fmap?: FloorMap) => {
    this.setState({ floormap: fmap })
  }

  handleSearchByVector = (resp: FindFaceVectorResponse | Error | any) => {
    if (resp.message) {
      alert(resp.message)
      return
    }
    if (!resp || !resp.vector) {
      alert('face not found')
      return
    }

    const { filter } = this.state

    const face = {
      vector: resp.vector,
      nodetect: resp.nodetect,
      threshold: filter.vector_threshold | 1.1,
    }

    localStorageSaveFace(face)
    this.setState({ face }, this.loadEvents)
  }

  handleSearchByVectorClear = () => {
    localStorageRemoveFace()
    this.setState({ face: undefined }, this.loadEvents)
  }

  handleVectorThreshold = (v: number) => {
    const { face } = this.state
    if (!face) return
    face.threshold = v

    this.setState({ face }, this.loadEvents)
  }

  handleSelectAll = (checked: boolean, events: Event[]) => {
    const selected = {} as Record<number, Event>
    if (checked) events.forEach((e) => (selected[e.id] = e))

    this.setState({ selected })
  }

  handleSelect = (checked: boolean, e: Event) => {
    const { selected } = this.state

    if (checked) selected[e.id] = e
    else delete selected[e.id]

    this.setState({ selected })
  }

  perfomDelete = () => {
    const { selected } = this.state
    NAP.deleteEvents(Object.keys(selected))
      .then(() => {
        success('success')

        this.setState({ selected: {} as Record<number, Event> })
        this.loadEvents()
      })
      .catch(alert)
  }

  callbackEventDelete = () => {
    this.setState({})
  }

  handleVideoArchive = (e: Event) => {
    const time = e.time_at.format(DateTimeFormat)
    const url = `${ROUTES.videoarchive}?camera=${e.camera}&time=${time}`

    this.setState({ redirect: url })
  }

  handleFloormapSize = (w?: number, h?: number) => {
    if (!w || !h) return
    this.setState({ floormapSize: { w, h } })
  }

  //
  //
  //

  render() {
    const { t } = this.props
    const { loaded, error, config, open, redirect, user } = this.state
    const { chart, chartdata } = this.state
    const { floormap, floormapSize } = this.state

    if (redirect) return <Redirect push to={redirect} />

    const filter = this.getFilter()
    const events = this.getData()

    const { Column, HeaderCell, Cell } = Table

    return (
      <Content loaded={loaded} error={error} header={this.renderHeader()}>
        {open && open.event && open.imgsrc && (
          <EventsModalImage
            events={events}
            event={open.event}
            imgSrc={open.imgsrc}
            onHide={this.toggleEventWindow}
            onDelete={this.callbackEventDelete}
            user={user}
          />
        )}

        {floormap && (
          <Panel className='content-panel eventsarchive-floormaps'>
            <ResizeDetector
              onResize={this.handleFloormapSize}
              skipOnMount
              handleWidth
              handleHeight>
              <FloorMapCanvas
                fmap={floormap}
                events={events}
                size={{ h: floormapSize?.h || 300 }}
              />
            </ResizeDetector>
          </Panel>
        )}

        {chart && (
          <Panel className='content-panel'>
            <ChartWidget
              ref={this.refChart}
              height={300}
              loadDataDisable={true}
              data={chartdata}
              btns={{ edit: true, save: true }}
              {...chart}
            />
          </Panel>
        )}

        <Panel className='content-panel'>
          {this.state.inProgress && (
            <Progress.Line
              className='events-progressline'
              percent={this.state.progress}
              showInfo={false}
            />
          )}

          <Table
            className='eventsarchive-table'
            data={events}
            rowHeight={58}
            autoHeight>
            <Column align='center' width={130} verticalAlign='middle'>
              <HeaderCell>
                {t('eventsarchive.time')}
                {this.renderSortBtn('time_at')}
              </HeaderCell>
              <Cell className='events-celltime' dataKey='time_at'>
                {this.renderCellTime}
              </Cell>
            </Column>

            {config.columns['camera'] !== false && (
              <Column align='center' flexGrow={1} verticalAlign='middle'>
                <HeaderCell>
                  <CheckPicker
                    className='events-filter'
                    onChange={(v) => this.handleFilter(v, 'cameras')}
                    value={this.state.filter.cameras || []}
                    data={filter.cameras}
                    labelKey='name'
                    groupBy='sector'
                    renderMenuGroup={(label, item) => {
                      return (
                        <div>
                          {label && label !== 'undefined'
                            ? label
                            : t('eventsarchive.nosector')}
                        </div>
                      )
                    }}
                    valueKey='id'
                    cleanable={true}
                    searchable={true}
                  />
                  <div className='events-filter-label'>
                    {t('eventsarchive.camera')}
                  </div>
                  {this.renderSortBtn('camera_id')}
                </HeaderCell>
                <Cell dataKey='camera_id'>{(e: Event | any) => e.camera}</Cell>
              </Column>
            )}

            {config.columns['sector'] !== false &&
              filter.sectors &&
              filter.sectors.length > 0 && (
                <Column align='center' flexGrow={1} verticalAlign='middle'>
                  <HeaderCell>
                    <CheckPicker
                      className='events-filter'
                      onChange={(v) => this.handleFilter(v, 'sectors')}
                      value={this.state.filter.sectors || []}
                      data={filter.sectors as ShortModel[]}
                      labelKey='name'
                      valueKey='name'
                      cleanable={false}
                      searchable={true}
                    />
                    <div className='events-filter-label'>
                      {t('eventsarchive.sector')}
                    </div>
                    {this.renderSortBtn('sector')}
                  </HeaderCell>
                  <Cell dataKey='sector'>
                    {(e: Event | any) => e.sector && <Tag>{e.sector}</Tag>}
                  </Cell>
                </Column>
              )}

            {config.columns['analytics'] !== false && (
              <Column align='center' flexGrow={1} verticalAlign='middle'>
                <HeaderCell>
                  <CheckPicker
                    className='events-filter'
                    value={this.state.filter.analytics || []}
                    onChange={(v) => this.handleFilter(v, 'analytics')}
                    data={filter.schemes}
                    labelKey='name'
                    valueKey='id'
                    cleanable={false}
                    searchable={true}
                  />
                  <div className='events-filter-label'>
                    {t('eventsarchive.analytics')}
                  </div>
                  {this.renderSortBtn('analytics_id')}
                </HeaderCell>
                <Cell dataKey='analytics_id'>
                  {(e: Event | any) => e.analytics}
                </Cell>
              </Column>
            )}

            {config.columns['users'] !== false && filter.users.length > 0 && (
              <Column align='center' flexGrow={1} verticalAlign='middle'>
                <HeaderCell>
                  <CheckPicker
                    className='events-filter'
                    onChange={(v) => this.handleFilter(v, 'users')}
                    data={filter.users}
                    value={this.state.filter.users || []}
                    labelKey='name'
                    valueKey='id'
                    cleanable={false}
                    searchable={true}
                  />
                  <div className='events-filter-label'>
                    {t('eventsarchive.users')}
                  </div>
                  {this.renderSortBtn('user')}
                </HeaderCell>
                <Cell dataKey='user'>{(e: Event | any) => e.user}</Cell>
              </Column>
            )}

            {config.columns['groups'] !== false && (
              <Column align='center' flexGrow={1} verticalAlign='middle'>
                <HeaderCell>
                  <CheckPicker
                    className='events-filter'
                    onChange={(v) => this.handleFilter(v, 'groups')}
                    data={filter.groups}
                    value={this.state.filter.groups || []}
                    labelKey='name'
                    valueKey='id'
                    cleanable={false}
                    searchable={true}
                  />
                  <div className='events-filter-label'>
                    {t('eventsarchive.groups')}
                  </div>
                  {this.renderSortBtn('group_id')}
                </HeaderCell>
                <Cell dataKey='group_id'>
                  {(e: Event | any) => e.group?.name}
                </Cell>
              </Column>
            )}

            {config.columns['profiles'] !== false && (
              <Column align='center' flexGrow={1} verticalAlign='middle'>
                <HeaderCell>
                  <CheckPicker
                    className='events-filter'
                    onSelect={this.handleFilterProfiles}
                    data={filter.profiles}
                    value={this.getFilterProfilesValues()}
                    labelKey='name'
                    valueKey='id'
                    groupBy='groupBy'
                    renderMenuGroup={(name, item) => {
                      return t('eventsarchive.' + name)
                    }}
                    cleanable={false}
                    searchable={true}
                  />
                  <div className='events-filter-label'>
                    {t('eventsarchive.profiles')}
                  </div>
                  {this.renderSortBtn('profile_id')}
                </HeaderCell>
                <Cell dataKey='profile_id'>{this.renderCellProfile}</Cell>
              </Column>
            )}

            {config.columns['status'] !== false && (
              <Column flexGrow={1} align='center' verticalAlign='middle'>
                <HeaderCell>
                  {t('eventsarchive.status')}
                  {this.renderSortBtn('status')}
                </HeaderCell>
                <Cell dataKey='status'>
                  {(e: Event | any) => (
                    <div>
                      {e.status && (
                        <Tag
                          data-state={e.state}
                          data-alert={e.alert}
                          onClick={() => this.handleSearch(e.status)}>
                          {e.status}
                        </Tag>
                      )}

                      {this.renderEventNameInStatus(e)}
                    </div>
                  )}
                </Cell>
              </Column>
            )}

            {config.columns['objects'] !== false && (
              <Column flexGrow={1} verticalAlign='middle'>
                <HeaderCell>
                  <CheckPicker
                    className='events-filter'
                    onChange={(v) => this.handleFilter(v, 'types')}
                    data={filter.types as ShortModel[]}
                    value={this.state.filter.types || []}
                    labelKey='name'
                    valueKey='name'
                    cleanable={false}
                    searchable={true}
                  />
                  <div className='events-filter-label'>
                    {t('eventsarchive.objects')}
                  </div>
                  {this.renderSortBtn('prob')}
                </HeaderCell>
                <Cell>
                  {(e: Event | any) => (
                    <EventItems {...e} onClick={this.handleFilterByItem} />
                  )}
                </Cell>
              </Column>
            )}

            {config.columns['image'] !== false && (
              <Column width={70} verticalAlign='middle'>
                <HeaderCell>{t('eventsarchive.image')}</HeaderCell>
                <Cell className='table-images-cell'>
                  {this.renderCellImage}
                </Cell>
              </Column>
            )}

            {config.columns['alerts'] !== false && (
              <Column width={70} align='center' verticalAlign='middle'>
                <HeaderCell>
                  <div onClick={() => this.handleSearch('alerts')}>
                    {t('eventsarchive.alerts')}
                  </div>
                </HeaderCell>
                <Cell>
                  {(e: Event | any) =>
                    e.alert && <FaExclamationCircle data-errors='true' />
                  }
                </Cell>
              </Column>
            )}

            {config.columns['archive'] !== false && (
              <Column width={70} align='center' verticalAlign='middle'>
                <HeaderCell>
                  <div onClick={() => this.handleSearch('alerts')}>
                    {t('eventsarchive.archive')}
                  </div>
                </HeaderCell>
                <Cell>{this.renderCellVideoArchive}</Cell>
              </Column>
            )}

            {config.columns['select'] !== false &&
              checkAccess(this.state.user, '/events', 1) && (
                <Column
                  width={50}
                  fixed='right'
                  align='right'
                  verticalAlign='middle'>
                  <HeaderCell className='control-table-header-cell'>
                    <Checkbox
                      checked={
                        events?.length > 0 &&
                        Object.keys(this.state.selected).length ===
                          events.length
                      }
                      onChange={(v, checked) =>
                        this.handleSelectAll(checked, events)
                      }
                    />
                  </HeaderCell>
                  <Cell>
                    {(e: Event | any) => (
                      <Checkbox
                        onChange={(v, checked) => this.handleSelect(checked, e)}
                        checked={this.state.selected[e.id] !== undefined}
                      />
                    )}
                  </Cell>
                </Column>
              )}
          </Table>

          {this.renderPagination()}
        </Panel>
      </Content>
    )
  }

  renderPagination() {
    const { t } = this.props
    const { total, page, pageCapacity } = this.state

    return (
      <Stack justifyContent='center'>
        <Pagination
          total={total}
          limit={pageCapacity}
          activePage={page}
          maxButtons={10}
          onChangePage={this.handleChangePage}
          onChangeLimit={this.handleChangeLength}
          prev
          last
          next
          first
        />
        <Tag>
          {t('total')}:{total}
        </Tag>
      </Stack>
    )
  }

  renderSortBtn(col: string) {
    const { sort } = this.state

    let icon = <FaSort className='rs-table-cell-header-icon-sort' />
    let type = 'asc'
    if (sort.col === col) {
      if (sort.type === 'asc') {
        icon = <FaSortUp className='rs-table-cell-header-icon-sort' />
        type = 'desc'
      } else {
        icon = <FaSortDown className='rs-table-cell-header-icon-sort' />
        type = 'asc'
      }
    }

    return (
      <span
        className='rs-table-cell-header-sort-wrapper'
        onClick={() => this.handleSort(col, type)}>
        {icon}
      </span>
    )
  }

  renderHeader() {
    const { t } = this.props
    const {
      search,
      date_at,
      date_to,
      filter,
      exportNames,
      inExport,
      chart,
      charts,
      floormaps,
      floormap,
    } = this.state

    return (
      <HeaderSearch
        onSearch={this.handleSearch}
        value={search}
        left={
          <ButtonToolbar>
            <Calendar
              value={[date_at, date_to]}
              onChange={this.handleCalendar}
            />

            <TimeRange
              at={filter.date_time_at}
              to={filter.date_time_to}
              onChange={this.handleTimeRange}
            />
          </ButtonToolbar>
        }
        right={
          <ButtonToolbar>
            {/* remove events */}
            {Object.keys(this.state.selected).length > 0 && (
              <DelButton onConfirm={this.perfomDelete} circle />
            )}

            {/* add floormaps  */}
            {floormaps?.length > 0 && (
              <Dropdown
                className='eventsarchive-export-list'
                renderToggle={(props) => (
                  <Whisper
                    {...props}
                    speaker={
                      <Tooltip>{t('eventsarchive.add_floormap')}</Tooltip>
                    }
                    placement='bottom'>
                    <IconButton icon={<FaMapMarkedAlt />} circle />
                  </Whisper>
                )}
                placement='bottomEnd'>
                {floormap && (
                  <Dropdown.Item onSelect={() => this.handleSelectFloormap()}>
                    {t('eventsarchive.close_floormap')}
                  </Dropdown.Item>
                )}
                {floormaps.map((fmap) => {
                  return (
                    <Dropdown.Item
                      key={fmap.id}
                      onSelect={() => this.handleSelectFloormap(fmap)}>
                      {fmap.name}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown>
            )}

            {/* add charts  */}
            {charts?.length > 0 && (
              <Dropdown
                className='eventsarchive-export-list'
                renderToggle={(props) => (
                  <Whisper
                    {...props}
                    speaker={<Tooltip>{t('eventsarchive.add_chart')}</Tooltip>}
                    placement='bottom'>
                    <IconButton icon={<FaChartBar />} circle />
                  </Whisper>
                )}
                placement='bottomEnd'>
                {chart && (
                  <Dropdown.Item onSelect={() => this.handleSelectChart()}>
                    {t('eventsarchive.close_chart')}
                  </Dropdown.Item>
                )}
                {charts.map((chart) => {
                  return (
                    <Dropdown.Item
                      key={chart.id}
                      onSelect={() => this.handleSelectChart(chart)}>
                      {chart.name}
                    </Dropdown.Item>
                  )
                })}
              </Dropdown>
            )}

            {/* reports sendings modal window */}
            <ReportsSendings
              filter={{
                names: this.getFilter(),
                value: filter,
              }}
            />

            {/* export dropdown list */}
            <Dropdown
              className='eventsarchive-export-list'
              renderToggle={(props) => (
                <Whisper
                  {...props}
                  speaker={<Tooltip>{t('eventsarchive.export')}</Tooltip>}
                  placement='bottom'>
                  <IconButton
                    onClick={() => this.loadExportNames()}
                    icon={<FaFileAlt />}
                    circle
                  />
                </Whisper>
              )}
              placement='bottomEnd'>
              {exportNames?.map((name) => (
                <Dropdown.Item
                  key={name}
                  onClick={() => this.export(name)}
                  disabled={inExport !== undefined}>
                  {inExport && inExport.name === name ? (
                    <Progress.Line
                      percent={inExport.percent}
                      showInfo={false}
                      status='active'
                    />
                  ) : (
                    name
                  )}
                </Dropdown.Item>
              ))}
            </Dropdown>

            <EventsSettings onChange={this.handleSettings} />
          </ButtonToolbar>
        }>
        {this.renderFindByFace()}
      </HeaderSearch>
    )
  }

  renderFindByFace() {
    const { t } = this.props
    let { face } = this.state
    const vector_threshold = face?.threshold || DEFAULT_VECTOR_THRESHOLD

    return (
      <div>
        {face?.vector ? (
          <div className='eventsarchive-findface_container'>
            <IconButton
              className='selected'
              onClick={this.handleSearchByVectorClear}
              icon={<FaTimesCircle />}
              circle
            />
            <Slider
              min={0.6}
              max={1.5}
              step={0.05}
              value={vector_threshold}
              onChange={(v) => this.handleVectorThreshold(v)}
              tooltip={false}
              handleTitle={
                <div className='eventsarchive-slider_handler'>
                  {vector_threshold.toFixed(2)}
                </div>
              }
              progress
            />
            <div>
              {face.nodetect && (
                <Whisper
                  placement='bottom'
                  speaker={<Tooltip>{t('profiles.nodetect')}</Tooltip>}>
                  <IconButton
                    appearance='primary'
                    color='red'
                    icon={<FaExclamationCircle />}
                    circle
                  />
                </Whisper>
              )}
            </div>
          </div>
        ) : (
          <Uploader
            action={NAP.url('/api/detectors/findfacevector')}
            headers={NAP.authHeaders()}
            onError={(r) => alert(r.response.error)}
            onSuccess={this.handleSearchByVector}
            // withCredentials
            fileListVisible={false}
            accept='image/jpeg'
            name='frame'
            autoUpload>
            <Whisper
              placement='bottom'
              speaker={
                <Tooltip>{t('eventsarchive.help.searchbyface')}</Tooltip>
              }>
              <IconButton icon={<FaUserCircle />} />
            </Whisper>
          </Uploader>
        )}
      </div>
    )
  }

  // renderColumnsSettings() {
  //   const { t } = this.props
  //   const { filter } = this.state

  //   return (
  //     <Dropdown
  //       className='eventsarchive-export-list'
  //       renderToggle={(props) => (
  //         <Whisper
  //           {...props}
  //           speaker={<Tooltip>{t('eventsarchive.columns')}</Tooltip>}
  //           placement='bottom'>
  //           <IconButton icon={<FaCog />} circle />
  //         </Whisper>
  //       )}
  //       placement='bottomEnd'>
  //       {this.renderColumnButton('camera')}
  //       {this.renderColumnButton('sector')}
  //       {this.renderColumnButton('analytics')}
  //       {this.renderColumnButton('users')}
  //       {this.renderColumnButton('groups')}
  //       {this.renderColumnButton('profiles')}
  //       {this.renderColumnButton('status')}
  //       {this.renderColumnButton('objects')}
  //       {this.renderColumnButton('image')}
  //       {this.renderColumnButton('alerts')}
  //       {this.renderColumnButton('archive')}
  //       {this.renderColumnButton('select')}
  //       <Divider />
  //       <Dropdown.Item
  //         icon={
  //           filter.state === 'hide' ? (
  //             <FaCheck />
  //           ) : (
  //             <span className='emptyicon' />
  //           )
  //         }
  //         onClick={() =>
  //           this.handleFilter(
  //             filter.state === 'hide' ? undefined : 'hide',
  //             'state'
  //           )
  //         }>
  //         {t('eventsarchive.hidden')}
  //       </Dropdown.Item>
  //     </Dropdown>
  //   )
  // }

  // renderColumnButton = (column: string) => {
  //   const { t } = this.props
  //   const { config } = this.state

  //   return (
  //     <Dropdown.Item
  //       key={column}
  //       icon={
  //         config.columns[column] !== false ? (
  //           <FaCheck />
  //         ) : (
  //           <span className='emptyicon' />
  //         )
  //       }
  //       className={`eventsarchive-export-buttons ${
  //         config.columns[column] === false && 'unselected-column'
  //       }`}
  //       onClick={() => this.toggleColumn(column)}>
  //       {t('eventsarchive.' + column)}
  //     </Dropdown.Item>
  //   )
  // }

  renderCellTime(e: Event | any) {
    return (
      <div>
        <div>
          {e.time_at.format(EventsTimeLongFormat)}

          {e.time_at.unix() !== e.time_to.unix() &&
            ' - ' + e.time_to.format(EventsTimeLongFormat)}
        </div>

        {!e.time_at.isSame(moment(), 'day') && (
          <div className='events-date-subtitle' key='date'>
            {e.time_at.format(EventsDateFormat)}
          </div>
        )}

        {e.video && e.offset !== undefined && e.offset > 0 && (
          <div className='events-date-subtitle' key='offset'>
            {moment.utc(e.offset).format('HH:mm:ss')}
          </div>
        )}
      </div>
    )
  }

  renderCellProfile = (e: Event | any) => {
    if (!e.profile) return null
    return this.renderProfileName(e.profile)
  }

  renderEventNameInStatus = (e: Event) => {
    if (!e.name) return null
    if (e.profile) {
      const name = this.renderProfileName(e.profile)
      if (name === e.name) return null
    }

    return (
      <Tag
        data-state={e.state}
        data-alert={e.alert}
        onClick={() => this.handleSearch(e.name)}>
        {e.name}
      </Tag>
    )
  }

  renderProfileName = (p: Profile) => {
    const name = [] as string[]
    if (p.lastname) name.push(p.lastname)
    if (p.firstname) name.push(p.firstname)
    if (p.middlename) name.push(p.middlename)

    return name.join(' ')
  }

  renderCellImage = (e: Event | any) => {
    return (
      <div>
        {e.frame && this.renderImage(e, e.frame)}
        {e.images && e.images.map((imgsrc) => this.renderImage(e, imgsrc))}
      </div>
    )
  }

  renderCellVideoArchive = (e: Event | any) => {
    if (!e.archive_file) return null

    const { t } = this.props

    return (
      <Whisper
        placement='autoVertical'
        trigger='hover'
        speaker={
          <Tooltip>{t('eventsarchive.help.open_videoarchive')}</Tooltip>
        }>
        <IconButton
          className='events-toarchivebtn'
          appearance='subtle'
          icon={<FaPlay />}
          onClick={() => this.handleVideoArchive(e)}
        />
      </Whisper>
    )
  }

  renderImage = (e: Event, imgsrc: string) => {
    return (
      <div
        key={imgsrc}
        className='events-image-btn'
        onClick={() => this.toggleEventWindow(e, imgsrc)}>
        <IMAGE
          src={imgsrc + '?thumb'}
          width={48}
          height={48}
          background
          className='events-image'
        />
      </div>
    )
  }
}

export default withTranslation()(EventsArchive)
