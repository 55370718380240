import i18n from 'i18next'
import React from 'react'
import { FaTimes } from 'react-icons/fa'
import Moveable from 'react-moveable'
import { DateRangePicker, IconButton, Popover } from 'rsuite'

import NAP from 'nap'

interface Props {
  id: number
}
interface State {
  heatmap?: any
  modal?: boolean
  transform: string

  dates: [Date, Date] | null
}

class Heatmap extends React.Component<Props, State> {
  state = {
    modal: true,
    transform: '',
    dates: null,
  } as State

  interval = 0
  refModal = React.createRef<any>()

  componentDidMount() {
    this.loadHeatmap()

    this.interval = window.setInterval(this.loadHeatmap, 5000)
  }

  componentWillUnmount() {
    window.clearInterval(this.interval)
  }

  loadHeatmap = () => {
    const { id } = this.props
    const { dates } = this.state

    const opt = {} as Record<string, any>
    if (dates && dates.length === 2) {
      opt.time_at = Math.round(dates[0].getTime() / 1000)
      opt.time_to = Math.round(dates[1].getTime() / 1000)
    }

    NAP.heatmap(id, opt)
      .then((blob: Blob) => {
        const reader = new FileReader()
        reader.onload = (e) => {
          if (e.target) this.setState({ heatmap: e.target.result })
        }
        reader.readAsDataURL(blob)
      })
      .catch(alert)
  }

  handleHeatmapDates = (dates: [Date, Date] | null) => {
    this.setState({ dates })
  }

  handleDragModal = (e: any) => {
    this.setState({ transform: e.transform })
  }

  //
  // render
  //

  render() {
    const { heatmap, modal, dates, transform } = this.state

    return (
      <div>
        {modal && (
          <Popover
            ref={this.refModal}
            className='modal-draggable'
            style={{ transform }}
            visible>
            <div className='subtitle'>
              {i18n.t('cameras.heatmap_settings')}
              <IconButton
                size='xs'
                appearance='primary'
                color='red'
                icon={<FaTimes />}
                className='close-btn'
                onClick={() => this.setState({ modal: false })}
                lang={i18n.language}
              />
            </div>
            <DateRangePicker
              value={dates}
              onChange={this.handleHeatmapDates}
              placement='autoVertical'
              showOneCalendar
              cleanable
              block
            />
          </Popover>
        )}
        {modal && (
          <Moveable
            target={this.refModal.current}
            draggable
            onDrag={this.handleDragModal}
            resizable={false}
            origin={false}
            edge={false}
            bounds={false}
          />
        )}

        {heatmap && <img src={heatmap} alt='heatmap' className='heatmap' />}
      </div>
    )
  }
}

export default Heatmap
