import React from 'react'

import { Spinner } from 'content'
import NAP from 'nap'
import getFrame from './getframe'

interface Props {
  src: string
  className?: string
  controls?: boolean
  loop?: boolean
}

export default class VIDEO extends React.Component<Props> {
  src: string = ''
  video: any = React.createRef<HTMLVideoElement>()
  canvas: HTMLCanvasElement = document.createElement('canvas')

  // componentDidUpdate() {
  //   if (this.src !== this.props.src) {
  //     this.src = this.props.src
  //     this.setState({})
  //   }
  // }

  getFrame = (callback: (frame: any) => void) => {
    this.canvas.width = this.video.videoWidth
    this.canvas.height = this.video.videoHeight

    getFrame(this.video, this.canvas, { onFrame: callback })
  }

  getOffset = (): number => {
    return parseInt((parseFloat(this.video.currentTime) * 1000) as any)
  }

  setOffset = (offset: number) => {
    this.video.currentTime = offset
  }

  setPlaybackRate = (rate: number) => {
    this.video.playbackRate = rate
  }

  isPaused(): boolean {
    return this.video.paused
  }

  playPause(): boolean {
    if (this.video.paused) this.video.play()
    else this.video.pause()

    return this.video.paused
  }

  //
  // render
  //

  render() {
    const src = NAP.urlauth2(this.props.src)

    if (!src) return this.renderLoader()

    return (
      <video
        ref={(ref) => (this.video = ref)}
        src={src}
        // crossOrigin='use-credentials'
        crossOrigin='anonymous'
        className={this.props.className}
        controls={this.props.controls}
        loop={this.props.loop}
        autoPlay
      />
    )
  }

  renderLoader() {
    return (
      <div className={this.props.className}>
        <div className='camera-loader'>
          <Spinner className='spinner-small spinner-center' />
        </div>
      </div>
    )
  }
}
