import { Icon } from '@rsuite/icons'
import React from 'react'
import { Trans, WithTranslation, withTranslation } from 'react-i18next'
import { FaEllipsisV, FaPen, FaTrash } from 'react-icons/fa'
import {
  Button,
  ButtonToolbar,
  Dropdown,
  Form,
  IconButton,
  Input,
  Modal,
  Panel,
  Popover,
  Stack,
  Table,
  Whisper,
} from 'rsuite'

import { alert } from 'content'
import NAP, { Permission, Role } from 'nap'
import RolesSectors from './rolessectors'

export const ACCESS_WRITE = 'W'
export const ACCESS_READ = 'R'
export const ACCESS_DENIED = '-'

export const SECTIONS = [
  { section: '/archive', name: 'videoarchive.title' },

  { section: '/events', sections: ['/reports', '/events'], name: 'events' },
  { section: '/charts', name: 'charts.title' },
  { section: '/floormaps', name: 'floormaps.title' },

  { section: '/cameras', name: 'cameras.title' },
  {
    section: '/schemes',
    sections: [
      '/schemes',
      '/detectors',
      '/devices',
      '/scripts',
      '/notifications',
    ],
    name: 'analytics.title',
  },

  { section: '/users', name: 'users.title' },
  { section: '/roles', name: 'users.roles.title' },

  {
    section: '/profiles',
    sections: ['/profiles', '/cars'],
    name: 'profiles.title',
  },
  { section: '/groups', name: 'profiles.groups.title' },

  { section: '/themes', name: 'themes.title' },
  {
    section: '/packages',
    sections: ['/packages', '/repository', '/plugins', '/shell'],
    name: 'packages.title',
  },
  { section: '/journal', name: 'journal.title' },
  { section: '/license', name: 'license.title' },
]

//
// roles
//

interface Props extends WithTranslation {}
interface State {
  roles?: Role[]
  form: {
    show: boolean
    id?: number
    title?: string
  }
  remove?: Role
}

class Roles extends React.Component<Props, State> {
  state = {
    form: { show: false },
  } as State

  componentDidMount() {
    NAP.roles()
      .then((roles: Role[]) => this.setState({ roles }))
      .catch(alert)
  }

  setRole = (r: Role) => {
    let { roles } = this.state
    if (!roles) roles = []

    const role = roles.find((role: Role) => role.id === r.id)
    if (role) role.permissions = r.permissions
    else roles.push(r)

    this.setState({ roles })
  }

  lookupPermission = (r: Role, key: string): Permission => {
    return (
      r.permissions?.find((p: Permission) => p.sectionpath === key) || {
        sectionpath: key,
        access: 0,
      }
    )
  }

  changeAccess = (r: Role, keys: string[]) => {
    let access = this.lookupPermission(r, keys[0]).access
    access++
    if (access > 1) access = -1

    keys.forEach((key) => {
      const p = this.lookupPermission(r, key)
      p.access = access

      NAP.setRolePermission(r.id, p).then(this.setRole).catch(alert)
    })
  }

  getAccess = (r: Role, key: string) => {
    const p = this.lookupPermission(r, key)

    switch (p.access) {
      case -1:
        return ACCESS_DENIED
      case 0:
        return ACCESS_READ
      case 1:
        return ACCESS_WRITE
    }

    return p.access
  }

  toggleNewGroupWindow = (id?: number, title?: string) => {
    this.setState({
      form: { show: !this.state.form.show, id: id, title: title },
    })
  }

  handleInputName = (title: string) => {
    const { form } = this.state
    form.title = title
    this.setState({ form })
  }

  performModalForm = () => {
    let { roles, form } = this.state
    let resp: Promise<void | Role> | undefined = undefined

    if (!form.id && form.title) {
      resp = NAP.createRole(form.title).then((r: Role) => {
        if (!roles) roles = []
        roles.push(r)
        this.setState({ roles })
      })
    } else if (form.id && form.title) {
      resp = NAP.changeRole(form.id, { title: form.title }).then(
        (r: Role) => {
          if (!roles) roles = []
          const role = roles.find((role: Role) => role.id === r.id)
          if (role) role.title = r.title
          this.setState({ roles })
        }
      )
    }

    if (resp) resp.catch(alert).then(() => this.toggleNewGroupWindow())
  }

  handleRemove = (r?: Role) => {
    this.setState({ remove: r })
  }

  performRemove = () => {
    const { remove, roles } = this.state
    if (!remove) return

    NAP.deleteRole(remove.id)
      .then(() => {
        this.setState({
          roles: roles?.filter((role: Role) => role.id !== remove.id),
          remove: undefined,
        })
      })
      .catch(alert)
  }

  //
  //
  //

  render() {
    const { t } = this.props
    const { roles } = this.state

    const { Column, HeaderCell, Cell } = Table

    return (
      <Panel className='content-panel' header={t('users.roles.title')}>
        <Table
          data={roles}
          loading={roles === undefined}
          height={(roles?.length || 1) * 50 + 50}>
          <Column width={80}>
            <HeaderCell> </HeaderCell>
            <Cell dataKey='title' />
          </Column>

          {SECTIONS.map((s: any) => (
            <Column key={s.section} minWidth={40} flexGrow={1} align='center'>
              <HeaderCell className='table-vertical-th' title={t(s.name)}>
                {t(s.name)}
              </HeaderCell>
              <Cell className='actions-cell'>
                {(r: Role | any) => this.renderAccess(r, s)}
              </Cell>
            </Column>
          ))}

          <Column minWidth={40} flexGrow={1} align='center'>
            <HeaderCell
              className='table-vertical-th'
              title={t('profiles.sectors')}>
              {t('profiles.sectors')}
            </HeaderCell>
            <Cell className='actions-cell'>
              {(r: Role | any) => <RolesSectors role={r} />}
            </Cell>
          </Column>

          <Column fixed='right' width={60}>
            <HeaderCell> </HeaderCell>
            <Cell className='table-content-edit'>
              {(r: Role | any) => (
                <Whisper
                  placement='bottomEnd'
                  trigger='click'
                  speaker={
                    <Popover full>
                      <Dropdown.Menu>
                        <Dropdown.Item
                          icon={<Icon as={FaPen} />}
                          onSelect={() =>
                            this.toggleNewGroupWindow(r.id, r.title)
                          }>
                          {t('rename')}
                        </Dropdown.Item>
                        <Dropdown.Item
                          className='delete'
                          icon={<Icon as={FaTrash} />}
                          onSelect={() => this.handleRemove(r)}>
                          {t('remove')}
                        </Dropdown.Item>
                      </Dropdown.Menu>
                    </Popover>
                  }>
                  <IconButton appearance='subtle' icon={<FaEllipsisV />} />
                </Whisper>
              )}
            </Cell>
          </Column>
        </Table>

        {this.renderForm()}
        {this.renderRemoveWindow()}

        <Stack className='footer' justifyContent='center'>
          <Button
            appearance='primary'
            onClick={() => this.toggleNewGroupWindow()}>
            {t('users.roles.add_role')}
          </Button>
        </Stack>
      </Panel>
    )
  }

  renderAccess(r: Role, s: any) {
    const a = this.getAccess(r, s.section)

    let color: any = 'green'
    if (a === ACCESS_WRITE) color = 'blue'
    else if (a === ACCESS_DENIED) color = 'red'

    return (
      <Button
        appearance='primary'
        className='table-mini-btn'
        color={color}
        onClick={() => this.changeAccess(r, s.sections || [s.section])}>
        {a}
      </Button>
    )
  }

  renderForm() {
    const { t } = this.props
    const { form } = this.state

    if (!form.show) return ''

    return (
      <Modal onClose={() => this.toggleNewGroupWindow()} open>
        <Modal.Header>
          <Modal.Title>
            {form.id ? t('users.roles.rename_role') : t('users.roles.add_role')}
          </Modal.Title>
        </Modal.Header>
        <Form fluid>
          <Modal.Body>
            <Form.Group>
              <Form.ControlLabel>{t('name')}</Form.ControlLabel>
              <Input
                value={form.title || ''}
                onChange={(v: string) => this.handleInputName(v)}
              />
            </Form.Group>
          </Modal.Body>

          <Modal.Footer>
            <ButtonToolbar justifyContent='flex-end'>
              <Button
                type='submit'
                appearance='primary'
                disabled={!form.title}
                onClick={this.performModalForm}>
                {t('apply')}
              </Button>
            </ButtonToolbar>
          </Modal.Footer>
        </Form>
      </Modal>
    )
  }

  renderRemoveWindow() {
    const { t } = this.props
    const { remove } = this.state

    if (!remove) return ''

    const title = remove.title

    return (
      <Modal onClose={() => this.handleRemove(undefined)} open>
        <Modal.Header>
          <Modal.Title>{t('users.roles.remove_role')}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Trans i18nKey='users.roles.remove_role_text' title={title}>
            Remove <strong>{title}</strong> role?
          </Trans>
        </Modal.Body>

        <Modal.Footer>
          <ButtonToolbar align='right'>
            <Button
              appearance='primary'
              color='red'
              onClick={this.performRemove}>
              {t('remove')}
            </Button>
          </ButtonToolbar>
        </Modal.Footer>
      </Modal>
    )
  }
}

export default withTranslation()(Roles)
