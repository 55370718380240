import moment from 'moment-timezone'
import React from 'react'
import { FaEllipsisV } from 'react-icons/fa'
import ReactResizeDetector from 'react-resize-detector'
import { Dropdown, IconButton, Modal } from 'rsuite'

import { CameraCanvas } from 'components/cameras'
import { IMAGE } from 'components/players'
import { alert, success } from 'content'
import { trans as t } from 'i18n'
import ROUTES from 'routes'
import NAP, {
  Event,
  Exception,
  Item,
  User,
  checkAccess,
  eventToDetected,
} from 'nap'

interface Props {
  event: Event
  imgSrc: string
  onDelete?: () => void
  user?: User
  withButtons?: boolean
}

interface State {
  canvas: { w: number; h: number }
  onScreenshot: boolean
}

export default class EventsImage extends React.Component<Props, State> {
  state = {
    canvas: { w: 100, h: 100 },
    onScreenshot: false,
  } as State

  image: any = React.createRef<IMAGE>()

  handleResize = (w?: number, h?: number) => {
    this.setState({ canvas: { w: w || 100, h: h || 100 } })
  }

  handleImageLoad = () => {
    // console.log(this.image)
    // this.image.getSize()
  }

  handleScreenshot = () => {
    const { event, imgSrc } = this.props
    const filename = imgSrc || event.frame

    this.setState({ onScreenshot: true })

    const name = `${event.camera}_${moment
      .unix(event.time_at)
      .format('YYYY-MM-DD_HH:mm:ss')}`

    const e = {
      status: event.status,
      state: event.state,
      items: event.items,
      regions: event.regions,
      lines: event.lines,
    } as Event
    NAP.drawFileEvents(filename, [e], name)
      .then(this.screenshotDone)
      .catch(alert)
  }

  handleAddException = (item: Item) => {
    this.image.getFrame((frame: any) => {
      if (!frame) {
        alert(t('frame_not_exist'))
        return
      }

      const { event } = this.props
      const e = {
        camera_id: event.camera_id,
        type: item.type,
        bbox: item.bbox,
        prob: item.prob,
        frame: frame,
      } as Exception

      NAP.createException(e)
        .then(() => success(t('success')))
        .catch((err: Error) => alert(err.message))
    })
  }

  screenshotDone = () => {
    this.setState({ onScreenshot: false })
  }

  //
  //
  //

  render() {
    const { canvas, onScreenshot } = this.state
    const { event, imgSrc, user, onDelete, withButtons } = this.props

    return (
      <Modal.Body className='event-image-modalbody'>
        <ReactResizeDetector
          key={`event_image`}
          handleWidth
          handleHeight
          onResize={this.handleResize}>
          <div>
            <IMAGE
              ref={(ref) => (this.image = ref)}
              key={`event_modal_image`}
              className='event-image'
              src={imgSrc || event.frame}
              onLoad={this.handleImageLoad}
            />
          </div>
        </ReactResizeDetector>

        <CameraCanvas
          key={`event_modal_canvas`}
          width={canvas.w}
          height={canvas.h}
          regions={event.regions}
          analytics={{ [event.analytics_id]: eventToDetected(event) }}
          onException={this.handleAddException}
          img={imgSrc || event.frame}
        />

        {withButtons !== false && (
          <Dropdown
            className='event-screenshot-btn'
            renderToggle={(props) => (
              <IconButton {...props} icon={<FaEllipsisV />} />
            )}
            placement='bottomEnd'>
            <Dropdown.Item
              as='a'
              href={NAP.url(event.frame)}
              target='_blank'
              download>
              {t('eventsarchive.dl_image')}
            </Dropdown.Item>

            <Dropdown.Item
              onSelect={this.handleScreenshot}
              disabled={onScreenshot}>
              {t('eventsarchive.dl_image_bboxes')}
            </Dropdown.Item>

            {user && onDelete && checkAccess(user, ROUTES.events, 1) && (
              <Dropdown.Item onSelect={onDelete}>{t('delete')}</Dropdown.Item>
            )}
          </Dropdown>
        )}
      </Modal.Body>
    )
  }
}
