import { MediaPlayer } from 'dashjs'
import moment from 'moment-timezone'
import React from 'react'

import { Spinner } from 'content'
import NAP, { WS } from 'nap'

// settings to reduce delay
const PLAYER_SETTINGS = {
  streaming: {
    // lowLatencyEnabled: true,
    gaps: {
      jumpGaps: true,
      jumpLargeGaps: true,
      // smallGapLimit: 0.5,
      // threshold: 0.8,
    },
    // buffer: {
    //   stallThreshold: 0.1, // used in BufferController for more accurate stall tracking in low-latency live streaming
    // },

    delay: {
      // liveDelayFragmentCount: 1,
      liveDelay: 2,
    },
    liveCatchup: {
      // minDrift: 0.02,
      maxDrift: 0,
      playbackRate: 0.5,
      // latencyThreshold: 10,
      playbackBufferMin: 0.5,
      // enabled: true,
    },
    utcSynchronization: {
      enabled: false,
    },

    manifestUpdateRetryInterval: 1000,
    retryIntervals: {
      MPD: 1000,
      XLinkExpansion: 500,
      InitializationSegment: 1000,
      IndexSegment: 1000,
      MediaSegment: 1000,
      BitstreamSwitchingSegment: 1000,
      other: 1000,
    },
    retryAttempts: {
      MPD: 1000,
      XLinkExpansion: 1,
      InitializationSegment: 1000,
      IndexSegment: 1,
      MediaSegment: 1,
      BitstreamSwitchingSegment: 1,
      other: 1,
    },
  },
}

interface Props {
  className?: string
  src: string
  syncTime: number
}

interface State {
  loaded: boolean
}

// MDASH player used 'dashjs' to display video stream
export default class MDASH extends React.Component<Props, State> {
  state = {
    loaded: false,
  } as State

  player = MediaPlayer().create()
  video = React.createRef<HTMLVideoElement>()
  progress: number = 0
  intervals: number[] = []
  segment = ''

  ws?: WS

  componentDidMount() {
    this.ws = NAP.ws()
    this.ws
      .video()
      .then(() => this.initPlayer())
      .catch(console.error)

    this.intervals.push(window.setInterval(this.failMonitor, 10000))
  }

  componentWillUnmount() {
    this.player.reset()
    this.intervals.forEach(window.clearInterval)
    this.ws?.close()
  }

  shouldComponentUpdate(props: Props, state: any) {
    if (state.loaded !== this.state.loaded) return true
    if (props.src !== this.props.src) return true
    if (props.syncTime !== this.props.syncTime) return true

    return false
  }

  initPlayer() {
    if (!this.video.current) return

    const src = NAP.url(this.props.src)
    console.log(src)

    this.player.updateSettings(PLAYER_SETTINGS as any)
    // this.player.extend('RequestModifier', this.reqHeaders, true)

    this.player.initialize(this.video.current, src, true)

    this.player.extend('XHRLoader', this.loadMPD, true)
    this.player.extend('FetchLoader', this.loadM4S, true)
  }

  getInfo = (): any => {
    const info = this.player.getDashMetrics().getCurrentDVRInfo('video')
    return info as any
  }

  getOffset = (): number => {
    const info = this.getInfo()
    if (!info) return 0

    return parseInt(((info as any).time * 1000) as any)
  }

  getTimeMS = (): number => {
    const info = this.getInfo()
    if (!info || !info.manifestInfo) return 0

    let at = moment(info.manifestInfo.availableFrom).utc()
    // at = at.add(-this.player.getCurrentLiveLatency(), 's')
    at = at.add(info.time, 's').add(this.props.syncTime, 's')

    return at.valueOf()
  }

  failMonitor = () => {
    if (!this.video.current) return

    if (this.video.current.currentTime === this.progress) {
      console.warn('restart player', this.props)

      const src = NAP.url(this.props.src)
      this.player.updateSource(src)
    }

    this.progress = this.video.current.currentTime
  }

  // set authorization headers to the each requests
  reqHeaders = () => {
    const headers = NAP.authHeaders()

    return {
      modifyRequestHeader: (xhr: XMLHttpRequest) => {
        for (let key in headers) {
          xhr.setRequestHeader(key, headers[key])
        }
        return xhr
      },
      modifyRequestURL: (url: string) => {
        return url
      },
    }
  }

  loadMPD = () => {
    return {
      load: this.wsloader,
      abort: (r?: any) => {},
    }
  }

  loadM4S = () => {
    return {
      load: this.wsloader,
      abort: (r?: any) => {},
    }
  }

  wsloader = (r: any) => {
    let file = r.url
    if (!r.url.startsWith('/')) file = new URL(r.url).pathname

    this.ws?.sendRequest({ file }).then((resp) => {
      r.response = { status: 200 } as any

      if (file.endsWith('.mpd')) {
        r.response.response = atob(resp.data)
      } else {
        r.response.response = _base64ToArrayBuffer(resp.data)
      }

      r.onload()
      r.onend()
    })
  }

  // when video is loaded set appropiate flag
  loaded = () => {
    if (this.video.current && this.video.current.readyState > 1) {
      this.setState({ loaded: true })
      this.video.current.play()
    }
  }

  render() {
    let loader: any = ''
    if (!this.state.loaded) {
      loader = (
        <div className='camera-loader'>
          <Spinner className='spinner-small spinner-center' />
        </div>
      )
    }

    return (
      <div>
        {loader}
        <video
          data-dashjs-player
          onLoadedData={this.loaded}
          className={this.props.className}
          ref={this.video}
          // controls={true}
          //src={NAP.url(this.props.src)}
          autoPlay
        />
      </div>
    )
  }
}

function _base64ToArrayBuffer(base64: any) {
  var binary_string = atob(base64)
  var len = binary_string.length
  var bytes = new Uint8Array(len)
  for (var i = 0; i < len; i++) {
    bytes[i] = binary_string.charCodeAt(i)
  }
  return bytes.buffer
}
