import React from 'react'
import { WithTranslation, withTranslation } from 'react-i18next'
import { FaCog } from 'react-icons/fa'
import {
  Button,
  Checkbox,
  CheckPicker,
  Form,
  IconButton,
  InputPicker,
  Modal,
  Stack,
} from 'rsuite'

import { alert, Spinner, success } from 'content'
import NAP, { ReportsConfig } from 'nap'

const columns = [
  { label: 'Cameras', value: 'camera' },
  { label: 'Sectors', value: 'sector' },
  { label: 'Analytics', value: 'analytics' },
  { label: 'Users', value: 'users' },
  { label: 'Groups', value: 'groups' },
  { label: 'Profiles', value: 'profiles' },
  { label: 'Status', value: 'status' },
  { label: 'Objects', value: 'objects' },
  { label: 'Image', value: 'image' },
  { label: 'Alerts', value: 'alerts' },
  { label: 'Archive', value: 'archive' },
  { label: 'Select', value: 'select' },
]

export const levels = [
  { label: 'Critical', value: 10 },
  { label: 'Important', value: 5 },
  { label: 'Notice', value: 2 },
  { label: 'Default', value: 0 },
  { label: 'Boring', value: -2 },
  { label: 'Techincal', value: -5 },
  { label: 'Debug', value: -10 },
]

interface Props extends WithTranslation {
  onChange: () => void
}
interface State {
  open: boolean
  conf: ReportsConfig
}

class EventsSettings extends React.Component<Props, State> {
  state = {
    open: false,
  } as State

  loadConfig = () => {
    NAP.reportsConfig()
      .then((conf) => this.setState({ conf }))
      .catch(alert)
  }

  toggleWindow = () => {
    this.loadConfig()
    this.setState({ open: !this.state.open })
  }

  handleSave = () => {
    const { conf } = this.state

    conf.reports_level = Number(conf.reports_level)
    conf.feed_level = Number(conf.feed_level)

    NAP.changeReportsConfig(conf)
      .then(() => {
        success()
        this.props.onChange()
        this.toggleWindow()
      })
      .catch(alert)
  }

  handleColumns = (cols: string[]) => {
    const { conf } = this.state

    cols.forEach((c) => (conf.columns[c] = true))
    for (const col in conf.columns) {
      conf.columns[col] = cols.includes(col)
    }

    this.setState({ conf })
  }

  handleChanges = (kv: Record<string, any>) => {
    const { conf } = this.state
    Object.assign(conf, kv)

    this.setState({ conf })
  }

  //
  // data
  //

  getColumns = (): string[] => {
    const { conf } = this.state
    if (!conf) return []

    return (
      Object.entries(conf.columns)
        .filter(([col, vis]) => vis)
        .map(([col, vis]) => col) || []
    )
  }

  //
  // render
  //

  render() {
    const { t } = this.props
    const { open } = this.state

    return (
      <div>
        <IconButton icon={<FaCog />} onClick={this.toggleWindow} circle />

        <Modal onClose={this.toggleWindow} open={open}>
          <Modal.Header>
            <h5>{t('eventsarchive.settings.title')}</h5>
          </Modal.Header>

          <Modal.Body>{this.renderForm()}</Modal.Body>

          <Modal.Footer>
            <Button onClick={this.toggleWindow}>{t('cancel')}</Button>
            <Button appearance='primary' onClick={this.handleSave}>
              {t('save')}
            </Button>
          </Modal.Footer>
        </Modal>
      </div>
    )
  }

  renderForm() {
    const { t } = this.props
    const { conf } = this.state
    if (!conf) return <Spinner center small />

    return (
      <Form fluid>
        <Form.Group>
          <Form.ControlLabel>
            {t('eventsarchive.settings.columns')}
          </Form.ControlLabel>
          <CheckPicker
            value={this.getColumns()}
            data={columns}
            onChange={this.handleColumns}
            cleanable={false}
            searchable={false}
            block
          />
        </Form.Group>

        <Form.Group>
          <Checkbox
            checked={conf.hidden}
            onChange={() => this.handleChanges({ hidden: !conf.hidden })}>
            {t('eventsarchive.settings.hidden')}
          </Checkbox>
        </Form.Group>

        <Form.Group>
          <Form.ControlLabel>
            {t('eventsarchive.settings.level')}
          </Form.ControlLabel>
          <Stack spacing={15} justifyContent='space-around'>
            <Stack.Item alignSelf='stretch'>
              <Form.ControlLabel>{t('eventsarchive.title')}</Form.ControlLabel>
              <InputPicker
                value={conf.reports_level}
                data={levels}
                onChange={(v) => this.handleChanges({ reports_level: v })}
                creatable
                block
              />
            </Stack.Item>
            <Stack.Item alignSelf='stretch'>
              <Form.ControlLabel>{t('eventsfeed.title')}</Form.ControlLabel>
              <InputPicker
                value={conf.feed_level}
                data={levels}
                onChange={(v) => this.handleChanges({ feed_level: v })}
                creatable
                block
              />
            </Stack.Item>
          </Stack>
        </Form.Group>
      </Form>
    )
  }
}

export default withTranslation()(EventsSettings)
